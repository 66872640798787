// Image
import EmptyPortfolioIcon from "assets/icons/EmptyPortfolio.png";

// Styles
import styles from "./style.module.scss";
import classNames from "classnames";

type NoDataProps = {
  message?: string;
  style?: object;
  className?: string;
};

const NoData = ({ message, style, className }: NoDataProps) => {
  return (
    <div className={classNames(styles["no__data"], className)} style={style}>
      <img className={styles["no__data__image"]} src={EmptyPortfolioIcon} alt="" />
      {message && <div className={styles[""]}>{message}</div>}
    </div>
  );
};

export default NoData;
