import React, { PureComponent } from "react";
import {
  allowSentry,
  getIsPartner,
  getUserId,
  getCryptGraphId,
} from "config/helper";

import ErrorHandler from "assets/icons/error-handler.svg";
import * as Sentry from "@sentry/browser";

import styles from "./style.module.scss";

type ErrorHandlerProps = {};

type ErrorHandlerState = {
  hasError: Boolean;
};

const globalThis: any = window;

const isAndroidApp =
  typeof globalThis?.proboAndroidWebView !== "undefined" &&
  globalThis?.proboAndroidWebView !== null;

class ErrorBoundary extends PureComponent<
  ErrorHandlerProps,
  ErrorHandlerState
> {
  constructor(props: ErrorHandlerProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    const user: any = {};

    if (getUserId()) {
      user.id = getUserId();
    }

    if (allowSentry()) {
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);
        Sentry.captureException(error, {
          user,
        });
      });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={styles["error__container"]}>
          <div className={styles["error__content"]}>
            <img className={styles["error__icon"]} src={ErrorHandler} alt="" />
            <div className={styles["error__message"]}>Oh Snap!</div>
            <div className={styles["error__text"]}>Something went wrong</div>
            <div
              className={styles["error__btn"]}
              onClick={() => {
                if (isAndroidApp) {
                  globalThis?.proboAndroidWebView?.closeWebViewWithReason(
                    "Something went wrong"
                  );
                } else if (getIsPartner()) {
                  window.location.replace(`/partner`);
                } else if (!!getCryptGraphId()) {
                  window.location.replace(`/cryptograph/:${getCryptGraphId()}`);
                } else {
                  window.location.replace(`/home`);
                }
              }}
            >
              Go Back
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
