// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_top__container__1JbrD {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.style_create__container__1rw_G {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  width: 100%;\n  margin-top: 50px;\n}\n\n.style_contract__title__wrapper__PxsIU {\n  display: flex;\n  align-items: center;\n  margin-bottom: 20px;\n}\n\n.style_contract__title__boJXZ {\n  font-size: 20px;\n  font-weight: 600;\n  line-height: 28px;\n  letter-spacing: 0em;\n  text-align: center;\n  margin-right: 10px;\n}\n\n.style_edit__contract__wrapper__21Wht {\n  display: flex;\n  align-items: baseline;\n  margin-top: 20px;\n  line-height: 0;\n  cursor: pointer;\n}\n\n.style_edit__contract__icon__1DYAb {\n  margin-left: 10px;\n}", "",{"version":3,"sources":["webpack://src/views/Rai/Create/style.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,mBAAA;EACA,WAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,qBAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;AACF;;AAEA;EACE,iBAAA;AACF","sourcesContent":[".top__container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.create__container {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  width: 100%;\n  margin-top: 50px;\n}\n\n.contract__title__wrapper {\n  display: flex;\n  align-items: center;\n  margin-bottom: 20px;\n}\n\n.contract__title {\n  font-size: 20px;\n  font-weight: 600;\n  line-height: 28px;\n  letter-spacing: 0em;\n  text-align: center;\n  margin-right: 10px;\n}\n\n.edit__contract__wrapper {\n  display: flex;\n  align-items: baseline;\n  margin-top: 20px;\n  line-height: 0;\n  cursor: pointer;\n}\n\n.edit__contract__icon {\n  margin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"top__container": "style_top__container__1JbrD",
	"create__container": "style_create__container__1rw_G",
	"contract__title__wrapper": "style_contract__title__wrapper__PxsIU",
	"contract__title": "style_contract__title__boJXZ",
	"edit__contract__wrapper": "style_edit__contract__wrapper__21Wht",
	"edit__contract__icon": "style_edit__contract__icon__1DYAb"
};
export default ___CSS_LOADER_EXPORT___;
