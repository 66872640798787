import React from "react";

import AI from "assets/icons/rai/ai_loader.gif";
import Checkmark from "assets/icons/rai/ai_loader_success.gif";

import styles from "./style.module.scss";

interface LoadingProps {
  message?: string;
  status?: "success" | "loading" | "input";
}

const Loading = ({ message, status }: LoadingProps) => {
  return (
    <>
      <div className={styles["loading__container"]}>
        <img
          src={status === "success" ? Checkmark : AI}
          width={100}
          className={styles["ai"]}
          alt={"AI loading"}
        ></img>
        <span>{message}</span>
      </div>
      <div></div>
    </>
  );
};

export default Loading;
