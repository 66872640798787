import React, { useContext, useEffect, useRef, useState } from "react";
import { getWalletDetails, postContract } from "services/rai";

import EventCard, {
  EventCardProps,
  EventProps,
} from "components/Rai/EventCard";
import Container from "components/Rai/Container";
import Navbar from "components/Rai/Navbar";

import EditIcon from "assets/icons/rai/edit.svg";
import RechargeIcon from "assets/icons/rai/recharge.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import styles from "./style.module.scss";
import RaiButton from "components/Rai/Button";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import Loading from "components/Rai/Loading";
import { Toast } from "components/Toast";
import { AppContext } from "config/context";
import { logFirebaseEvent } from "config/firebase";
import { Constants } from "config/constants";

const NumberOfContracts = ({
  event,
  opinion,
  contract_price,
  referee_flow,
  is_money_flow,
  winning_text,
}: EventCardProps) => {
  const history = useHistory();
  const ref = useRef<any>(null);

  const context = useContext(AppContext);
  const windowWidth = context.mobileLayoutWidth;

  const [investment, setInvestment] = useState(500);

  const [number, setNumber] = useState<number>(1);
  const [disabled, setDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [walletBalance, setWalletBalance] = useState<any>(0);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [dailyLimit, setDailyLimit] = useState(0);

  const [lowBalance, setLowBalance] = useState(false);

  const handleChange = (e: any) => {
    if (
      !(
        e?.target?.value?.includes("+") ||
        e?.target?.value?.includes("-") ||
        e?.target?.value?.includes("e")
      )
    ) {
      setNumber(e?.target?.value);
    }
  };

  const createContract = async () => {
    if (!localStorage?.getItem("name")) {
      history?.push("/create/enter-name", {
        event: event,
        contract_qty: number,
        opinion: opinion,
        stake: contract_price,
        referee_flow: referee_flow,
        is_money_flow: is_money_flow,
        winning_text: winning_text,
      });
    } else {
      logFirebaseEvent({
        event_name: "rai_create_contract_pressed",
        event_value_key1: "format_name",
        event_value_value1: "RAI",
        event_value_key2: "screen",
        event_value_value2: "number_of_contracts",
        event_value_key3: "number_of_contracts",
        event_value_value3: number,
        event_value_key4: "stakes",
        event_value_value4: contract_price,
        event_value_key5: "question",
        event_value_value5: event?.contract_name,
        event_value_key6: "request_id",
        event_value_value6: event?.request_id,
      });
      setLoading(true);
      const data = {
        request_id: event?.request_id,
        contract_qty: number,
        contract_price: contract_price,
        is_money_flow: is_money_flow,
        winning_text: winning_text,
        offer_type: opinion === "agree" ? "BUY" : "SELL",
      };
      const response: any = await postContract(data);

      if (response.statusCode === 201) {
        logFirebaseEvent({
          event_name: "rai_contract_created",
          event_value_key1: "format_name",
          event_value_value1: "RAI",
          event_value_key2: "screen",
          event_value_value2: "number_of_contracts",
          event_value_key3: "request_id",
          event_value_value3: event?.request_id,
          event_value_key4: "event_id",
          event_value_value4: response?.data?.event_id,
        });
        // const id = 111;
        const ref = response?.data?.referee_flow;
        if (ref) {
          history.push(`/contract/ref-share/${response?.data?.event_id}`);
        } else {
          history.push(`/contract/${response?.data?.event_id}`);
        }
      } else {
        logFirebaseEvent({
          event_name: "rai_contract_creation_failed",
          event_value_key1: "format_name",
          event_value_value1: "RAI",
          event_value_key2: "screen",
          event_value_value2: "number_of_contracts",
          event_value_key3: "request_id",
          event_value_value3: event?.request_id,
        });
        Toast({
          message: "Something went wrong",
          type: "error",
        });
        setLoading(false);
      }
    }
  };

  const handleKeyDown = (e: any) => {
    if (
      e.key === "Enter" &&
      !lowBalance &&
      number * contract_price! <= remainingAmount &&
      number
    ) {
      createContract();
    }
  };

  const getWallet = async () => {
    setLoading(true);
    const response: any = await getWalletDetails();
    setLoading(false);
    if (response?.statusCode === 200) {
      setWalletBalance(response?.data?.total_amount);
      setDailyLimit(response?.data?.daily_limit);
      setRemainingAmount(
        response?.data?.daily_limit - response?.data?.total_investment
      );
    } else {
      Toast({
        message: "Couldn't fetch wallet details",
        type: "error",
      });
    }
  };

  useEffect(() => {
    if (number > 0 && number < 100) {
      setDisabled(false);
      setLowBalance(walletBalance < contract_price! * number);
      setErrorMessage("");
      if (number * contract_price! > remainingAmount) {
        setDisabled(true);
        setErrorMessage(
          `You've exceeded daily investment limit of ₹${dailyLimit}`
        );
        setDisabled(true);
        ref.current.select();
        //   setNumber(99);
      }
    } else if (number > 99) {
      setDisabled(true);
      setErrorMessage("You can make a maximum of 99 contracts");
      ref.current.select();
      //   setNumber(99);
    } else {
      setDisabled(true);
    }
  }, [number, walletBalance, dailyLimit, remainingAmount]);

  useEffect(() => {
    getWallet();
    logFirebaseEvent({
      event_name: "rai_number_of_contracts_screen_loaded",
      event_value_key1: "format_name",
      event_value_value1: "RAI",
      event_value_key2: "screen",
      event_value_value2: "number_of_contracts",
      event_value_key3: "request_id",
      event_value_value3: event?.request_id,
    });
  }, []);

  return (
    <Container>
      {loading && (
        <>
          <Navbar title={"₹.AI"} subtitle={"by Probo"} />
          <Loading message="Processing contract..." />
        </>
      )}
      {!loading && (
        <>
          <div className={styles["top__container"]}>
            <Navbar title={"₹.AI"} subtitle={"by Probo"} />
            <div className={styles["create__container"]}>
              <span className={styles["contract__title__wrapper"]}>
                <span className={styles["contract__title"]}>Contract</span>
              </span>
              <EventCard
                event={event}
                opinion={opinion}
                referee_flow={referee_flow}
              />
              <div className={styles["contract__number__container"]}>
                <span className={styles["contract__number__title"]}>
                  No. of contracts
                </span>
                <div
                  className={styles["contract__number__input__wrapper"]}
                  onClick={() => {
                    ref?.current?.focus();
                  }}
                >
                  <input
                    className={styles["contract__number__input"]}
                    type="number"
                    value={number}
                    onChange={handleChange}
                    ref={ref}
                    onFocus={(e: any) => {
                      ref?.current?.select();
                    }}
                    onKeyDown={handleKeyDown}
                  />
                  <img
                    className={styles["contract__number__edit__image"]}
                    src={EditIcon}
                  />
                </div>

                {walletBalance && is_money_flow && (
                  <span
                    className={styles["wallet__message"]}
                  >{`Wallet balance: ₹${walletBalance.toFixed(2)}`}</span>
                )}

                {/* {<span className={styles["number__message"]}>{"Edit to contract with multiple friends"}</span>} */}
                {
                  <span className={styles["error__message"]}>
                    {errorMessage}
                  </span>
                }
              </div>
              {is_money_flow && (
                <div className={styles["investment__return__container"]}>
                  <div className={styles["investment__container"]}>
                    <span className={styles["investment__amount"]}>
                      ₹{contract_price! * number}
                    </span>
                    <span className={styles["investment__text"]}>You put</span>
                  </div>
                  <div className={styles["return__container"]}>
                    <span className={styles["return__amount"]}>
                      ₹{contract_price! * number * 2}
                    </span>
                    <span className={styles["return__text"]}>Winner gets</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={styles["footer__section"]}>
            {/* <span className={styles["terms__section"]}>
              <a className={styles["terms__link"]} href="">
                T&C
              </a>
              applies
            </span> */}
            <RaiButton
              disabled={disabled || (lowBalance && is_money_flow)}
              variant="contained"
              endIcon={<ArrowForwardIcon />}
              onClick={() => {
                createContract();
              }}
            >
              Create contract
            </RaiButton>
            {lowBalance && is_money_flow && (
              <>
                <div
                  className={styles["recharge__section"]}
                  style={{ width: windowWidth }}
                >
                  <div className={styles["recharge__container"]}>
                    <div className={styles["recharge__left__container"]}>
                      <img
                        src={RechargeIcon}
                        className={styles["recharge__icon"]}
                      />
                      <div className={styles["recharge__info__container"]}>
                        <span className={styles["recharge__info"]}>
                          ₹
                          {(contract_price! * number - walletBalance).toFixed(
                            1
                          )}{" "}
                          low on money!
                        </span>
                        <span className={styles["recharge__now"]}>
                          Recharge now
                        </span>
                      </div>
                    </div>
                    <button
                      className={styles["recharge__button"]}
                      onClick={() => {
                        window.open(
                          `${Constants?.TRADING_WEB_URL}/recharge`,
                          "_blank"
                        );
                      }}
                    >
                      Add now
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </Container>
  );
};

export default NumberOfContracts;
