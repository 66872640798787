import { AppContext } from "config/context";
import React, { useContext } from "react";
import ContentLoader from "react-content-loader";
import CircularProgress from "@mui/material/CircularProgress";
import { Player } from "@lottiefiles/react-lottie-player";
import { Constants } from "config/constants";

type LoaderProps = {
  style?: object;
};

const backgroundColor = "#f3f3f3";
const foregroundColor = "#ecebeb";

export const TopicsFeedLoader = () => {
  console.log("TopicsFeedLoader Render");
  const context = useContext(AppContext);

  let width: number = 64;
  let height: number = 90;
  let r: number = 32;
  let x: number = 12;
  let y: number = 74;

  if (context.windowWidth > 360) {
    width = 72;
    height = 98;
    r = 36;
    x = 16;
    y = 82;
  }

  return (
    <ContentLoader
      width={width}
      height={height}
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
    >
      <circle cx={r} cy={r} r={r} />
      <rect x={x} y={y} rx="5" ry="5" width="40" height="10" />
    </ContentLoader>
  );
};

export const CategoryFeedLoader = () => {
  console.log("CategoryFeedLoader Render");
  const context = useContext(AppContext);
  return (
    <ContentLoader width={context.mobileLayoutWidth / 4 - 20} height="80">
      <rect
        rx="5"
        ry="5"
        width={context.mobileLayoutWidth / 4 - 20}
        height="80"
      />
    </ContentLoader>
  );
};

export const BannersFeedLoader = () => {
  console.log("BannersFeedLoader Render");
  return (
    <ContentLoader width="280" height="90">
      <rect rx="5" ry="5" width="280" height="90" />
    </ContentLoader>
  );
};

export const TrendingTopicsFeedLoader = () => {
  console.log("TrendingTopicsFeedLoader Render");
  return (
    <ContentLoader width="120" height="50">
      <rect rx="5" ry="5" width="120" height="50" />
    </ContentLoader>
  );
};

export const EventCardLoader = () => {
  console.log("EventCardLoader Render");
  const context = useContext(AppContext);
  return (
    <ContentLoader viewBox={`0 0 ${context.mobileLayoutWidth} 250`}>
      <circle cx="31" cy="31" r="15" />
      <rect
        x="58"
        y="18"
        rx="2"
        ry="2"
        width={context.mobileLayoutWidth / 2}
        height="10"
      />
      <rect
        x="58"
        y="34"
        rx="2"
        ry="2"
        width={context.mobileLayoutWidth / 2}
        height="10"
      />
      <rect
        x="0"
        y="60"
        rx="2"
        ry="2"
        width={context.mobileLayoutWidth}
        height="200"
      />
    </ContentLoader>
  );
};

export const ArenaCardLoader = () => {
  console.log("ArenaCardLoader Render");
  const context = useContext(AppContext);
  return (
    <ContentLoader
      viewBox={`0 0 ${context.mobileLayoutWidth} 250`}
      foregroundColor={"#d3d3d3"}
      backgroundColor={"#e3e3e3"}
    >
      <circle cx="31" cy="31" r="15" />
      <rect
        x="58"
        y="18"
        rx="2"
        ry="2"
        width={context.mobileLayoutWidth / 2}
        height="10"
      />
      <rect
        x="58"
        y="34"
        rx="2"
        ry="2"
        width={context.mobileLayoutWidth / 2}
        height="10"
      />
      <rect
        x="0"
        y="60"
        rx="2"
        ry="2"
        width={context.mobileLayoutWidth}
        height="200"
      />
    </ContentLoader>
  );
};

export const EventGraphLoader = () => {
  console.log("EventGraphLoader Render");
  const context = useContext(AppContext);
  return (
    <ContentLoader viewBox={`0 0 ${context.mobileLayoutWidth} 220`}>
      <rect
        x="0"
        y="0"
        rx="2"
        ry="2"
        width={context.mobileLayoutWidth}
        height="220"
      />
    </ContentLoader>
  );
};

export const EventOverviewLoader = () => {
  console.log("EventOveriew Render");
  const context = useContext(AppContext);
  return (
    <ContentLoader viewBox={`0 0 ${context.mobileLayoutWidth} 220`}>
      <rect
        x="0"
        y="0"
        rx="2"
        ry="2"
        width={context.mobileLayoutWidth}
        height="220"
      />
    </ContentLoader>
  );
};

export const PortfolioLoader = () => {
  console.log("PortfolioLoader Render");
  const context = useContext(AppContext);
  return (
    <ContentLoader viewBox={`0 0 ${context.mobileLayoutWidth} 150`}>
      <rect
        x="0"
        y="0"
        rx="2"
        ry="2"
        width={context.mobileLayoutWidth}
        height="150"
      />
    </ContentLoader>
  );
};

export const CircularLoader = ({ style }: LoaderProps) => {
  console.log("CircularLoader Render");
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        height: 40,
        ...style,
      }}
    >
      <CircularProgress size={25} />
    </div>
  );
};

export const ProboLoader = ({ style }: LoaderProps) => {
  console.log("ProboLoader Render");
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        height: 40,
        ...style,
      }}
    >
      <Player
        autoplay
        loop
        src={Constants.DEFAULT_LOADING_IMAGE_URL}
        style={{ height: "96px", width: "96px" }}
      ></Player>
    </div>
  );
};

export const KycLoader = ({ style }: LoaderProps) => {
  console.log("KycLoader Render");
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        height: 40,
        ...style,
      }}
    >
      <Player
        autoplay
        loop
        src={Constants.KYC_LOADING_IMAGE_URL}
        style={{ height: "96px", width: "96px" }}
      ></Player>
    </div>
  );
};

export const WalletLoaderNavbar = () => {
  console.log("WalletLoaderNavbar Render");
  return (
    <ContentLoader viewBox={`0 0 100 30`}>
      <rect x="0" y="0" rx="2" ry="2" width={100} height="30" />
    </ContentLoader>
  );
};

export const PortfolioItemLoader = () => {
  console.log("PortfolioItemLoader Render");
  const context = useContext(AppContext);
  return (
    <ContentLoader viewBox={`0 0 ${context.mobileLayoutWidth} 100`}>
      <rect
        x="0"
        y="0"
        rx="2"
        ry="2"
        width={context.mobileLayoutWidth}
        height="100"
      />
    </ContentLoader>
  );
};

export const BottomSheetLoader = () => {
  console.log("BottomSheetoader Render");
  const context = useContext(AppContext);

  return (
    <ContentLoader viewBox={`0 0 ${context.mobileLayoutWidth} 320`}>
      <rect
        x="0"
        y="18"
        rx="2"
        ry="2"
        width={context.mobileLayoutWidth}
        height="10"
      />
      <rect
        x="0"
        y="34"
        rx="2"
        ry="2"
        width={context.mobileLayoutWidth}
        height="10"
      />
      <rect
        x="0"
        y="60"
        rx="2"
        ry="2"
        width={context.mobileLayoutWidth}
        height="280"
      />
    </ContentLoader>
  );
};

export const WalletBalanceLoader = () => {
  console.log("WalletBalanceLoader Render");
  const context = useContext(AppContext);

  return (
    <ContentLoader viewBox={`0 0 100 25`}>
      <rect
        x="0"
        y="0"
        rx="2"
        ry="2"
        width={context.mobileLayoutWidth}
        height="20"
      />
    </ContentLoader>
  );
};

export const WalletLinksLoader = () => {
  console.log("WalletBalanceLoader Render");
  const context = useContext(AppContext);

  return (
    <ContentLoader viewBox={`0 0 100 70`}>
      <rect
        x="0"
        y="0"
        rx="2"
        ry="2"
        width={context.mobileLayoutWidth}
        height="70"
      />
    </ContentLoader>
  );
};

export const LeaderboardRankLoader = () => {
  console.log("LeaderboardRankLoader Render");
  const context = useContext(AppContext);

  return (
    <ContentLoader viewBox={`0 0 ${context.mobileLayoutWidth} 200`}>
      <rect
        x="0"
        y="0"
        rx="2"
        ry="2"
        width={context.mobileLayoutWidth}
        height="200"
      />
    </ContentLoader>
  );
};

export const LeaderboardItemLoader = () => {
  console.log("LeaderboardItemLoader Render");
  const context = useContext(AppContext);

  return (
    <ContentLoader viewBox={`0 0 ${context.mobileLayoutWidth} 80`}>
      <rect
        x="0"
        y="10"
        rx="2"
        ry="2"
        width={context.mobileLayoutWidth}
        height="70"
      />
    </ContentLoader>
  );
};

export const PollCardLoader = () => {
  console.log("PollCardLoader Render");
  const context = useContext(AppContext);
  return (
    <ContentLoader viewBox={`0 0 ${context.mobileLayoutWidth} 300`}>
      <circle cx="31" cy="31" r="15" />
      <rect
        x="58"
        y="18"
        rx="2"
        ry="2"
        width={context.mobileLayoutWidth / 2}
        height="10"
      />
      <rect
        x="58"
        y="34"
        rx="2"
        ry="2"
        width={context.mobileLayoutWidth / 2}
        height="10"
      />
      <rect
        x="0"
        y="60"
        rx="2"
        ry="2"
        width={context.mobileLayoutWidth}
        height="250"
      />
    </ContentLoader>
  );
};

export const PollOverviewLoader = () => {
  console.log("PollOverviewLoader Render");
  const context = useContext(AppContext);
  return (
    <ContentLoader viewBox={`0 0 ${context.mobileLayoutWidth} 100`}>
      <rect
        x="0"
        y="0"
        rx="2"
        ry="2"
        width={context.mobileLayoutWidth}
        height="100"
      />
    </ContentLoader>
  );
};

export const PollPortfolioSummaryLoader = () => {
  console.log("PollPortfolioSummaryLoader Render");
  const context = useContext(AppContext);
  return (
    <ContentLoader viewBox={`0 0 ${context.mobileLayoutWidth} 100`}>
      <rect
        x="0"
        y="0"
        rx="2"
        ry="2"
        width={context.mobileLayoutWidth}
        height="100"
      />
    </ContentLoader>
  );
};

export const WalletOverviewLoader = () => {
  console.log("WalletOverviewLoader Render");

  const context = useContext(AppContext);
  const loaderElements = Array.from(Array(3));

  return (
    <React.Fragment>
      {loaderElements?.map((element: number, index: number) => {
        return (
          <div key={index} style={{ display: "flex" }}>
            <ContentLoader viewBox={`0 0 ${context.mobileLayoutWidth} 200`}>
              <rect
                x="0"
                y="0"
                rx="2"
                ry="2"
                width={context.mobileLayoutWidth}
                height="100"
              />
            </ContentLoader>
            <ContentLoader viewBox={`0 0 ${context.mobileLayoutWidth} 200`}>
              <rect
                x="200"
                y="0"
                rx="2"
                ry="2"
                width={context.mobileLayoutWidth / 2}
                height="100"
              />
            </ContentLoader>
          </div>
        );
      })}
    </React.Fragment>
  );
};

export const ArenaHeaderLoader = () => {
  console.log("ArenaHeaderLoader Render");
  const context = useContext(AppContext);
  return (
    <ContentLoader
      viewBox={`0 0 ${context.mobileLayoutWidth} 200`}
      foregroundColor={"#d3d3d3"}
      backgroundColor={"#e3e3e3"}
    >
      <rect
        x="0"
        y="0"
        rx="2"
        ry="2"
        width={context.mobileLayoutWidth}
        height="200"
      />
    </ContentLoader>
  );
};

export const ArenaFantasyContestCardLoader = () => {
  console.log("ArenaFantasyContestCardLoader Render");
  const context = useContext(AppContext);
  return (
    <ContentLoader
      viewBox={`0 0 ${context.mobileLayoutWidth} 122`}
      foregroundColor={"#4E4C4C"}
      backgroundColor={"#292929"}
    >
      <rect
        x="0"
        y="0"
        rx="12"
        ry="12"
        width={context.mobileLayoutWidth}
        height="116"
      />
    </ContentLoader>
  );
};

export const ArenaFantasyFilterLoader = () => {
  console.log("ArenaFantasyFilterLoader Render");
  return (
    <ContentLoader
      viewBox={`0 0 60 32`}
      foregroundColor={"#4E4C4C"}
      backgroundColor={"#292929"}
    >
      <rect x="0" y="0" rx="0" ry="0" width="60" height="32" />
    </ContentLoader>
  );
};

export const ArenaFantasyPortFolioReturnsLoader = () => {
  console.log("ArenaFantasyPortFolioReturnsLoader Render");
  const context = useContext(AppContext);
  return (
    <ContentLoader
      viewBox={`0 0 ${context.mobileLayoutWidth} 100`}
      foregroundColor={"#4E4C4C"}
      backgroundColor={"#292929"}
    >
      <rect
        x="0"
        y="0"
        rx="0"
        ry="0"
        width={context.mobileLayoutWidth}
        height="100"
      />
    </ContentLoader>
  );
};
