export const Constants = {
  PROBO_MPL: "probo_mpl",
  IOS: "ios",
  ANDROID: "android",
  HOW_TO_PLAY: "https://www.youtube.com/embed/_MXKGip-jUk",
  GAME_NAME: "Probo",
  SOCIAL_PLATFORMS: "ALL",
  SHARE_MESSAGE:
    "Hey! Check this out on MPL, where I can play over 60 games. Sign up using my code {INVITE_CODE} and get a bonus of ₹75 - Click here to install the app {URL}",
  PROBO_IOS_CLIENT: "probo_ios_client",
  PARTNER: "partner",
  LOW_BALANCE_THRESOLD: 5,
  SIGNUP_BONUS: "₹15", // ₹15
  PARTNER_USER_SIGNUP_MSG: "Adding ₹15 in bonus", // amount value should be as per signupBonus amount
  PARTNER_USER_LOGIN_MSG: "Welcome back!",
  PARTNER_USER_WELCOME_MSG: "Now you are ready to trade & win",
  PROBO_OUTLOOK: "probo_outlook",
  PARTNER_HEADER_TAGLINE: "India’s largest opinion exchange",
  PARTNER_WELCOME_SCREEN_TIMER: 5000, // milliseconds
  PARTNER_OPEN_EVENT: 227830,
  PARTNER_DEFAULT_TOPIC: 2754,
  SUCCESS_IMAGE_URL:
    "https://probo.gumlet.io/image/upload/probo_product_images/success.json",
  FAILURE_IMAGE_URL:
    "https://probo.gumlet.io/image/upload/probo_product_images/Failed_State_json.json",
  PENDING_IMAGE_URL:
    "https://probo.gumlet.io/image/upload/probo_product_images/pending.json",
  DEFAULT_LOADING_IMAGE_URL:
    "https://probo.gumlet.io/image/upload/probo_product_images/probo_loader.json",
  KYC_LOADING_IMAGE_URL:
    "https://probo.gumlet.io/image/upload/probo_product_images/kyc/icon/scan_progress.json",
  EMPTY_BOX_URL:
    "https://probo.gumlet.io/image/upload/probo_product_images/empty_box.json",
  APP_VERSION: "10",
  PROBO_LOGO_URL: "https://i.imgur.com/n5tjHFD.jpg",
  PARTNER_NEW_EVENT_CARD_HEADING: "INDIA SAYS",
  TERMS_AND_CONDITIONS_URL: "https://probo.in/terms-and-conditions",
  PORTFOLIO_URL: "https://trading.probo.in/portfolio",
  POTENTIAL_EXIT_PRICE: 10,
  CONGRATULATION_IMAGE_URL:
    "https://probo.gumlet.io/image/upload/probo_product_images/congratulations.json",
  WELCOME_IMAGE_URL:
    "https://probo.gumlet.io/image/upload/probo_product_images/welcome.json",
  WARNING_IMAGE_URL:
    "https://probo.gumlet.io/image/upload/probo_product_images/warnings.json",
  PROBO_HOME_PAGE: "https://trading.probo.in",
  LOGIN_NOTE: "Almost there!",
  OTP_TIMER: 59,
  TELEGRAM_CHANNEL_LINK: "https://telegram.me/MPLOpinio_discussions",
  HOME_PAGE_ANIMATION_TIMER_PARTNER: 5000,
  RS_SYMBOL: "₹",
  PDF_IMAGE:
    "https://probo.gumlet.io/image/upload/probo_product_images/helpAndSupport/pdf.png",
  WON: "won",
  LOST: "lost",
  TRADING_WEB_URL:
    process.env.REACT_APP_ENV === "production"
      ? "https://trading.probo.in"
      : "https://dev.trading.probo.in",
};

export const TransactionStatus = {
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
  PENDING: "PENDING",
  INITIATED: "INITIATED",
  CREDIT: "CREDIT",
  DEBIT: "DEBIT",
  DEPOSIT: "DEPOSIT",
  WITHDRAW: "WITHDRAW",
  USER_DROPPED: "USER_DROPPED",
};

export const Partners: any = {
  probo_mpl: 1,
  probo_outlook: 2,
  cricInfo: 3,
  ht: 4,
};

export const PartnerBanners: any = [
  {
    id: null,
    card_type: "image_banner",
    title: "Telegram",
    description: "Telegram group link",
    image:
      "https://gumlet-images-bucket.s3.ap-south-1.amazonaws.com/probo_product_images/IMAGE_f135e58b-7c37-46a0-b73f-bf5d3e614913.png",
    is_redirection: true,
    template_type: "external_redirect",
    redirection_url: "https://telegram.me/MPLOpinio_discussions",
  },
  {
    id: 2449,
    card_type: "image_banner",
    title: "First free trade",
    description: "First free trade",
    image:
      "https://gumlet-images-bucket.s3.ap-south-1.amazonaws.com/probo_product_images/IMAGE_537de43b-bf6d-4f4d-b2d9-1097e290d6d3.png",
    is_redirection: false,
    template_type: "youtube",
    redirection_url: "",
  },

  {
    id: null,
    card_type: "image_banner",
    title: "Polls: How to win?",
    description: "Polls: How to win?",
    image:
      "https://gumlet-images-bucket.s3.amazonaws.com/probo_product_images/IMAGE_32c9b9c8-4ca6-4b16-a5b9-97b6a1963b2d.png",
    is_redirection: false,
    template_type: "youtube",
    redirection_url: "https://www.youtube.com/embed/_MXKGip-jUk",
  },

  {
    id: null,
    card_type: "image_banner",
    title: "How to trade with exit?",
    description: "How to trade with exit?",
    image:
      "https://gumlet-images-bucket.s3.ap-south-1.amazonaws.com/probo_product_images/IMAGE_3751f3db-c6b1-4c2d-9c30-818b32704380.png",
    is_redirection: false,
    template_type: "youtube",
    redirection_url: "https://www.youtube.com/embed/jto_pOjD7eA",
  },

  {
    id: null,
    card_type: "image_banner",
    title: "Exit",
    description: "Exit",
    image:
      "https://gumlet-images-bucket.s3.ap-south-1.amazonaws.com/probo_product_images/IMAGE_fe2b3188-6f8e-47cc-9498-aa53c62e2508.png",
    is_redirection: false,
    template_type: "youtube",
    redirection_url: "https://www.youtube.com/embed/nIy73yrZWvE",
  },
];

export const PartnerBannersAfterFirstTrade: any = [
  {
    id: null,
    card_type: "image_banner",
    title: "How to trade with exit?",
    description: "How to trade with exit?",
    image:
      "https://gumlet-images-bucket.s3.ap-south-1.amazonaws.com/probo_product_images/IMAGE_3751f3db-c6b1-4c2d-9c30-818b32704380.png",
    is_redirection: false,
    template_type: "youtube",
    redirection_url: "https://www.youtube.com/embed/jto_pOjD7eA",
  },
  {
    id: null,
    card_type: "image_banner",
    title: "Exit",
    description: "Exit",
    image:
      "https://gumlet-images-bucket.s3.ap-south-1.amazonaws.com/probo_product_images/IMAGE_fe2b3188-6f8e-47cc-9498-aa53c62e2508.png",
    is_redirection: false,
    template_type: "youtube",
    redirection_url: "https://www.youtube.com/embed/nIy73yrZWvE",
  },
  {
    id: null,
    card_type: "image_banner",
    title: "Telegram",
    description: "Telegram group link",
    image:
      "https://gumlet-images-bucket.s3.ap-south-1.amazonaws.com/probo_product_images/IMAGE_f135e58b-7c37-46a0-b73f-bf5d3e614913.png",
    is_redirection: true,
    template_type: "external_redirect",
    redirection_url: "https://telegram.me/MPLOpinio_discussions",
  },
  {
    id: 2694,
    card_type: "image_banner",
    title: "BTC",
    description: "BTC",
    image:
      "https://gumlet-images-bucket.s3.ap-south-1.amazonaws.com/probo_product_images/IMAGE_8eb46749-7da3-4160-bacf-2d53b18d0b2e.png",
    is_redirection: false,
    template_type: "topic",
  },
];

export const PartnerBottomSheetData: any = {
  success_icon_url:
    "https://probo.gumlet.io/image/upload/probo_product_images/IMAGE_2f5ea1bc-6258-4083-b1cc-b828c6e95842.gif",
  text: "Finding a match...",
};

export const AndroidVersionCheck = {
  WALLET_WEBVIEW_SUPPORT_MINIMUM_VERSION: "5.40.0",
  OPEN_ACTIVITY_TWO_PARAMS_SUPPORT_MINIMUM_VERSION: "5.43.0",
};

export const IOS_WEB_VIEW_COMMANDS = {
  openTradeSummary: "OPEN_TRADE_SUMMARY_WEB_VIEW",
  getIosAppVersion: "GET_IOS_APP_VERSION",
};
