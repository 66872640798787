import classNames from "classnames";
import React from "react";

import styles from "./style.module.scss";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  className?: string;
}

const TabPanel = (props: TabPanelProps) => {
  console.log("TabPanel Render");
  const { children, value, index, className, ...other } = props;

  return (
    <div className={classNames(styles["tab"], className)} hidden={value !== index} {...other}>
      {value === index && <React.Fragment>{children}</React.Fragment>}
    </div>
  );
};

export default React.memo(TabPanel);
