// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

const globalThis: any = window;
// Get user payload
export const getUserPayload = () => {
  return true;
};

// Clear local storage
export const clearlocalStorage = () => {
  localStorage.clear();
};

// Get Referral Code
export const getUserReferralCode = () => {
  return sessionStorage.getItem("referral_code");
};

// Set Referral Code
export const setUserReferralCode = (referral_code: string) => {
  sessionStorage.setItem("referral_code", referral_code);
};

// Get Referee Data
export const getUserRefereeData = () => {
  if (sessionStorage.getItem("referral_data")) {
    const data: any = sessionStorage.getItem("referral_data");
    return JSON.parse(data);
  }
  return null;
};

// Set Referee Data
export const setUserRefereeData = (referral_data: string) => {
  sessionStorage.setItem("referral_data", JSON.stringify(referral_data));
};

// Delete Referral Code
export const deleteUserReferralCode = () => {
  sessionStorage.removeItem("referral_code");
};

// Get Mobile Number
export const getUserMobileNumber = () => {
  return sessionStorage.getItem("mobile");
};

// Get Partner Mobile
export const getPartnerUserMobileNumber = () => {
  return localStorage.getItem("mobile");
};

// Set Referral Code
export const setUserMobileNumber = (mobile: string) => {
  sessionStorage.setItem("mobile", mobile);
};

// Set partner user mobile
export const setPartnerUserMobileNumber = (mobile: string) => {
  localStorage.setItem("mobile", mobile);
};

// Get User Id
export const getUserId = () => {
  return localStorage.getItem("user_id");
};

// Set User Id
export const setUserId = (user_id: string) => {
  localStorage.setItem("user_id", user_id);
};

// Get User Token
export const getUserToken = () => {
  return localStorage.getItem("token");
};

// Set User Token
export const setUserToken = (token: string) => {
  localStorage.setItem("token", token);
};

// Set portfolio returns
export const setPortfolioReturns = (returns: string) => {
  localStorage.setItem("portfolioReturns", returns);
};

// Get portfolio returns
export const getPortfolioReturns = () => {
  return localStorage.getItem("portfolioReturns");
};

// Remove Auth Data
export const deleteAuthData = () => {
  sessionStorage.removeItem("mobile");
  sessionStorage.removeItem("referral_code");
  sessionStorage.removeItem("referral_data");
};

// Get User Event
export const getUserEvent = () => {
  return sessionStorage.getItem("event_id");
};

// Set User Event
export const setUserEvent = (event: string) => {
  sessionStorage.setItem("event_id", event);
};

// Delete User Event
export const deleteUserEvent = () => {
  sessionStorage.removeItem("event_id");
};

// Get Banner Type
export const getBannerType = () => {
  return sessionStorage.getItem("banner_type");
};

// Set Banner Type
export const setBannerType = (bannerType: string) => {
  sessionStorage.setItem("banner_type", bannerType);
};

// Delete Banner Type
export const deleteBannerType = () => {
  sessionStorage.removeItem("banner_type");
};

// Get ElectionCode
export const getElectionCode = () => {
  return sessionStorage.getItem("election_code");
};

// Set ElectionCode
export const setElectionCode = (electionCode: string) => {
  sessionStorage.setItem("election_code", electionCode);
};

// Delete ElectionCode
export const deletElectionCode = () => {
  sessionStorage.removeItem("election_code");
};

// Get User OS
export const getUserOs = () => {
  return localStorage.getItem("os");
};

// Get Order Id
export const getOrderId = () => {
  return localStorage.getItem("orderId");
};

// Set Order Id
export const setOrderId = (orderId: string) => {
  localStorage.setItem("orderId", orderId);
};

// Delete Order Id
export const deleteOrderId = () => {
  localStorage.removeItem("orderId");
};

// Get withDrawResponse
export const getWithDrawResponse = () => {
  return localStorage.getItem("withDrawResponse");
};

// Set withDrawResponse
export const setWithDrawResponse = (withDrawResponse: string) => {
  localStorage.setItem("withDrawResponse", withDrawResponse);
};

// Delete withDrawResponse
export const deleteWithDrawResponse = () => {
  localStorage.removeItem("withDrawResponse");
};

// Set User OS
export const setUserOs = () => {
  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;
  const mac = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
  const windows = ["Win32", "Win64", "Windows", "WinCE"];
  const ios = ["iPhone", "iPad", "iPod", "iPhone Simulator", "iPad Simulator"];
  let os: any = null;

  if (mac.indexOf(platform) !== -1) {
    os = "Mac";
  } else if (ios.indexOf(platform) !== -1) {
    os = "iOS";
  } else if (windows.indexOf(platform) !== -1) {
    os = "Windows";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
  } else if (!os && /Linux/.test(platform)) {
    os = "Linux";
  }

  localStorage.setItem("os", os);
};

// Get Click Id
export const getUserClickId = () => {
  return localStorage.getItem("click_id");
};

// Set Click Id
export const setUserClickId = (clickId: string) => {
  localStorage.setItem("click_id", clickId);
};

// Delete Click Id
export const deleteUserClickId = () => {
  localStorage.removeItem("click_id");
};

export const toShowRankSection = (rank: string): boolean => {
  if (rank === "1" || rank === "2" || rank === "3") return true;
  return false;
};

// Get User Poll
export const getUserPoll = () => {
  return sessionStorage.getItem("poll_id");
};

// Set User Poll
export const setUserPoll = (poll: string) => {
  sessionStorage.setItem("poll_id", poll);
};

// Delete User Poll
export const deleteUserPoll = () => {
  sessionStorage.removeItem("poll_id");
};

// Get Source Id
export const getPollsSource = () => {
  return localStorage.getItem("source");
};

// Set Click Id
export const setPollsSource = (source: string) => {
  localStorage.setItem("source", source);
};

// Delete Click Id
export const deletePollsSource = () => {
  localStorage.removeItem("source");
};

// set open event details info
export const setOpenEventInfo = (eventData: string) => {
  localStorage.setItem("openEventInfo", eventData);
};

// Get open event details info
export const getOpenEventInfo = () => {
  return localStorage.getItem("openEventInfo");
};

// Delete open event details info
export const deleteOpenEventInfo = () => {
  return localStorage.removeItem("openEventInfo");
};

// to check if user placed first trade
export const isFirstTradeDone = () => {
  return localStorage.getItem("firstTrade");
};

export const removeFirstTradeInfo = () => {
  return localStorage.removeItem("firstTrade");
};

export const isValidHttpUrl = (testString: string) => {
  let url: URL;

  try {
    url = new URL(testString);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
};

// Get Graph Type
export const getGraphType = () => {
  return localStorage.getItem("graphType");
};

// Set Graph Type
export const setGraphType = (graphType: string) => {
  localStorage.setItem("graphType", graphType);
};

// Delete Graph Type
export const deleteGraphType = () => {
  localStorage.removeItem("graphType");
};

// get showbanner count
export const getShowBannerCount = () => {
  return localStorage.getItem("bannerCount");
};

export const setShowBannerCount = (details: string) => {
  localStorage.setItem("bannerCount", details);
};

export const isNumber = (evt: any) => {
  evt = evt ? evt : window.event;
  var charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
};

export const setBackButton = (back: any) => {
  localStorage.setItem("back", back);
};

export const getBackButton = () => {
  return localStorage.getItem("back");
};

export const setUserType = (type: boolean) => {
  localStorage.setItem("isNewUser", type.toString());
};

export const getUserType = () => {
  return localStorage.getItem("isNewUser");
};

export const getSeoCard = () => {
  return localStorage.getItem("seoCard");
};

// Delete open event details info
export const deleteSeoCard = () => {
  localStorage.removeItem("seoCard");
};

// to check if user placed first trade
export const setSeoCard = (data: string) => {
  localStorage.setItem("seoCard", data);
};

// Get pseudo user uuid
export const getPseudoUserId = () => {
  return localStorage.getItem("user_pseudo_id");
};

// Set pseudo user uuid
export const setPseudoUserId = () => {
  localStorage.setItem("user_pseudo_id", uuidv4());
};

// Get utm_source
export const getUtmSource = () => {
  return localStorage.getItem("utm_source");
};

// Set pseudo user uuid
export const setUtmSource = (source: string) => {
  localStorage.setItem("utm_source", source);
};

// Get utm event id
export const getUtmEventId = () => {
  return localStorage.getItem("utm_event_id");
};

// Set utm event id
export const setUtmEventId = (id: string) => {
  localStorage.setItem("utm_event_id", id);
};

// Delete utm event id
export const deleteUtmEventId = () => {
  localStorage.removeItem("utm_event_id");
};

// Get utm poll id
export const getUtmPollId = () => {
  return localStorage.getItem("utm_poll_id");
};

// Set utm poll id
export const setUtmPollId = (id: string) => {
  localStorage.setItem("utm_poll_id", id);
};

// Delete utm poll id
export const deleteUtmPollId = () => {
  localStorage.removeItem("utm_poll_id");
};

// Get session key
export const getSessionId = () => {
  return localStorage.getItem("session_id");
};

// Set session key
export const setSessionId = (sessionId: string) => {
  localStorage.setItem("session_id", sessionId);
};

// Set sourceName  key
export const setSourceName = (sourceName: string) => {
  localStorage.setItem("source_name", sourceName);
};

// Set iframe webview key
export const setIframeSourceName = (sourceName: string) => {
  sessionStorage.setItem("source_name", sourceName);
};

// set setDecodedToken
export const setDecodedToken = (info: string) => {
  const decodedData: any = decodeBase64String(info);
  localStorage.setItem("partner_session_id", decodedData.token);
  localStorage.setItem("platform", decodedData?.platform?.toLowerCase());
};

export const setMPLUserType = (type: boolean) => {
  // input validation
  localStorage.setItem("isNewMPLUser", type?.toString());
};

export const getMPLUserType = () => {
  return localStorage.getItem("isNewMPLUser") || false;
};

export const setPartnerTopicId = (topicId: any) => {
  localStorage.setItem("partnerChildId", topicId);
};

export const getPartnerTopicId = () => {
  return Number(localStorage.getItem("partnerChildId"));
};

export const setPartnerCategoryId = (categoryId: any) => {
  localStorage.setItem("partnerParentId", categoryId);
};

export const getPartnerCategoryId = () => {
  return Number(localStorage.getItem("partnerParentId"));
};

export const setPartnerOpenEventId = (eventId: any) => {
  localStorage.setItem("partnerEventId", eventId);
};

export const getPartnerOpenEventId = () => {
  return Number(localStorage.getItem("partnerEventId"));
};

export const setIsPartner = (flag: any) => {
  sessionStorage.setItem("isPartner", flag);
};

export const getIsPartner = () => {
  return sessionStorage.getItem("isPartner");
};

type BureauContext = {
  bureauContext: any;
  sessionKey?: string | null;
  userIdHash?: string | null;
  flow?: string | null;
};

export const updateBureauContext = ({
  bureauContext,
  sessionKey,
  userIdHash,
  flow,
}: BureauContext) => {
  const newContext: any = {};
  if (sessionKey) newContext.sessionKey = sessionKey;
  if (userIdHash) newContext.userIdHash = userIdHash;
  if (flow) newContext.flow = flow;
  if (bureauContext) bureauContext.updateConfig(newContext);
};

export const getSourceName = () => {
  const source: any = localStorage.getItem("source_name") || "probo";
  return source;
};

export const getIframeSourceName = () => {
  const source: any = sessionStorage.getItem("source_name") || "probo";
  return source;
};

export const decodeBase64String = (info: string) => {
  if (!info) return;
  const base64ToStringNew = Buffer.from(info, "base64").toString("ascii");
  const jsonParsedData = JSON.parse(base64ToStringNew);
  return jsonParsedData;
};

export const getPartnerSession = () => {
  return localStorage.getItem("partner_session_id");
};

export const getPlatform = () => {
  return localStorage.getItem("platform");
};

export const setTopicId = (topicId: any) => {
  localStorage.setItem("topic_id", topicId);
};

export const getTopicId = () => {
  return localStorage.getItem("topic_id");
};

export const getDateFromDateTime = (datetime: any) => {
  const dateFormat = "DD MMMM hh:mm A";
  return moment(datetime).utcOffset("+05:30").format(dateFormat);
};

export const stripHtml = (html: string) => {
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
};

function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}

export const getAppVersion = () => {
  return localStorage.getItem("appVersion");
};

export const setAppVersion = (appVersion: string) => {
  localStorage.setItem("appVersion", appVersion);
};

export const generateLinearGradient = (values: string[]) => {
  let val = "";
  values.forEach((element: string, index: number) => {
    if (index === 0) {
      val += `${element}deg, `;
    } else if (index === values.length - 1) {
      val += `${element}`;
    } else {
      val += `${element}, `;
    }
  });
  return val;
};

export const getTeamIcon = (data: any, index: number) => {
  let imageUrl: string = "";
  if (data && data?.scorecard && data?.scorecard[index]) {
    imageUrl = data?.scorecard[index]?.teamIcon;
  }
  return imageUrl;
};

export const getTeamStats = (data: any, index: number) => {
  let stats: any = null;
  if (
    data &&
    data?.scorecard &&
    data?.scorecard[index] &&
    data?.scorecard[index]?.score
  ) {
    if (Array.isArray(data?.scorecard[index]?.score)) {
      const results = data?.scorecard[index]?.score?.find(
        (item: any) => item?.ongoing
      );
      if (results) {
        stats = { score: results?.scoreText, overs: results?.overs };
      } else {
        stats = {
          score:
            data?.scorecard[index]?.score[
              data?.scorecard[index]?.score.length - 1
            ]?.scoreText,
          overs:
            data?.scorecard[index]?.score[
              data?.scorecard[index]?.score.length - 1
            ]?.overs,
        };
      }
    }
  }
  return stats;
};

export const getTeamName = (data: any, index: number) => {
  let teamName: any = null;
  if (
    data &&
    data?.scorecard &&
    data?.scorecard[index] &&
    data?.scorecard[index]?.teamName
  ) {
    return data?.scorecard[index]?.teamName;
  }
  return teamName;
};

type FormatTimerProps = {
  hours?: number | null;
  minutes?: number | null;
  seconds?: number | null;
};

export const formatTimer = ({ hours, minutes, seconds }: FormatTimerProps) => {
  let timer: any = "";

  if (hours !== null && hours !== undefined && hours >= 0) {
    timer += (hours > 9 ? `${hours}` : `0${hours}`).concat(":");
  }
  if (minutes !== null && minutes !== undefined && minutes >= 0) {
    timer += (minutes > 9 ? `${minutes}` : `0${minutes}`).concat(":");
  }
  if (seconds !== null && seconds !== undefined && seconds >= 0) {
    timer += seconds > 9 ? `${seconds}` : `0${seconds}`;
  }
  return timer;
};

export const foreCastDate = (timestamp: string) => {
  const years = moment(timestamp).diff(moment(), "years");
  const months = moment(timestamp).diff(moment(), "months");
  const days = moment(timestamp).diff(moment(), "days");
  const hours = moment(timestamp).diff(moment(), "hours");

  if (years > 0) {
    return `${years}Y`;
  } else if (months > 0) {
    return `${months}M`;
  } else if (days > 0) {
    return `${days}D`;
  } else if (hours > 0) {
    return `${hours}H`;
  }

  return "";
};

export const getTimeString = (d: number): string => {
  d = Number(d);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var mDisplay = m > 0 ? m + `:` : "";
  var sDisplay = s > 0 ? (s < 10 ? "0" + s : s) : "00";
  var unit =
    m > 0
      ? m === 1
        ? " minute"
        : " minutes"
      : s > 0
      ? s === 1
        ? " second"
        : " seconds"
      : "";
  return mDisplay + sDisplay + unit;
};
export function setUgcPollFormValues(values: string) {
  sessionStorage.setItem("ugcFormValues", values);
}

export function getUgcPollFormValues() {
  let response: any = sessionStorage.getItem("ugcFormValues");
  if (response) {
    response = JSON.parse(response);
  } else {
    response = {};
  }
  return response;
}

export function deleteUgcPollFormValues() {
  sessionStorage.removeItem("ugcFormValues");
}

export function allowSentry() {
  let status = false;
  if (
    process.env.REACT_APP_ENV &&
    ["staging", "production"].includes(process.env.REACT_APP_ENV)
  ) {
    status = true;
  }
  return status;
}

export function resolveHostname() {
  let dsn = process?.env?.REACT_APP_SENTRY_URL_PROD;
  if (window.location.hostname === "widget.probo.in") {
    dsn = process?.env?.REACT_APP_SENTRY_URL_WIDGET;
  }
  return dsn;
}

export function renameMplTopic(value: string) {
  if (!value || value === "MPL") return "";
  const rep = /(MPL#|MPL-|MPL -|MPL #|Mpl-)/g;
  return value.replace(rep, "");
}

export const getTourStatus = () => {
  return localStorage.getItem("tour_enabled");
};

export const setTourStatus = (status: boolean) => {
  localStorage.setItem("tour_enabled", status.toString());
};

export const deleteTourStatus = () => {
  localStorage.removeItem("tour_enabled");
};

export const getAmountValueFromProbonString = (value: any) => {
  if (!value) return -1;
  const [, amount] = value?.split("₹");
  return Number(amount?.trim());
};

export const getLanguagePreferenceRequired = () => {
  return localStorage.getItem("user_language");
};

export const setLanguagePreferenceRequired = (language: string) => {
  if (language) {
    localStorage.setItem("user_language", language);
  }
};

export const deleteLanguagePreferenceRequired = () => {
  localStorage.removeItem("user_language");
};

export const isPartnerUserSessionExists = () => {
  return !!sessionStorage.getItem("partner_user_session_exist");
};

export const setIsPartnerUserSessionExists = (value: any) => {
  sessionStorage.setItem("partner_user_session_exist", value);
};

export const deleteIsPartnerUserSessionExists = () => {
  sessionStorage.removeItem("partner_user_session_exist");
};

export const getTimeDiffInSeconds = (
  timestamp1?: string,
  timestamp2?: string
): number => {
  if (!timestamp1 || !timestamp2) return 0;
  const seconds = moment(timestamp1).diff(timestamp2, "seconds");
  return seconds;
};

export const getNumberDifferenceTillTwoDigit = (p1: string, p2: string) => {
  return parseFloat(p2) - parseFloat(p1);
};

export const decodePartnerUId = (userId: string) => {
  return Buffer.from(userId, "base64").toString("ascii");
};

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const getPlatformType = () => {
  if (
    typeof globalThis?.proboAndroidWebView !== "undefined" &&
    globalThis?.proboAndroidWebView !== null
  ) {
    return "android";
  } else if (globalThis?.webkit?.messageHandlers?.probo_ios_handler) {
    return "iOS";
  } else {
    const userOs: string | any = getUserOs();
    if (["Mac", "Windows", "Linux"].includes(userOs)) return "d_web";
    else return "m_web";
  }
};

export const formatTimeStamp = (timestamp: string, format: string) => {
  const updatedFormat = format;
  return moment(timestamp).utcOffset("+05:30").format(updatedFormat);
};

export const getCurrentDateTimeUTC = () => {
  return moment().utc().format();
};

export const setCryptoGraphId = (id: any) => {
  localStorage.setItem("cryptGraphId", id);
};

export const getCryptGraphId = () => {
  return localStorage.getItem("cryptGraphId");
};

export const getWalletRedirectionUrl = () => {
  return localStorage.getItem("wallet_redirection_url");
};

export const setWalletRedirectionUrl = (url: string) => {
  return localStorage.setItem("wallet_redirection_url", url);
};

export const deleteWalletRedirectionUrl = () => {
  return localStorage.removeItem("wallet_redirection_url");
};
