import { createContext } from "react";

export interface RaiConfig {
  landing?: {
    introduction?: string;
    title?: string;
    subtitle?: string;
    info?: {
      image?: string;
      text?: string;
    };
  };
  settlementInfo?: {
    text?: string;
    icon?: string;
  };
  opinion?: {
    title?: string;
    options?: any;
  };
  ai?: {
    progress?: {
      title?: string;
    };
    success?: {
      title?: string;
    };
  };
  stakes?: {
    title?: string;
  };
  number_of_contracts?: {
    title?: string;
    subtitle?: string;
    max_contracts?: number;
    investment?: {
      put_text?: string;
      get_text?: string;
    };
  };
  contract?: {
    title?: string;
    subtitle?: string;
    opinion?: {
      title?: string;
    };
    investment?: {
      title?: string;
    };
  };
}

export const defaultState: RaiConfig = {
  landing: {
    introduction: "Introducing",
    title: "₹.AI",
    subtitle: "Create contract. Share with friends. AI will settle debate",
    info: {
      image:
        "https://gumlet-images-bucket.s3.ap-south-1.amazonaws.com/probo_product_images/IMAGE_a0296433-ff52-4946-a126-6e67dfb72ee9.png",
      text: "10K+ users have settled debates with ₹.Ai",
    },
  },
  settlementInfo: {
    text: "₹.AI-settles",
    icon: "https://gumlet-images-bucket.s3.ap-south-1.amazonaws.com/probo_product_images/IMAGE_20ae25f5-3d0d-4381-85a9-8830e9474d56.png",
  },
  opinion: {
    title: "Pick your Rai (opinion)",
    options: [
      { btn_text: "Agree", type: "primary" },
      { btn_text: "Disagree", type: "secondary" },
    ],
  },
  ai: {
    progress: {
      title: "₹.Ai is evaluating your input...",
    },
    success: {
      title: "Relax! ₹.AI will setlle this contract for you",
    },
  },
  stakes: {
    title: "Investment per contract",
  },
  number_of_contracts: {
    title: "No. of contracts",
    subtitle: "Edit to contract with multiple friends",
    max_contracts: 99,
    investment: {
      put_text: "You put",
      get_text: "Winner gets",
    },
  },
  contract: {
    title: "Contract created",
    subtitle: "Share this contract with your friends",
    opinion: {
      title: "Your Rai",
    },
    investment: {
      title: "Winner gets",
    },
  },
};

export const RaiContext = createContext<RaiConfig>(defaultState);
