import React, { createRef, useContext, useEffect, useState } from "react";
import { useScreenshot } from "use-react-screenshot";
import { getContractById, postContract, getWalletDetails } from "services/rai";
import { Player } from "@lottiefiles/react-lottie-player";

import Container from "components/Rai/Container";
import Navbar from "components/Rai/Navbar";
import EventCard, { EventProps } from "components/Rai/EventCard";
import RaiButton from "components/Rai/Button";
import ContractCard from "components/Rai/ContractCard";

import Checkmark from "assets/images/rai/checkmark_green.svg";
import RechargeIcon from "assets/icons/rai/recharge.svg";
import Sealed from "assets/images/rai/rai_success.json";
import WhatsappIcon from "assets/icons/rai/whatsapp.svg";
import IosShareIcon from "@mui/icons-material/IosShare";
import DownloadIcon from "@mui/icons-material/Download";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

import styles from "./style.module.scss";
import { CircularLoader } from "components/Loaders";
import { Toast } from "components/Toast";
import { useHistory } from "react-router-dom";
import { AppContext } from "config/context";
import { logFirebaseEvent } from "config/firebase";
import classNames from "classnames";
import { Constants } from "config/constants";
import SwipeableDrawer from "@mui/material/SwipeableDrawer/SwipeableDrawer";
import { setUserDetails } from "services/user";

interface FinalContractProps {
  id: string | number;
}

const FinalContract = ({ id }: FinalContractProps) => {
  const history = useHistory();

  const context = useContext(AppContext);
  const windowWidth = (context?.windowWidth - context?.mobileLayoutWidth) / 2;

  const [nameSheetOpen, setNameSheetOpen] = useState(false);
  const [name, setName] = useState("");

  const ref = createRef<any>();
  const [image, takeScreenshot] = useScreenshot();
  const [isCreator, setIsCreator] = useState(true);
  const [isMatched, setIsMatched] = useState(false);
  const [contract, setContract] = useState<any>();
  const [event, setEvent] = useState<EventProps>();
  const [loading, setLoading] = useState(true);
  const [lowBalance, setLowBalance] = useState(false);
  const [walletbalance, setWalletBalance] = useState<any>();
  const [contractsCompleted, setContractsCompleted] = useState(false);
  const [isRefereeFlow, setIsRefereeFlow] = useState(false);
  const [refereeName, setRefereeName] = useState("");
  const [isMoneyFlow, setIsMoneyFlow] = useState(false);
  const [isValidated, setIsValidated] = useState(false);

  const getContractDetails = async () => {
    const response: any = await getContractById(id);
    setLoading(false);
    if (response?.statusCode === 200) {
      const formattedEvent: EventProps = {
        contract_name: response?.data?.contract_details?.name,
        expiry_dt: response?.data?.contract_details?.expiry,
      };
      setEvent(formattedEvent);
      setContract(response?.data);
      setIsCreator(response?.data?.is_creator);
      setIsMatched(response?.data?.is_matched);
      setContractsCompleted(response?.data?.all_contracts_completed);
      setIsRefereeFlow(response?.data?.is_referee_flow);
      setIsValidated(response?.data?.is_referee_validated);
      setIsMoneyFlow(response?.data?.is_money_flow);
      setRefereeName(response?.data?.referee_name);
    } else if (response?.statusCode === 403) {
      localStorage.clear();
      history.push(`/login?contractId=${id}`);
      Toast({
        message: "Please login again",
        type: "success",
      });
    } else {
      Toast({
        message: response?.message,
        type: "error",
      });
      setLoading(false);
    }
  };

  const getWallet = async () => {
    const response: any = await getWalletDetails();
    if (response?.statusCode === 200) {
      setWalletBalance(response?.data?.total_amount);
    }
  };

  const acceptContract = async () => {
    if (localStorage?.getItem("name")) {
      logFirebaseEvent({
        event_name: "rai_accept_clicked",
        event_value_key1: "format_name",
        event_value_value1: "RAI",
        event_value_key2: "screen",
        event_value_value2: "final_contract",
        event_value_key3: "event_id",
        event_value_value3: id,
      });
      setLoading(true);
      const data = {
        event_id: id,
        contract_qty: 1,
        contract_price: contract?.reward_details?.value,
        offer_type:
          contract?.your_rai_details?.creator_opinion === "disagree"
            ? "BUY"
            : "SELL",
      };
      const response: any = await postContract(data);
      console.log("Response", response);

      if (response.statusCode === 201) {
        console.log(response);
        // const id = 111;
        setLoading(false);
        setIsMatched(true);
      } else {
        Toast({
          message: response?.message,
          type: "error",
        });
        setLoading(false);
      }
    } else {
      setNameSheetOpen(true);
    }
  };

  const getImage = () => {
    if (ref?.current) {
      takeScreenshot(ref?.current);
    }
  };

  const getImageURI = async () => {
    let blob = await fetch(image).then((r) => r.blob());
    let dataUrl = await new Promise((resolve) => {
      let reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
    return dataUrl;
  };

  const dataURLtoFile = (dataurl: string, filename: string) => {
    const arr = dataurl.split(",");
    const mime = arr[0]?.match(/:(.*?);/)![1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const onShareClicked = async () => {
    logFirebaseEvent({
      event_name: "rai_share_clicked",
      event_value_key1: "format_name",
      event_value_value1: "RAI",
      event_value_key2: "screen",
      event_value_value2: "final_contract",
      event_value_key3: "event_id",
      event_value_value3: id,
    });
    navigator?.clipboard?.writeText(contract?.contract_link);
    Toast({
      message: "Copied to clipboard",
      type: "success",
    });

    const imageURI: any = await getImageURI();
    const filesArray = [dataURLtoFile(imageURI, "contract.jpg")];

    if (navigator.share) {
      navigator
        .share({
          // title: `I have invited you on ₹.Ai to contract on "${event?.contract_name}"`,
          text: `Invite to contract on "${event?.contract_name}": ${contract?.contract_link}`,
          // url: contract?.contract_link,
          // files: filesArray,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    }
  };

  const handleNameChange = (e: any) => {
    setName(e?.target?.value);
  };

  const submitNameClicked = async () => {
    if (name?.length > 0) {
      const data = { name: name };
      const response: any = await setUserDetails(data);
      if (response?.statusCode === 200) {
        localStorage.setItem("name", name);
        acceptContract();
        setNameSheetOpen(false);
      } else {
        Toast({
          message: response?.message,
          type: "error",
        });
      }
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      submitNameClicked();
    }
  };

  useEffect(() => {
    if (contract) {
      getImage();
    }
  }, [contract]);

  useEffect(() => {
    if (walletbalance < contract?.contract_winnings?.value) {
      setLowBalance(true);
    }
  }, [walletbalance, contract]);

  useEffect(() => {
    getContractDetails();
    getWallet();
    logFirebaseEvent({
      event_name: "rai_final_contract_screen_loaded",
      event_value_key1: "format_name",
      event_value_value1: "RAI",
      event_value_key2: "screen",
      event_value_value2: "final_contract",
      event_value_key3: "event_id",
      event_value_value3: id,
    });
  }, []);

  return (
    <>
      <Container>
        <>
          {loading && <CircularLoader />}
          {!loading && (
            <>
              <div className={styles["top__container"]}>
                <Navbar title={"₹.AI"} subtitle={"by Probo"} type={"close"} />
                <div
                  className={classNames({
                    [styles["contract__container"]]: true,
                  })}
                >
                  <div className={styles["contract__header"]}>
                    {isCreator && <img src={Checkmark} />}
                    {!isCreator && (
                      <span className={styles["contract__title"]}>
                        Contract
                      </span>
                    )}
                    {isCreator && (
                      <span className={styles["contract__title"]}>
                        Contract created
                      </span>
                    )}
                    {isCreator && (
                      <span className={styles["contract__subtitle"]}>
                        Share this contract with your friends
                      </span>
                    )}
                  </div>
                </div>

                <div
                  className={classNames({
                    [styles["contract__details__container__screenshot"]]: true,
                    [styles[
                      "contract__details__container__screenshot__grayscale"
                    ]]: contractsCompleted && !isRefereeFlow,
                  })}
                  ref={ref}
                >
                  <>
                    {isMatched && !isCreator && (
                      <EventCard
                        event={event!}
                        referee_flow={isRefereeFlow}
                        referee_name={refereeName}
                      />
                    )}
                  </>
                  <>
                    {(!isMatched || isCreator) && (
                      <ContractCard
                        event={event!}
                        reward_details={contract?.reward_details}
                        initiator_rai_details={contract?.initiator_rai_details}
                        rewardDisabled={!isCreator && !isRefereeFlow}
                        is_referee_flow={isRefereeFlow}
                        referee_name={refereeName}
                      />
                    )}
                  </>
                  {isMatched && !isCreator && (
                    <div className={styles["contract__sealed__container"]}>
                      <Player
                        className={styles["contract__sealed__image"]}
                        autoplay
                        loop
                        src={Sealed}
                        style={{ height: "72px", width: "72px" }}
                      ></Player>
                      <span className={styles["contract__sealed__title"]}>
                        Contract sealed
                      </span>
                      <span
                        className={
                          styles["contract__sealed__subtitle__wrapper"]
                        }
                      >
                        <span
                          className={styles["contract__sealed__subtitle__left"]}
                        >
                          {isRefereeFlow ? "Referee" : "₹.AI"}
                        </span>
                        <span
                          className={
                            styles["contract__sealed__subtitle__right"]
                          }
                        >
                          {" "}
                          will settle this contract for you
                        </span>
                      </span>
                    </div>
                  )}
                </div>

                {!isCreator && !isMatched && isMoneyFlow && (
                  <div
                    className={classNames({
                      [styles["investment__return__container"]]: true,
                      [styles["investment__return__container__grayscale"]]:
                        contractsCompleted,
                    })}
                  >
                    <div className={styles["investment__container"]}>
                      <span className={styles["investment__amount"]}>
                        {contract?.contract_winnings?.investment_text}
                      </span>
                      <span className={styles["investment__text"]}>
                        You put
                      </span>
                    </div>
                    <div className={styles["return__container"]}>
                      <span className={styles["return__amount"]}>
                        {contract?.contract_winnings?.winning_text}
                      </span>
                      <span className={styles["return__text"]}>
                        Winner gets
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className={styles["footer__section"]}>
                {isCreator && (
                  <div className={styles["share__section"]}>
                    <RaiButton
                      className={styles["share__button"]}
                      variant="contained"
                      endIcon={<IosShareIcon />}
                      onClick={onShareClicked}
                    >
                      Share contract
                    </RaiButton>
                  </div>
                )}
                {!isCreator &&
                  !isMatched &&
                  !contractsCompleted &&
                  !(isRefereeFlow && !isValidated) && (
                    <>
                      <RaiButton
                        className={styles["button"]}
                        variant="contained"
                        onClick={acceptContract}
                        disabled={lowBalance && isMoneyFlow}
                      >
                        {contract?.accept_contract_cta?.text}
                      </RaiButton>
                    </>
                  )}
                {!isCreator && isRefereeFlow && !isValidated && (
                  <>
                    <div className={styles["contract__completed__section"]}>
                      <span
                        className={
                          styles["contract__completed__title__wrapper"]
                        }
                      >
                        <ErrorOutlineOutlinedIcon
                          className={styles["contract__completed__title__icon"]}
                        />
                        <span className={styles["contract__completed__title"]}>
                          Contract not approved
                        </span>
                      </span>
                      <span className={styles["contract__completed__subtitle"]}>
                        This contract is awaiting validation from referee.
                      </span>
                    </div>
                    <RaiButton
                      className={styles["button"]}
                      variant="contained"
                      onClick={acceptContract}
                      disabled={true}
                    >
                      {contract?.accept_contract_cta?.text}
                    </RaiButton>
                  </>
                )}
                {!isCreator && !isMatched && contractsCompleted && (
                  <>
                    <div className={styles["contract__completed__section"]}>
                      <span
                        className={
                          styles["contract__completed__title__wrapper"]
                        }
                      >
                        <ErrorOutlineOutlinedIcon
                          className={styles["contract__completed__title__icon"]}
                        />
                        <span className={styles["contract__completed__title"]}>
                          Contract Completed
                        </span>
                      </span>
                      <span className={styles["contract__completed__subtitle"]}>
                        This contract is already completed. You can make your
                        own questions on Rai.
                      </span>
                    </div>
                    <RaiButton
                      className={styles["button"]}
                      variant="contained"
                      onClick={() => {
                        history?.push("/question");
                      }}
                    >
                      {"Create now"}
                    </RaiButton>
                  </>
                )}
                {isMatched && !isCreator && (
                  <>
                    <RaiButton
                      className={styles["button"]}
                      variant="contained"
                      onClick={() => {
                        logFirebaseEvent({
                          event_name: "rai_create_question_clicked",
                          event_value_key1: "format_name",
                          event_value_value1: "RAI",
                          event_value_key2: "screen",
                          event_value_value2: "final_contract",
                          event_value_key3: "event_id",
                          event_value_value3: id,
                        });
                        history?.push("/question");
                      }}
                    >
                      {"Create my own question"}
                    </RaiButton>
                  </>
                )}
                {lowBalance &&
                  isMoneyFlow &&
                  !isMatched &&
                  !isCreator &&
                  !contractsCompleted && (
                    <>
                      <div
                        className={styles["recharge__section"]}
                        style={{ width: windowWidth }}
                      >
                        <div className={styles["recharge__container"]}>
                          <div className={styles["recharge__left__container"]}>
                            <img
                              src={RechargeIcon}
                              className={styles["recharge__icon"]}
                            />
                            <div
                              className={styles["recharge__info__container"]}
                            >
                              <span className={styles["recharge__info"]}>
                                ₹
                                {(
                                  contract?.contract_winnings?.value -
                                  walletbalance
                                ).toFixed(1)}{" "}
                                low on money!
                              </span>
                              <span className={styles["recharge__now"]}>
                                Recharge now
                              </span>
                            </div>
                          </div>
                          <button
                            className={styles["recharge__button"]}
                            onClick={() => {
                              logFirebaseEvent({
                                event_name: "rai_recharge_clicked",
                                event_value_key1: "format_name",
                                event_value_value1: "RAI",
                                event_value_key2: "screen",
                                event_value_value2: "final_contract",
                                event_value_key3: "event_id",
                                event_value_value3: id,
                              });
                              window.open(
                                `${Constants?.TRADING_WEB_URL}/recharge`,
                                "_blank"
                              );
                            }}
                          >
                            Add now
                          </button>
                        </div>
                      </div>
                    </>
                  )}
              </div>
            </>
          )}

          {nameSheetOpen && (
            <SwipeableDrawer
              container={window.document.body}
              anchor="bottom"
              open={nameSheetOpen}
              onClose={() => {
                setNameSheetOpen(false);
              }}
              onOpen={() => {}}
              PaperProps={{
                style: {
                  left: windowWidth < 0 ? 0 : windowWidth,
                  maxWidth: context.mobileLayoutWidth,
                  background: "none",
                  boxShadow: "none",
                },
              }}
              BackdropProps={{
                style: {
                  left: windowWidth < 0 ? 0 : windowWidth,
                  maxWidth: context.mobileLayoutWidth,
                },
              }}
              ModalProps={{
                keepMounted: true,
              }}
            >
              <div className={styles["enter__name__container"]}>
                <span className={styles["enter__name__title"]}>
                  Please tell us your name
                </span>
                <input
                  className={styles["enter__name__input"]}
                  autoComplete="off"
                  type="text"
                  onChange={handleNameChange}
                  onKeyDown={handleKeyDown}
                  value={name}
                  autoFocus
                />
                <RaiButton
                  variant="contained"
                  disabled={!(name.length > 0)}
                  onClick={submitNameClicked}
                >
                  Continue
                </RaiButton>
              </div>
            </SwipeableDrawer>
          )}
        </>
      </Container>
    </>
  );
};

export default FinalContract;
