import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import classNames from "classnames";

import { RaiContext } from "config/RaiContext";
import { getWaitlistStatus, getWalletDetails } from "services/rai";
import { AppContext } from "config/context";

import Container from "components/Rai/Container";
import Navbar from "components/Rai/Navbar";
import EventCard, {
  EventCardProps,
  EventProps,
} from "components/Rai/EventCard";
import RaiButton from "components/Rai/Button";

import RechargeIcon from "assets/icons/rai/recharge.png";

import styles from "./style.module.scss";
import { logFirebaseEvent } from "config/firebase";
import { Toast } from "components/Toast";
interface StakesProps {
  event: EventProps;
  opinion?: "agree" | "disagree";
}

const Stakes = ({ event, opinion, referee_flow }: EventCardProps) => {
  const history = useHistory();
  const ref = useRef<any>(null);

  const context = useContext(AppContext);
  const windowWidth = context.mobileLayoutWidth;

  const [stake, setStake] = useState<any>();
  const [moneyEnabled, setMoneyEnabled] = useState(true);

  const getWaitlist = async () => {
    const response: any = await getWaitlistStatus();
    if (response?.data) {
      setMoneyEnabled(response?.data?.isEligibleForMoneyFlow);
    }
  };

  const handleChange = (e: any) => {
    if (e?.target?.value != "0") setStake(e?.target?.value);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" && stake) {
      logFirebaseEvent({
        event_name: "rai_stakes_entered",
        event_value_key1: "format_name",
        event_value_value1: "RAI",
        event_value_key2: "screen",
        event_value_value2: "stakes",
        event_value_key3: "stakes",
        event_value_value3: stake,
        event_value_key4: "request_id",
        event_value_value4: event?.request_id,
      });
      history.push("/create/number-of-contracts", {
        event: event,
        opinion: opinion,
        winning_text: stake,
        is_money_flow: false,
        referee_flow: referee_flow,
        contract_price: 5,
      });
    }
  };

  useEffect(() => {
    ref?.current?.select();
    getWaitlist();
    logFirebaseEvent({
      event_name: "rai_stakes_screen_loaded",
      event_value_key1: "format_name",
      event_value_value1: "RAI",
      event_value_key2: "screen",
      event_value_value2: "stakes",
      event_value_key3: "request_id",
      event_value_value3: event?.request_id,
    });
  }, []);

  return (
    <RaiContext.Consumer>
      {(config) => (
        <Container>
          <div className={styles["top__container"]}>
            <Navbar title={"₹.AI"} subtitle={"by Probo"} />
            <div className={styles["create__container"]}>
              <span className={styles["contract__title__wrapper"]}>
                <span className={styles["contract__title"]}>Contract</span>
              </span>
              <EventCard
                event={event}
                opinion={opinion}
                referee_flow={referee_flow}
              />
              <div className={styles["contract__stakes__container"]}>
                <span className={styles["contract__stakes__title"]}>
                  {"What does the winner get?"}
                </span>
                <div className={styles["contract__stakes__input__wrapper"]}>
                  <input
                    className={styles["contract__stakes__input"]}
                    autoFocus
                    type="text"
                    value={stake}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    placeholder="Enter reward for the contract winner"
                    ref={ref}
                    onFocus={(e: any) => {
                      ref?.current?.select();
                    }}
                  />
                </div>
                {moneyEnabled && (
                  <div className={styles["contract__stakes__cta__container"]}>
                    <RaiButton
                      variant="contained"
                      onClick={() => {
                        history.push("/create/number-of-contracts", {
                          event: event,
                          opinion: opinion,
                          winning_text: stake,
                          is_money_flow: false,
                          referee_flow: referee_flow,
                          contract_price: 5,
                        });
                      }}
                      disabled={!stake || stake?.length === 0}
                    >
                      Continue
                    </RaiButton>
                    <span
                      className={styles["contract__stakes__cta__separator"]}
                    >
                      or
                    </span>
                    <RaiButton
                      variant="outlined"
                      onClick={() => {
                        history.push("/create/monetary", {
                          event: event,
                          opinion: opinion,
                          referee_flow: referee_flow,
                        });
                      }}
                    >
                      Contract with real money
                    </RaiButton>
                  </div>
                )}
              </div>
            </div>
          </div>
          {!moneyEnabled && (
            <div className={styles["footer__section"]}>
              <RaiButton
                variant="contained"
                onClick={() => {
                  history.push("/create/number-of-contracts", {
                    event: event,
                    opinion: opinion,
                    winning_text: stake,
                    is_money_flow: false,
                    referee_flow: referee_flow,
                    contract_price: 5,
                  });
                }}
                disabled={!stake || stake?.length === 0}
              >
                Confirm
              </RaiButton>
            </div>
          )}
        </Container>
      )}
    </RaiContext.Consumer>
  );
};

export default Stakes;
