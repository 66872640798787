// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_tab__sOGGL {\n  display: flex;\n  flex: 1 1;\n}", "",{"version":3,"sources":["webpack://src/components/TabPanel/style.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,SAAA;AADF","sourcesContent":["@import \"scss/variables.scss\";\n\n.tab {\n  display: flex;\n  flex: 1;\n}\n\n// Mobile Devices (380px and up)\n@media (min-width: 360px) {\n}\n\n// Mobile Devices (420px and up)\n@media (min-width: 420px) {\n}\n\n// Mobile Devices (480px and up)\n@media (min-width: 480px) {\n}\n\n// Small devices (landscape phones, 576px and up)\n@media (min-width: 576px) {\n}\n\n// Medium devices (tablets, 768px and up)\n@media (min-width: 768px) {\n}\n\n// Large devices (desktops, 992px and up)\n@media (min-width: 992px) {\n}\n\n// X-Large devices (large desktops, 1200px and up)\n@media (min-width: 1200px) {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tab": "style_tab__sOGGL"
};
export default ___CSS_LOADER_EXPORT___;
