import React, { useContext, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import {
  createGroupContract,
  getGroupContractDetails,
  getWalletDetails,
} from "services/rai";

import RaiButton from "components/Rai/Button";
import { Toast } from "components/Toast";
import { CircularLoader } from "components/Loaders";
import Container from "components/Rai/Container";
import Navbar from "components/Rai/Navbar";
import PollCard from "components/Rai/PollCard";

import Success from "assets/images/rai/checkmark.svg";
import Deadline from "assets/images/rai/deadline.svg";
import IosShareIcon from "@mui/icons-material/IosShare";
import RechargeIcon from "assets/icons/rai/recharge.png";

import styles from "./style.module.scss";
import { logFirebaseEvent } from "config/firebase";
import { Constants } from "config/constants";
import { AppContext } from "config/context";

interface ContractProps {
  id: number | string;
}

const Contract = ({ id }: ContractProps) => {
  const history = useHistory();

  const context = useContext(AppContext);
  const windowWidth = context.mobileLayoutWidth;

  const [isCreator, setIsCreator] = useState(true);
  const [isMatched, setIsMatched] = useState(false);
  const [contract, setContract] = useState<any>();
  const [selectedIndex, setSelectedIndex] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [deadlinePassed, setDeadlinePassed] = useState(false);
  const [lowBalance, setLowBalance] = useState(false);
  const [walletbalance, setWalletBalance] = useState<any>();
  const [spotsFilled, setSpotsFilled] = useState(false);
  const [count, setCount] = useState(0);

  const getWallet = async () => {
    const response: any = await getWalletDetails();
    if (response?.statusCode === 200) {
      setWalletBalance(response?.data?.total_amount);
    }
  };

  const getContract = async () => {
    const response: any = await getGroupContractDetails(id);
    if (response?.statusCode === 201) {
      setContract(response?.data);
      setLoading(false);
      setIsCreator(response?.data?.is_creator);
      setSelectedIndex(response?.data?.selected_option);
      setSpotsFilled(
        response?.data?.max_user_limit === response?.data?.number_of_members
      );
    } else if (response?.statusCode === 403) {
      localStorage.clear();
      history.push(`/login?contractId=${id}&format=group`);
      Toast({
        message: "Please login again",
        type: "success",
      });
    }
  };

  const onShareClicked = async () => {
    logFirebaseEvent({
      event_name: "rai_share_clicked",
      event_value_key1: "format_name",
      event_value_value1: "RAI",
      event_value_key2: "screen",
      event_value_value2: "contract_summary",
      event_value_key3: "event_id",
      event_value_value3: id,
      event_value_key4: "rai_format",
      event_value_value4: "group",
    });
    navigator?.clipboard?.writeText(contract?.shareable_link);
    Toast({
      message: "Copied to clipboard",
      type: "success",
    });

    // const imageURI: any = await getImageURI();
    // const filesArray = [dataURLtoFile(imageURI, "contract.jpg")];

    if (navigator.share) {
      navigator
        .share({
          // title: `I have invited you on ₹.Ai to contract on "${event?.contract_name}"`,
          text: `Invite to contract on "${contract?.name}": ${contract?.shareable_link}`,
          // url: contract?.contract_link,
          // files: filesArray,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    }
  };

  const acceptContract = async () => {
    logFirebaseEvent({
      event_name: "rai_accept_clicked",
      event_value_key1: "format_name",
      event_value_value1: "RAI",
      event_value_key2: "screen",
      event_value_value2: "final_contract",
      event_value_key3: "event_id",
      event_value_value3: id,
      event_value_key4: "rai_format",
      event_value_value4: "group",
    });
    setLoading(true);
    if (contract?.is_deadline_passed) {
      setDeadlinePassed(true);
      setLoading(false);
    } else {
      const data = {
        poll_id: contract?.poll_id,
        investment: contract?.investment,
        selected_options: selectedIndex,
      };
      const response: any = await createGroupContract(data);
      console.log("Response", response);

      if (response.statusCode === 201) {
        setLoading(false);
        setIsMatched(true);
      } else {
        Toast({
          message: "Something went wrong",
          type: "error",
        });
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (walletbalance < contract?.investment) {
      setLowBalance(true);
    }
  }, [walletbalance, contract]);

  useEffect(() => {
    logFirebaseEvent({
      event_name: "rai_contract_screen_loaded",
      event_value_key1: "format_name",
      event_value_value1: "RAI",
      event_value_key2: "screen",
      event_value_value2: "contract",
      event_value_key3: "rai_format",
      event_value_value3: "group",
      event_value_key4: "event_id",
      event_value_value4: id,
    });

    getContract();
    getWallet();
  }, []);

  return (
    <Container>
      <div className={styles["contract__container"]}>
        <Navbar
          title="₹.AI"
          subtitle={"by Probo"}
          type={"close"}
          customBackLink={"/"}
        />
        {isMatched && (
          <div className={styles["contract__content__container__matched"]}>
            <img
              className={styles["contract__sealed__image"]}
              src={Success}
            ></img>
            <span className={styles["contract__title"]}>
              Investment completed
            </span>
          </div>
        )}
        {(deadlinePassed || spotsFilled) && !isCreator && (
          <div className={styles["contract__content__container__matched"]}>
            <img
              className={styles["contract__sealed__image"]}
              src={Deadline}
            ></img>
            <span className={styles["contract__title"]}>
              {spotsFilled ? "No spots left" : "Deadline Passed!"}
            </span>
            <span className={styles["contract__subtitle"]}>
              {spotsFilled
                ? "All spots in this poll have been filled. Please create your own contract"
                : "You can’t invest in this contract anymore. Please create your own contract"}
            </span>
            <RaiButton
              className={styles["create__button"]}
              variant="contained"
              onClick={() => {
                logFirebaseEvent({
                  event_name: "rai_create_contract_clicked",
                  event_value_key1: "format_name",
                  event_value_value1: "RAI",
                  event_value_key2: "screen",
                  event_value_value2: "contract",
                  event_value_key3: "rai_format",
                  event_value_value3: "group",
                  event_value_key4: "event_id",
                  event_value_value4: id,
                });
                history?.push("/group/question/list");
              }}
            >
              Create contract
            </RaiButton>
          </div>
        )}
        {loading && !isMatched && <CircularLoader />}
        {!loading && !isMatched && !deadlinePassed && (
          <div className={styles["contract__content__container"]}>
            <div className={styles["contract__title__container"]}>
              {!isCreator && (
                <>
                  <span className={styles["contract__title"]}>Contract</span>
                </>
              )}
              {isCreator && (
                <>
                  <img
                    className={styles["contract__sealed__image"]}
                    src={Success}
                  ></img>
                  <span className={styles["contract__title"]}>
                    Ready to share
                  </span>
                  <span className={styles["contract__subtitle"]}>
                    Share this event with your friends
                  </span>
                </>
              )}
            </div>
            <PollCard
              contract={contract}
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
              isCreator={isCreator}
            />
            {!isCreator && (
              <div className={styles["investment__return__container"]}>
                <div className={styles["investment__container"]}>
                  <span className={styles["investment__amount"]}>
                    ₹{contract?.investment}
                  </span>
                  <span className={styles["investment__text"]}>You put</span>
                </div>
                <div className={styles["return__container"]}>
                  <span className={styles["return__amount"]}>
                    ₹{contract?.potential_returns}
                  </span>
                  <span className={styles["return__text"]}>
                    Potential returns
                  </span>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div className={styles["footer__section"]}>
        {isCreator && (
          <>
            <RaiButton
              className={styles["share__button"]}
              variant="contained"
              endIcon={<IosShareIcon />}
              onClick={onShareClicked}
            >
              Share contract
            </RaiButton>
          </>
        )}
        {!isCreator && !isMatched && !deadlinePassed && (
          <>
            <RaiButton
              className={styles["share__button"]}
              variant="contained"
              onClick={acceptContract}
              disabled={selectedIndex === -1 || lowBalance}
            >
              Continue
            </RaiButton>
            {lowBalance && !isMatched && !isCreator && (
              <>
                <div
                  className={styles["recharge__section"]}
                  style={{ width: windowWidth }}
                >
                  <div className={styles["recharge__container"]}>
                    <div className={styles["recharge__left__container"]}>
                      <img
                        src={RechargeIcon}
                        className={styles["recharge__icon"]}
                      />
                      <div className={styles["recharge__info__container"]}>
                        <span className={styles["recharge__info"]}>
                          ₹{(contract?.investment - walletbalance).toFixed(1)}{" "}
                          low on money!
                        </span>
                        <span className={styles["recharge__now"]}>
                          Recharge now
                        </span>
                      </div>
                    </div>
                    <button
                      className={styles["recharge__button"]}
                      onClick={() => {
                        logFirebaseEvent({
                          event_name: "rai_recharge_clicked",
                          event_value_key1: "format_name",
                          event_value_value1: "RAI",
                          event_value_key2: "screen",
                          event_value_value2: "final_contract",
                          event_value_key3: "event_id",
                          event_value_value3: id,
                        });
                        window.open(
                          `${Constants?.TRADING_WEB_URL}/recharge`,
                          "_blank"
                        );
                      }}
                    >
                      Add now
                    </button>
                  </div>
                </div>
              </>
            )}
          </>
        )}
        {!isCreator && isMatched && !deadlinePassed && (
          <>
            <RaiButton
              className={styles["share__button"]}
              variant="contained"
              onClick={() => {
                history?.push("/");
              }}
            >
              Continue
            </RaiButton>
          </>
        )}
      </div>
    </Container>
  );
};

export default Contract;
