import React, { useContext } from "react";
import classNames from "classnames";
import { ToastContainer } from "react-toastify";
import { AppContext } from "config/context";
import { useClearCacheCtx } from "react-clear-cache";

import "react-toastify/dist/ReactToastify.css";

// Types
export type MainLayoutProps = {
  id?: string;
  style?: object;
  className?: string;
  children: React.ReactElement;
};

const MainLayout = ({ id, style, className, children }: MainLayoutProps) => {
  console.log("MainLayout Render");

  const context = useContext(AppContext);
  style = { ...style, maxWidth: context.mobileLayoutWidth };

  const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();
  if (process.env.REACT_APP_ENV === "production" && !isLatestVersion) {
    emptyCacheStorage();
  }

  return (
    <div
      id={id}
      className={classNames({
        mobile__layout: true,
        [`${className}`]: className,
      })}
      style={style}
    >
      {children}
      <ToastContainer
        style={{ width: "100%", maxWidth: context.mobileLayoutWidth }}
      />
    </div>
  );
};

export default MainLayout;
