import { VALIDATE_OTP_URL, httpClient } from "config/api";

export type ValidateOtpServiceProps = {
  mobile?: string;
  opt?: number;
  token?: string;
  type?: string;
};

/**
 * Function to validate user otp
 * @param data
 * @returns
 */
export const validateOtp = async (data: ValidateOtpServiceProps) => {
  return httpClient.post({
    url: `${VALIDATE_OTP_URL}`,
    data,
  });
};
