import { useState } from "react";
import classNames from "classnames";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import styles from "./style.module.scss";

interface CollapsibleProps {
  title: string;
  subtitle?: string;
  description: string;
}

const Collapsible = ({ title, subtitle, description }: CollapsibleProps) => {
  const [collapse, toggleCollapse] = useState(false);

  return (
    <div
      className={styles["collapsible"]}
      onClick={() => {
        toggleCollapse(!collapse);
      }}
    >
      <div className={styles["collapsible__body"]}>
        <div className={styles["collapsible__title__wrapper"]}>
          <div className={styles["collapsible__title__subtitle"]}>
            <div
              className={classNames({
                [styles["collapsible__title"]]: true,
                [styles["collapsible__title__collapsed"]]: collapse,
              })}
            >
              {title}
            </div>
            <div
              className={classNames({
                [styles["collapsible__subtitle"]]: true,
                [styles["collapsible__subtitle__collapsed"]]: collapse,
              })}
            >
              {subtitle}
            </div>
          </div>

          <div className={styles["collapsible__actions"]}>{!collapse ? <ExpandMoreIcon /> : <ExpandLessIcon />}</div>
        </div>
        {collapse && <span className={styles["collapsible__description"]}>{description}</span>}
      </div>
    </div>
  );
};

export default Collapsible;
