import React, { useEffect, useState, createContext } from "react";
import { RaiContext } from "config/RaiContext";

import { getWaitlistStatus, prompt } from "services/rai";

import Navbar from "components/Rai/Navbar";
import RaiButton from "components/Rai/Button";

import styles from "./style.module.scss";
import Container from "components/Rai/Container";
import { useHistory } from "react-router-dom";
import Loading from "components/Rai/Loading";
import classNames from "classnames";
import { getPlatformType } from "config/helper";
import { logFirebaseEvent } from "config/firebase";

interface QuestionProps {
  value?: string;
}

const Question = ({ value }: QuestionProps) => {
  const history = useHistory();

  const [event, setEvent] = useState<any>();
  const [question, setQuestion] = useState("");
  const [placeholder, setPlaceholer] = useState(
    "Virat Kohli will score more runs than Rohit Sharma in IPL’23"
  );
  const [header, setHeader] = useState("What's your question?");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<"success" | "loading" | "input">(
    "input"
  );
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loadingMessage, setLoadingMessage] = useState(
    "₹.Ai is evaluating your input..."
  );
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    setQuestion(value || "");
    getWaitlist();
    logFirebaseEvent({
      event_name: "rai_question_screen_loaded",
      event_value_key1: "format_name",
      event_value_value1: "RAI",
      event_value_key2: "screen",
      event_value_value2: "question",
    });
  }, []);

  const getWaitlist = async () => {
    const response: any = await getWaitlistStatus();
    if (response?.data) {
      response?.data?.isUserWaitListed && history?.push("/");
    }
  };

  const postQuestion = async () => {
    setLoading(true);
    const data = {
      name: question,
    };
    const response: any = await prompt(data);
    if (response?.data?.type === "success") {
      setEvent(response?.data);
      setStatus("success");
      logFirebaseEvent({
        event_name: "rai_question_success",
        event_value_key1: "format_name",
        event_value_value1: "RAI",
        event_value_key2: "screen",
        event_value_value2: "question",
        event_value_key3: "question",
        event_value_value3: question,
      });
      if (response?.data?.template_id === 0) {
        history.push("/suggestions", { event: response?.data });
      } else {
        setTimeout(() => {
          history.push("/create", {
            event: response?.data,
            referee_flow: false,
          });
        }, 4000);
      }
    } else if (response?.data?.type === "missing") {
      setLoading(false);

      setErrorMessage(response?.data?.message);
    } else if (response?.data?.type === "suggestion") {
    } else if (response?.data?.type === "profanity") {
    } else {
      setLoading(false);
      logFirebaseEvent({
        event_name: "rai_question_fail",
        event_value_key1: "format_name",
        event_value_value1: "RAI",
        event_value_key2: "screen",
        event_value_value2: "question",
        event_value_key3: "question",
        event_value_value3: question,
      });
      history.push("/suggestions", { question: question, type: "beyond" });
      // setErrorMessage(response?.message);
    }
  };

  const handleFocus = (focus: boolean) => {
    if (["android", "iOS", "m_web"].includes(getPlatformType())) {
      if (!focus && question?.length > 0) {
        postQuestion();
      }
      setIsFocused(focus);
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" && question) {
      postQuestion();
    }
  };

  return (
    <Container>
      <Navbar title="₹.AI" subtitle={"by Probo"} customBackLink={"/"} />

      {loading && (
        <>
          <Loading message={loadingMessage} status={status} />
        </>
      )}

      {!loading && status === "success" && (
        <Loading message={loadingMessage} status="success" />
      )}
      {!loading && status === "input" && (
        <div className={styles["question__response__container"]}>
          <div className={styles["question"]}>{header}</div>
          <div className={styles["answer__wrapper"]}>
            <textarea
              placeholder={placeholder}
              className={styles["answer"]}
              onChange={(e: any) => {
                setQuestion(e.target.value);
                setErrorMessage("");
              }}
              value={question}
              autoFocus
              onKeyDown={handleKeyDown}
              onKeyPress={(e) => {
                if (e.key === "Enter") e.preventDefault();
              }}
              onFocus={(e) => handleFocus(true)}
              onBlur={(e) => handleFocus(false)}
              rows={3}
            ></textarea>
            <span className={styles["error__message"]}>{errorMessage}</span>
            {isFocused && (
              <RaiButton
                variant="contained"
                onClick={postQuestion}
                disabled={question?.length === 0}
              >
                Send
              </RaiButton>
            )}
          </div>
          <span className={styles["beta__text"]}>
            This is a beta version only for IND v AUS Series
          </span>
        </div>
      )}
      {!loading && status === "input" && !isFocused && (
        <RaiButton
          variant="contained"
          onClick={postQuestion}
          disabled={question?.length === 0}
        >
          Send
        </RaiButton>
      )}
    </Container>
  );
};

export default Question;
