import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

// Components
import { Toast } from "components/Toast";
import { CircularLoader } from "components/Loaders";
import ContentLoader from "react-content-loader";

import PaginatedList from "components/PaginatedList";
import PortfolioEventCard from "components/Rai/EventCard/PortfolioEventCard";
import NoData from "components/NoData";

import FilterIcon from "assets/icons/filter.svg";
import InfoIcon from "assets/icons/infoIcon.png";

// Services
import {
  getActivePortfolio,
  getActivePortfolioCard,
  getSettledPortfolio,
  getSettledPortfolioCard,
} from "services/rai";

// Styles
import styles from "./style.module.scss";

import { AppContext } from "config/context";
import { logFirebaseEvent } from "config/firebase";
import { getSourceName, getUserOs, setPortfolioReturns } from "config/helper";
import { CardActionArea } from "@mui/material";
import { Constants } from "config/constants";

type PortfolioTabProps = {
  tabType: string;
  pageNumber?: number;
  hasMoreData?: boolean;
  showOldStyle?: boolean;
  onClick?: Function;
  endpoint?: string;
  threadId?: string;
  categoryIds?: any;
};
const PortfolioLoader = () => {
  console.log("PortfolioLoader Render");
  const context = useContext(AppContext);
  return (
    <ContentLoader
      viewBox={`0 0 ${context.mobileLayoutWidth} 150`}
      backgroundColor={"#000000"}
    >
      <rect
        x="0"
        y="0"
        rx="2"
        ry="2"
        width={context.mobileLayoutWidth}
        height="150"
      />
    </ContentLoader>
  );
};
const PortfolioEvents = ({
  tabType,
  pageNumber = 0,
  hasMoreData,
  showOldStyle,
  onClick,
  endpoint,
  threadId,
  categoryIds,
}: PortfolioTabProps) => {
  console.log("PortfolioEvents Render");

  const context = useContext(AppContext);

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(pageNumber);
  const [hasMore, setHasMore] = useState(true);
  const [cardData, setCardData] = useState<any>([]);
  const [source] = useState(getSourceName());
  const [summaryData, setSummaryData] = useState<any>();
  const [pendingOrders, setPendingOrders] = useState<any>();

  const [bottomSheetEventId, setBottomSheetEventId] = useState<null | number>(
    null
  );
  const [bottomSheetOpen, setBottomSheetOpen] = useState<boolean>(false);
  const [bottomSheetEventStatus, setBottomSheetEventStatus] = useState<
    null | string
  >(null);
  const [, setBottomSheetAction] = useState<string>("");

  const loadingData = Array.from(Array(5));

  const getPortolfioSummaryCard = async () => {
    const response: any =
      tabType === "open"
        ? await getActivePortfolioCard()
        : await getSettledPortfolioCard();
    if (response?.statusCode === 200) {
      console.log("Card response", response);
      setSummaryData(response?.data);
      setPendingOrders(response?.data?.pending_order);
    }
  };

  const getOpenPortfolioEventsData = async (refresh?: boolean) => {
    const headers: any = {
      source,
    };

    let queryPage = page;

    if (!refresh) {
      queryPage += 1;
    }

    let response: any = {};
    if (endpoint !== null && endpoint && !!queryPage) {
    } else {
      const data = { page: queryPage };
      response =
        tabType === "open"
          ? await getActivePortfolio(data)
          : await getSettledPortfolio(data);
    }
    setLoading(false);

    if (response && !response?.isError) {
      let newData = [];
      if (refresh) {
        newData = endpoint ? response?.data?.list : response?.data?.records;
      } else {
        newData = cardData.concat(
          endpoint ? response?.data?.list : response?.data?.records
        );
      }
      if (response?.data?.showArchiveData && response?.data?.archiveDataView) {
        // newData.push({ archiveDataView: response?.data?.archiveDataView, type: "archived" });
      }

      setCardData(newData);
      setHasMore(response?.data?.is_remaining);
      if (response?.data?.page_no) {
        setPage(response?.data?.page_no);
      }
    } else {
      Toast({
        message: response?.message,
        type: "error",
      });
    }
  };

  useEffect(() => {
    getOpenPortfolioEventsData();
    getPortolfioSummaryCard();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getOpenPortfolioEventsData(true);
    // eslint-disable-next-line
  }, [categoryIds]);

  useEffect(() => {
    if (context.cancelOrderEventSuccess) {
      getOpenPortfolioEventsData(true);
    }
    // eslint-disable-next-line
  }, [context.cancelOrderEventSuccess]);

  useEffect(() => {
    if (!bottomSheetOpen) {
      setBottomSheetEventId(null);
      setBottomSheetAction("");
      setBottomSheetEventStatus(null);
    }
  }, [bottomSheetOpen]);

  if (!loading && cardData?.length === 0) {
    return (
      <NoData
        message={
          tabType === "open" ? "No Active Contracts" : "No Settled Contracts"
        }
        style={{
          height:
            context.windowHeight -
            (context.mobileNavHeight +
              context.portfolioTabHeight +
              context.mobileBottomNavHeight +
              30),
        }}
      />
    );
  }

  return (
    <React.Fragment>
      <div className={styles["portfolio__summary"]}>
        {!summaryData && <CircularLoader />}
        {summaryData && (
          <>
            <div className={styles["portfolio__main"]}>
              <div className={styles["portfolio__main__left"]}>
                <div className={styles["portfolio__main__left__value"]}>
                  {summaryData?.card_elements &&
                    summaryData?.card_elements[0]?.value}
                </div>
                <div className={styles["portfolio__main__left__text"]}>
                  Total investment
                </div>
              </div>
              <div className={styles["portfolio__main__right"]}>
                <div
                  className={styles["portfolio__main__right__value"]}
                  style={{
                    color: summaryData?.card_elements
                      ? summaryData?.card_elements[1]?.value_color
                      : "#ffffff",
                  }}
                >
                  {tabType === "open"
                    ? `₹${summaryData?.matched_investment}`
                    : summaryData?.card_elements &&
                      summaryData?.card_elements[1]?.value}
                </div>
                <div className={styles["portfolio__main__right__text"]}>
                  {tabType === "open" ? "Matched investment" : "Total returns"}
                </div>
              </div>
            </div>
            <div className={styles["portfolio__bottom"]}>
              {tabType === "open"
                ? `Open contracts: ${pendingOrders}`
                : `${summaryData?.bottom_bar?.left_text}`}
            </div>
          </>
        )}
      </div>
      <PaginatedList
        height={["Android", "iOS"].includes(getUserOs()!) ? undefined : 500}
        loading={loading}
        next={getOpenPortfolioEventsData}
        hasMore={hasMore}
        props={{
          tabType,
          onClickHandler: onClick ? onClick : null,
        }}
        data={loading && cardData?.length === 0 ? loadingData : cardData}
        component={
          loading && cardData?.length === 0
            ? PortfolioLoader
            : PortfolioEventCard
        }
        refreshFunction={getOpenPortfolioEventsData}
      />
    </React.Fragment>
  );
};

const PortfolioTab = (props: PortfolioTabProps) => {
  const { tabType, endpoint } = props;
  console.log("PortfolioTab Render");

  const context = useContext(AppContext);
  const [loading] = useState(false);
  const [source] = useState(getSourceName());
  const history = useHistory();
  const [categoryIds, setCategoryIds] = useState([]);

  const headers: any = {
    source,
  };

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles["portfolio__wrapper"]}>
      {loading && (
        <CircularLoader
          style={{
            height: "100vh",
          }}
        />
      )}
      {!loading && <PortfolioEvents {...props} categoryIds={categoryIds} />}
    </div>
  );
};

export default React.memo(PortfolioTab);
