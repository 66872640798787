import React from "react";
// Styles
import styles from "./style.module.scss";
export type LayoutProps = {
  children: React.ReactElement;
};
const EnvironmentLable = ({ children }: LayoutProps) => {
  return (
    <div style={{ position: "relative", overflow: "hidden" }}>
      {/* <div className={styles["lable"]}>{process.env.REACT_APP_ENV}</div> */}
      <div>{children}</div>
    </div>
  );
};

export default EnvironmentLable;
