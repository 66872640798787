import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import classNames from "classnames";

import OtpInput from "react-otp-input";
import { validateOtp } from "services/auth/otp";
import { Constants } from "config/constants";
import {
  setUserType,
  setSessionId,
  setUserMobileNumber,
  deleteUserReferralCode,
  setUserId,
  getUserMobileNumber,
  deleteAuthData,
  setUserToken,
  getPlatformType,
} from "config/helper";
import { Toast } from "components/Toast";
import RaiButton from "components/Rai/Button";
import Container from "components/Rai/Container";
import Navbar from "components/Rai/Navbar";
import styles from "./style.module.scss";
import { timer } from "d3";
import { AppContext } from "config/context";
import { Button } from "@mui/material";
import { getPublicContractDetails } from "services/rai";
import { logFirebaseEvent } from "config/firebase";
import { LoginServiceProps, login } from "services/auth/login";
import { getUserDetails } from "services/user";

const publicContract = {
  title: "You are invited to a contract with",
  profile: {
    image:
      "https://gumlet-images-bucket.s3.ap-south-1.amazonaws.com/probo_product_images/IMAGE_24f6ecef-e5d0-40e3-b8c9-9ed57b5d546b.png",
    name: "@Aman_Gol",
  },
  expiry: {
    text: "with tentative expiry Today",
  },
};

const otpStyles = {
  containerStyle: { display: "flex", color: "#ffffff" },
  inputStyle: {
    width: 48,
    height: 48,
    borderRadius: 8,
    marginRight: 5,
    background:
      "linear-gradient(0deg, #19171D, #19171D), linear-gradient(0deg, #45DE92, #45DE92)",
    border: "1px solid #45DE92",
    outline: "none",
    color: "#ffffff",
    fontSize: 16,
  },
  errorStyle: {},
};

const Otp = () => {
  const context = useContext(AppContext);
  const history = useHistory();

  const { search } = useLocation();
  const param = new URLSearchParams(search);
  const contractId = param.get("contractId");
  const user = param.get("user");
  const format = param.get("format");

  const [contract, setContract] = useState<any>();

  const [timer, setTimer] = useState(Constants.OTP_TIMER);
  const [number, setNumber] = useState<number>();
  const [otp, setOtp] = useState<number>();
  const [isFocused, setIsFocused] = useState(false);

  const getPublicContract = async () => {
    const response: any = await getPublicContractDetails(contractId!);
    if (response?.statusCode === 200) {
      setContract(response?.data);
    } else {
      Toast({
        message: response?.message,
        type: "error",
      });
    }
  };

  const getUser = async () => {
    const response: any = await getUserDetails();
    if (response?.statusCode === 200) {
      response?.data?.name &&
        localStorage?.setItem("name", response?.data?.name);
    }
  };

  const handleResendOtp = async () => {
    if (timer === 0) {
      setTimer(Constants.OTP_TIMER);
      const param = new URLSearchParams(search);
      const contractId = param.get("contractId");
      const format = param.get("format");

      const data: LoginServiceProps = {
        mobile: getUserMobileNumber() || "",
        is_following_referee: false,
      };
      const response: any = await login(data);

      if (response && !response?.isError) {
        setUserType(response?.data?.is_new_user);
        setSessionId(response?.data?.sessionId);
        setUserId(response?.data?.userId);
        if (response?.data?.message) {
          Toast({
            message: response?.data?.message,
            type: "success",
          });
        }
        logFirebaseEvent({
          event_name: "rai_resend_otp_clicked",
          event_value_key1: "format_name",
          event_value_value1: "RAI",
          event_value_key2: "screen",
          event_value_value2: "login",
          event_value_key3: "phone_number",
          event_value_value3: number,
        });
        setUserMobileNumber(number?.toString() || "");
      } else {
        Toast({
          message: response?.message,
          type: "error",
        });
        if (response?.metaData?.user_exist) {
          deleteUserReferralCode();
        }
      }
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleOtpSubmit();
    }
  };

  const handleOtpSubmit = async () => {
    const data: any = { otp: otp };
    if (getUserMobileNumber()) {
      data.mobile = getUserMobileNumber();
    }

    const response = await validateOtp(data);

    if (response && !response?.isError) {
      if (response?.data?.AUTH_TOKEN?.token) {
        deleteAuthData();
        setUserId(response?.data?.user_id);
        setUserToken(response?.data?.AUTH_TOKEN?.token);
        logFirebaseEvent({
          event_name: "rai_logged_in",
          event_value_key1: "format_name",
          event_value_value1: "RAI",
          event_value_key2: "screen",
          event_value_value2: "otp",
          event_value_key3: "phone_number",
          event_value_value3: number,
        });
        setTimeout(() => {
          context.setUserLoggedIn(true);
          getUser();
          if (contractId != "null") {
            user === "ref"
              ? history.push(`/contract/${contractId}/ref`)
              : format !== "group"
              ? history.push(`/contract/${contractId}`)
              : history.push(`/group/contract/${contractId}`);
          } else {
            history.push("/");
          }
        }, 500);
      }
    } else {
      Toast({
        message: response?.message,
        type: "error",
      });
    }
    /* Container Code */
  };

  const handleOtpChange = (value: any) => {
    setOtp(value);
  };

  const handleFocus = (focus: boolean) => {
    if (["android", "iOS", "m_web"].includes(getPlatformType())) {
      if (!focus && otp?.toString().length === 6) {
        handleOtpSubmit();
      }
      setIsFocused(focus);
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (timer === 0) {
        clearInterval(interval);
      } else {
        setTimer((timer) => timer - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    logFirebaseEvent({
      event_name: "rai_otp_screen_loaded",
      event_value_key1: "format_name",
      event_value_value1: "RAI",
      event_value_key2: "screen",
      event_value_value2: "otp",
    });
  }, []);

  return (
    <Container>
      <div className={styles["top__container"]}>
        <Navbar title="₹.AI" subtitle="by Probo" portfolioDisabled />
        <div className={styles["contract__container"]}>
          {contractId !== "null" && (
            <>
              {contract?.initiator_rai_details?.text && (
                <span className={styles["title"]}>
                  {"You are invited to a contract with"}
                </span>
              )}
              <div className={styles["profile__container"]}>
                <img
                  className={styles["profile__image"]}
                  src={contract?.initiator_rai_details?.profile_pic}
                />
                <span className={styles["profile__name"]}>
                  {contract?.initiator_rai_details?.text}
                </span>
                {contract?.contract_details?.expiry && (
                  <span className={styles["expiry__text"]}>
                    with tentative expiry: {contract?.contract_details?.expiry}
                  </span>
                )}
              </div>
            </>
          )}

          <div className={styles["input__container"]}>
            <span
              className={styles["input__text"]}
            >{`Please enter the OTP sent to ${getUserMobileNumber()}`}</span>
            <div
              className={styles["input__wrapper"]}
              onFocus={(e) => handleFocus(true)}
              onBlur={(e) => handleFocus(false)}
            >
              <OtpInput
                value={otp?.toString()}
                onChange={handleOtpChange}
                shouldAutoFocus
                isInputNum
                numInputs={6}
                containerStyle={otpStyles.containerStyle}
                inputStyle={otpStyles.inputStyle}
                errorStyle={otpStyles.errorStyle}
              />
            </div>

            <span className={styles["resend__otp__wrapper"]}>
              <span
                className={classNames({
                  [styles["resend__text"]]: true,
                  [styles["resend__enabled"]]: timer === 0,
                })}
                onClick={handleResendOtp}
              >
                Resend OTP
              </span>
              {timer > 0 && (
                <>
                  in
                  <span className={styles["resend__otp__time"]}>
                    {timer} sec
                  </span>
                </>
              )}
            </span>
            {isFocused && (
              <RaiButton
                variant="contained"
                onClick={handleOtpSubmit}
                disabled={otp?.toString().length !== 6}
              >
                Continue
              </RaiButton>
            )}
          </div>
        </div>
      </div>

      {!isFocused && (
        <RaiButton
          variant="contained"
          onClick={handleOtpSubmit}
          disabled={otp?.toString().length !== 6}
        >
          Continue
        </RaiButton>
      )}
    </Container>
  );
};

export default Otp;
