// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_btn__2ulx3 {\n  text-transform: none !important;\n}\n\n.style_disabled__2nKW9 {\n  background-color: gray !important;\n  pointer-events: none !important;\n}", "",{"version":3,"sources":["webpack://src/components/Rai/Button/style.module.scss"],"names":[],"mappings":"AAAA;EACE,+BAAA;AACF;;AAEA;EACE,iCAAA;EACA,+BAAA;AACF","sourcesContent":[".btn {\n  text-transform: none !important;\n}\n\n.disabled {\n  background-color: gray !important;\n  pointer-events: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "style_btn__2ulx3",
	"disabled": "style_disabled__2nKW9"
};
export default ___CSS_LOADER_EXPORT___;
