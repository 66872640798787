import React, { useEffect, useState, createContext } from "react";
import classNames from "classnames";

import { getPlatformType, getUserOs } from "config/helper";
import { logFirebaseEvent } from "config/firebase";
import {
  getWaitlistStatus,
  prompt,
  getSuggestionList,
  getFilteredSuggestionList,
} from "services/rai";
import { RaiContext } from "config/RaiContext";

import Navbar from "components/Rai/Navbar";
import PaginatedList from "components/PaginatedList";

import Container from "components/Rai/Container";
import { useHistory } from "react-router-dom";
import Loading from "components/Rai/Loading";
import { QuestionProps } from "views/Rai/Group/Question";

import styles from "./style.module.scss";

interface QuestionListProps {
  value?: string;
}

const QuestionItem = (props: QuestionProps) => {
  const history = useHistory();
  return (
    <>
      <div
        className={styles["question"]}
        onClick={() => {
          logFirebaseEvent({
            event_name: "rai_question_clicked",
            event_value_key1: "format_name",
            event_value_value1: "RAI",
            event_value_key2: "screen",
            event_value_value2: "question_list",
            event_value_key3: "rai_format",
            event_value_value3: "group",
            event_value_key4: "suggestion_id",
            event_value_value4: props?.id,
          });
          history?.push(`/group/question`, { event: props });
        }}
      >
        <span className={styles["question__name"]}>{props?.name}</span>
        <img className={styles["question__image"]} src={props?.image_url} />
      </div>
    </>
  );
};

const QuestionList = ({ value }: QuestionListProps) => {
  const history = useHistory();

  const [event, setEvent] = useState<any>();
  const [suggestions, setSuggestions] = useState<any>([]);
  const [filters, setFilters] = useState<any>();
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [selected, setSelected] = useState<any>(0);
  const [selectedId, setSelectedId] = useState<any>(0);
  const [header, setHeader] = useState("What's your question?");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<"success" | "loading" | "input">(
    "input"
  );
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loadingMessage, setLoadingMessage] = useState(
    "₹.Ai is evaluating your input..."
  );
  const [isFocused, setIsFocused] = useState(false);

  const getWaitlist = async () => {
    const response: any = await getWaitlistStatus();
    if (response?.data) {
      response?.data?.isUserWaitListed && history?.push("/");
    }
  };

  const getSuggestions = async (refresh?: boolean) => {
    let queryPage = page;

    if (!refresh) {
      queryPage += 1;
    }
    const response: any =
      selected === 0
        ? await getSuggestionList(queryPage)
        : await getFilteredSuggestionList(queryPage, selectedId);

    if (response?.data) {
      console.log(response);
      setEvent(response?.data);
      let newData = [];
      if (refresh) {
        newData = response?.data?.records?.suggestions;
      } else {
        newData = suggestions?.concat(response?.data?.records?.suggestions);
      }
      setSuggestions(newData);
      const filterArray = [
        {
          id: "",
          type: null,
          display_name: "All",
          display_tag: "All",
          description: "All",
        },
      ];

      filterArray?.push(...response?.data?.records?.filters);

      setFilters(filterArray);

      setHasMore(response?.data?.is_remaining);
      if (response?.data?.page_no) {
        setPage(response?.data?.page_no);
      }
    }
  };

  useEffect(() => {
    getSuggestions(true);
  }, [selectedId]);

  useEffect(() => {
    // getWaitlist();
    getSuggestions();
    logFirebaseEvent({
      event_name: "rai_question_list_screen_loaded",
      event_value_key1: "format_name",
      event_value_value1: "RAI",
      event_value_key2: "screen",
      event_value_value2: "question_list",
      event_value_key3: "rai_format",
      event_value_value3: "group",
    });
  }, []);

  return (
    <Container>
      {loading && (
        <>
          <Loading message={loadingMessage} status={status} />
        </>
      )}
      <div className={styles["question__list__container"]}>
        <Navbar title="₹.AI" subtitle={"by Probo"} customBackLink={"/"} />
        <div className={styles["question__list__title"]}>
          Select your question
        </div>
        <div className={styles["question__list__filters"]}>
          {filters?.map((ele: any, index: number) => {
            return (
              <div
                className={classNames({
                  [styles["filter__item"]]: true,
                  [styles["filter__item__selected"]]: selected === index,
                })}
                onClick={() => {
                  setSelected(index);
                  setSelectedId(ele?.id);
                }}
              >
                {ele?.display_name}
              </div>
            );
          })}
        </div>
        {/* <div className={styles["question__list"]}> */}
        {!loading && suggestions && (
          <PaginatedList
            height={["Android", "iOS"].includes(getUserOs()!) ? undefined : 500}
            loading={false}
            next={getSuggestions}
            component={QuestionItem}
            hasMore={hasMore}
            data={suggestions}
            props={event}
            refreshFunction={getSuggestions}
          />
        )}
        {/* </div> */}
      </div>
      <div></div>
    </Container>
  );
};

export default QuestionList;
