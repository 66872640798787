import {
  RAI_CONTRACT,
  RAI_PROMPT,
  httpClient,
  RAI_GET_WALLET_DETAILS,
  RAI_GET_PORTFOLIO,
  RAI_GET_CONTRACT_BY_ID,
  RAI_GET_TRADE_SUMMARY,
  RAI_GET_PUBLIC_CONTRACT_DETAILS,
  RAI_GET_WAITLIST_STATUS,
  RAI_GET_SUGGESTION_LIST,
  RAI_CREATE_GROUP_CONTRACT,
  RAI_GET_GROUP_CONTRACT,
  RAI_CANCEL_CONTRACT,
  RAI_GET_PORTFOLIO_CARD,
  RAI_VALIDATE_CONTRACT,
  RAI_EXPIRE_CONTRACT,
  RAI_CLOSE_CONTRACT,
} from "config/api";

export const getWaitlistStatus = async () => {
  return httpClient.get({
    url: `${RAI_GET_WAITLIST_STATUS}`,
    token: true,
  });
};

export const prompt = async (data = {}) => {
  return httpClient.post({
    url: `${RAI_PROMPT}`,
    data,
    token: true,
  });
};

export const postContract = async (data = {}) => {
  return httpClient.post({
    url: `${RAI_CONTRACT}`,
    data,
    token: true,
  });
};

export const getWalletDetails = async () => {
  return httpClient.get({
    url: `${RAI_GET_WALLET_DETAILS}`,
    token: true,
  });
};

export const getContractById = async (id: string | number) => {
  return httpClient.get({
    url: `${RAI_GET_CONTRACT_BY_ID}/${id}`,
    token: true,
  });
};

export const getActivePortfolio = async (data: any) => {
  return httpClient.post({
    url: `${RAI_GET_PORTFOLIO}/open`,
    data,
    token: true,
  });
};

export const getSettledPortfolio = async (data: any) => {
  return httpClient.post({
    url: `${RAI_GET_PORTFOLIO}/closed`,
    data,
    token: true,
  });
};

export const getActivePortfolioCard = async () => {
  return httpClient.post({
    url: `${RAI_GET_PORTFOLIO_CARD}/open`,
    token: true,
  });
};

export const getSettledPortfolioCard = async () => {
  return httpClient.post({
    url: `${RAI_GET_PORTFOLIO_CARD}/closed`,
    token: true,
  });
};

export const getTradeSummary = async (id: string | number) => {
  return httpClient.get({
    url: `${RAI_GET_TRADE_SUMMARY}?eventId=${id}`,

    token: true,
  });
};

export const getPublicContractDetails = async (id: string | number) => {
  return httpClient.get({
    url: `${RAI_GET_PUBLIC_CONTRACT_DETAILS}/${id}`,
    token: true,
  });
};

export const getSuggestionList = async (page: number) => {
  return httpClient.get({
    url: `${RAI_GET_SUGGESTION_LIST}/?page=${page}`,
    token: true,
  });
};

export const getFilteredSuggestionList = async (
  page: number,
  filterId: number
) => {
  return httpClient.get({
    url: `${RAI_GET_SUGGESTION_LIST}/?page=${page}&filter_id=${filterId}`,
    token: true,
  });
};

export const getGroupContractDetails = async (id: number | string) => {
  return httpClient.get({
    url: `${RAI_GET_GROUP_CONTRACT}/${id}`,
    token: true,
  });
};

export const createGroupContract = async (data: any) => {
  return httpClient.post({
    url: `${RAI_CREATE_GROUP_CONTRACT}`,
    data,
    token: true,
  });
};

export const cancelContract = async (order_id: string | number) => {
  return httpClient.put({
    url: `${RAI_CANCEL_CONTRACT}/${order_id}`,
    token: true,
  });
};

export const validateContract = async (data: any) => {
  return httpClient.post({
    url: `${RAI_VALIDATE_CONTRACT}`,
    data,
    token: true,
  });
};

export const expireContract = async (data: any) => {
  return httpClient.patch({
    url: `${RAI_EXPIRE_CONTRACT}`,
    data,
    token: true,
  });
};

export const closeContract = async (data: any) => {
  return httpClient.patch({
    url: `${RAI_CLOSE_CONTRACT}`,
    data,
    token: true,
  });
};
