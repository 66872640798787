import React from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

import styles from "./style.module.scss";
// import Button from "components/Button";
import { CardActionArea } from "@mui/material";

import WalletBig from "assets/icons/rai/wallet_big.svg";
import PortfolioIcon from "assets/icons/rai/portfolio.svg";
import LogoutIcon from "assets/icons/rai/logout.svg";
import CloseIcon from "@mui/icons-material/Close";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { getUserOs } from "config/helper";
import { Constants } from "config/constants";

const drawerItems = [
  // {
  //   title: "Control Centre",
  //   icon: GaugeIcon,
  //   link: "",
  //   type: "ControlCentre",
  // },

  {
    title: "Wallet Balance",
    icon: WalletBig,
    link: `${Constants?.TRADING_WEB_URL}/payments`,
    type: "wallet",
    link_type: "external",
  },
  {
    title: "My Portfolio",
    icon: PortfolioIcon,
    link: "/portfolio",
    type: "portfolio",
    link_type: "internal",
  },
  {
    title: "Logout",
    icon: LogoutIcon,
    link: "/logout",
    type: "logout",
    link_type: "internal",
  },
];

type DrawerItemsProps = {
  logEvent?: Function;
  toggleDrawer?: any;
  walletBalance?: number;
};

const DrawerItems = ({ toggleDrawer, walletBalance }: DrawerItemsProps) => {
  console.log("DrawerItems Render");
  const history = useHistory();

  return (
    <React.Fragment>
      <div className={styles["drawer"]}>
        <span className={styles["close__icon__container"]}>
          <CloseIcon className={styles["close__icon"]} onClick={toggleDrawer} />
        </span>

        <div className={styles["top__section"]}>
          {localStorage?.getItem("name") && (
            <span className={styles["top__section__title"]}>
              {localStorage?.getItem("name")}
            </span>
          )}
          <span className={styles["top__section__subtitle"]}>
            +91 - {localStorage?.getItem("number")}
          </span>
        </div>

        {drawerItems?.map((item, index) => {
          return (
            <CardActionArea
              key={index}
              className={styles["drawer__item__cta"]}
              onClick={() => {
                if (item.link_type === "internal") {
                  history.push(item?.link, {
                    from: history?.location?.pathname,
                  });
                } else {
                  window.open(item?.link, "_blank");
                }
              }}
            >
              <div className={styles["drawer__item"]}>
                <div className={styles["drawer__item__left"]}>
                  <img
                    src={item.icon}
                    alt=""
                    className={styles["drawer__item__icon"]}
                  />
                  <span
                    className={classNames({
                      [styles["drawer__item__title"]]: true,
                      [styles["drawer__logout__title"]]:
                        item?.type === "logout",
                    })}
                  >
                    {item.title}
                  </span>
                </div>
                <span
                  className={classNames({
                    [styles["drawer__item__right"]]: true,
                    [styles["drawer__item__wallet"]]: item?.type === "wallet",
                  })}
                >
                  {item?.type === "wallet" ? (
                    `₹ ${walletBalance?.toFixed(0)}`
                  ) : (
                    <ChevronRightIcon />
                  )}
                </span>
              </div>
            </CardActionArea>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default DrawerItems;
