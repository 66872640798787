import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";

import CoinsIcon from "assets/icons/rai/coins.svg";
import TimerIcon from "assets/icons/rai/timer.svg";
import FriendsIcon from "assets/icons/rai/friends.svg";
import CheckCircleIcon from "assets/images/rai/check_circle.svg";

import styles from "./style.module.scss";
import { Constants } from "config/constants";

interface PollCardProps {
  contract: any;
  selectedIndex?: number;
  setSelectedIndex?: any;
  isCreator?: boolean;
}

const PollCard = ({
  contract,
  selectedIndex,
  setSelectedIndex,
  isCreator,
}: PollCardProps) => {
  const [timer, setTimer] = useState("--:--:--");
  const [minutesRemaining, setMinutesRemaining] = useState(0);

  const ref = useRef<any>(null);

  const getTimeRemaining = (e: any) => {
    const total = Date.parse(e) - Date.parse(new Date().toString());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor(total / 1000 / 60 / 60);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e: any) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    setMinutesRemaining(total / 60000);
    if (total >= 0) {
      setTimer(
        (hours > 9 ? hours : "0" + hours) +
          ":" +
          (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    } else {
      setTimer("Expired");
    }
  };

  const clearTimer = (e: any) => {
    if (ref?.current) clearInterval(ref?.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();

    deadline.setSeconds(
      deadline.getSeconds() + contract?.event_expiry_time_in_sec
    );
    return deadline;
  };

  useEffect(() => {
    clearTimer(getDeadTime());
    if (contract?.event_expiry_time_in_sec === 0) setTimer("Expired");
  }, [contract]);

  const findCorrectIndex = () => {
    return contract?.options?.findIndex(
      (ele: any) => ele.is_settled_at === true
    );
  };

  return (
    <div className={styles["contract__details__container"]}>
      {contract?.outcome_type && contract?.result && (
        <div
          className={classNames({
            [styles["result__section"]]: true,
            [styles["result__section__won"]]:
              contract?.outcome_type === Constants?.WON,
            [styles["result__section__lost"]]:
              contract?.outcome_type === Constants?.LOST,
          })}
        >
          {contract?.selected_option === findCorrectIndex() &&
          contract?.outcome_type === Constants?.LOST
            ? "Contract settled"
            : contract?.result}
        </div>
      )}
      <span className={styles["contract__name__wrapper"]}>
        <img src={contract?.image_url} className={styles["contract__icon"]} />
        <span className={styles["contract__name"]}>{contract?.name}</span>
      </span>
      <div className={styles["contract__poll__container"]}>
        {contract?.options?.map((ele: any, index: number) => {
          const progress = ele?.percentage;
          const selectedColor = "#45DE922E";
          const unselectedColor = "#353338";

          return (
            <span
              key={index}
              className={classNames({
                [styles["contract__poll__option"]]: true,
                [styles["contract__poll__option__clickable"]]: !isCreator,
                [styles["contract__poll__option__selected"]]:
                  selectedIndex === index,
              })}
              style={{
                backgroundImage:
                  selectedIndex === index
                    ? `linear-gradient(to right,${selectedColor} ${progress}%,transparent ${progress}%)`
                    : `linear-gradient(to right,${unselectedColor} ${progress}%,transparent ${progress}%)`,
              }}
              onClick={() => {
                !isCreator && setSelectedIndex && setSelectedIndex(index);
              }}
            >
              <span className={styles["contract__poll__left"]}>
                {ele?.is_settled_at ? (
                  <>
                    {ele?.name}
                    <img
                      src={CheckCircleIcon}
                      className={styles["contract__poll__check"]}
                    />
                  </>
                ) : (
                  ele?.name
                )}
              </span>
              <span> {progress}%</span>
            </span>
          );
        })}
      </div>

      {contract?.is_settled && contract?.outcome_type === Constants.WON && (
        <div className={styles["investment__return__wrapper"]}>
          <div className={styles["investment__return__container"]}>
            <div className={styles["investment__container"]}>
              <span className={styles["investment__amount"]}>
                ₹{contract?.investment}
              </span>
              <span className={styles["investment__text"]}>Invested</span>
            </div>
            <div className={styles["return__container"]}>
              <span className={styles["return__amount"]}>
                ₹{contract?.actual_returns?.toFixed(2)}
              </span>
              <span className={styles["return__text"]}>Returns</span>
            </div>
          </div>
        </div>
      )}

      <div className={styles["contract__info__container"]}>
        <span className={styles["contract__info__wrapper"]}>
          <img src={CoinsIcon} className={styles["contract__info__icon"]} />
          <span className={styles["contract__info__text"]}>
            ₹{contract?.investment}
          </span>
        </span>
        <span className={styles["contract__info__wrapper"]}>
          <img src={FriendsIcon} className={styles["contract__info__icon"]} />
          <span className={styles["contract__info__text"]}>
            {`${contract?.number_of_members}/${contract?.max_user_limit} spots filled`}
          </span>
        </span>
        <span className={styles["contract__info__wrapper"]}>
          <img src={TimerIcon} className={styles["contract__info__icon"]} />
          <span
            className={classNames({
              [styles["contract__info__text"]]: true,
              [styles["contract__info__text__error"]]:
                // contract?.is_low_remaining_time || contract?.is_deadline_passed,
                minutesRemaining < 5,
            })}
          >
            {/* {contract?.remaining_time} */}
            {timer}
          </span>
        </span>
      </div>
    </div>
  );
};

export default PollCard;
