import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import classNames from "classnames";

import { RaiContext } from "config/RaiContext";
import { getWalletDetails } from "services/rai";
import { AppContext } from "config/context";

import Container from "components/Rai/Container";
import Navbar from "components/Rai/Navbar";
import EventCard, {
  EventCardProps,
  EventProps,
} from "components/Rai/EventCard";
import RaiButton from "components/Rai/Button";

import RechargeIcon from "assets/icons/rai/recharge.png";

import styles from "./style.module.scss";
import { logFirebaseEvent } from "config/firebase";
import { Toast } from "components/Toast";

const Monetary = ({ event, opinion, referee_flow }: EventCardProps) => {
  const history = useHistory();
  const ref = useRef<any>(null);

  const context = useContext(AppContext);
  const windowWidth = context.mobileLayoutWidth;

  const [errorMessage, setErrorMesssage] = useState<string>();
  const [stake, setStake] = useState<number>();
  const [walletBalance, setWalletBalance] = useState<any>();
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [dailyLimit, setDailyLimit] = useState(0);

  const [lowBalance, setLowBalance] = useState(false);

  const maxStake = 10000;
  const handleChange = (e: any) => {
    if (e?.target?.value != "0") setStake(parseInt(e?.target?.value));
  };

  const handleKeyDown = (e: any) => {
    if (
      e.key === "Enter" &&
      stake &&
      stake <= remainingAmount &&
      stake > 0 &&
      !lowBalance
    ) {
      logFirebaseEvent({
        event_name: "rai_monetary_entered",
        event_value_key1: "format_name",
        event_value_value1: "RAI",
        event_value_key2: "screen",
        event_value_value2: "monetary",
        event_value_key3: "stakes",
        event_value_value3: stake,
        event_value_key4: "request_id",
        event_value_value4: event?.request_id,
      });
      history.push("/create/number-of-contracts", {
        event: event,
        opinion: opinion,
        contract_price: stake,
        referee_flow: referee_flow,
        is_money_flow: true,
      });
    }
  };

  const getWallet = async () => {
    const response: any = await getWalletDetails();
    if (response?.statusCode === 200) {
      setWalletBalance(response?.data?.total_amount);
      setDailyLimit(response?.data?.daily_limit);
      setRemainingAmount(
        response?.data?.daily_limit - response?.data?.total_investment
      );
    } else {
      Toast({
        message: "Couldn't fetch wallet details",
        type: "error",
      });
    }
  };

  useEffect(() => {
    setLowBalance(walletBalance < stake!);
    if (stake && stake > remainingAmount) {
      setErrorMesssage(
        `You've exceeded daily investment limit of ₹${dailyLimit}`
      );
      ref.current.select();
    } else {
      setErrorMesssage("");
    }
  }, [stake]);

  useState(() => {
    getWallet();
  });

  useEffect(() => {
    ref?.current?.select();
    logFirebaseEvent({
      event_name: "rai_stakes_screen_loaded",
      event_value_key1: "format_name",
      event_value_value1: "RAI",
      event_value_key2: "screen",
      event_value_value2: "stakes",
      event_value_key3: "request_id",
      event_value_value3: event?.request_id,
    });
  }, []);

  return (
    <RaiContext.Consumer>
      {(config) => (
        <Container>
          <div className={styles["top__container"]}>
            <Navbar title={"₹.AI"} subtitle={"by Probo"} />
            <div className={styles["create__container"]}>
              <span className={styles["contract__title__wrapper"]}>
                <span className={styles["contract__title"]}>Contract</span>
              </span>
              <EventCard
                event={event}
                opinion={opinion}
                referee_flow={referee_flow}
              />
              <div className={styles["contract__stakes__container"]}>
                <span className={styles["contract__stakes__title"]}>
                  {config?.stakes?.title}
                </span>
                <div className={styles["contract__stakes__input__wrapper"]}>
                  <span className={styles["contract__stakes__input__currency"]}>
                    ₹
                  </span>
                  <input
                    className={styles["contract__stakes__input"]}
                    autoFocus
                    type="number"
                    value={stake}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    ref={ref}
                    onFocus={(e: any) => {
                      ref?.current?.select();
                    }}
                    style={{
                      width: stake ? stake?.toString().length * 25 : 10,
                    }}
                  />
                </div>
                {walletBalance && (
                  <span
                    className={styles["wallet__message"]}
                  >{`Wallet balance: ₹${walletBalance.toFixed(2)}`}</span>
                )}
                <span className={styles["error__message"]}>{errorMessage}</span>
              </div>
            </div>
          </div>
          <div className={styles["footer__section"]}>
            <RaiButton
              className={classNames({
                [styles["disabled__button"]]:
                  (stake && stake > remainingAmount) || !stake || lowBalance,
              })}
              variant="contained"
              onClick={() => {
                history.push("/create/number-of-contracts", {
                  event: event,
                  opinion: opinion,
                  contract_price: stake,
                  referee_flow: referee_flow,
                  is_money_flow: true,
                });
              }}
            >
              Confirm
            </RaiButton>
            {lowBalance && (
              <>
                <div
                  className={styles["recharge__section"]}
                  style={{ width: windowWidth }}
                >
                  <div className={styles["recharge__container"]}>
                    <div className={styles["recharge__left__container"]}>
                      <img
                        src={RechargeIcon}
                        className={styles["recharge__icon"]}
                      />
                      <div className={styles["recharge__info__container"]}>
                        <span className={styles["recharge__info"]}>
                          ₹{(stake! - walletBalance).toFixed(1)} low on money!
                        </span>
                        <span className={styles["recharge__now"]}>
                          Recharge now
                        </span>
                      </div>
                    </div>
                    <button
                      className={styles["recharge__button"]}
                      onClick={() => {
                        window.open(
                          "https://trading.probo.in/recharge",
                          "_blank"
                        );
                      }}
                    >
                      Add now
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </Container>
      )}
    </RaiContext.Consumer>
  );
};

export default Monetary;
