import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { RaiContext } from "config/RaiContext";
import { Player } from "@lottiefiles/react-lottie-player";

import Navbar from "components/Rai/Navbar";
import Container from "components/Rai/Container";
import RaiButton from "components/Rai/Button";

import Header from "assets/gifs/header.json";
import AIShadow from "assets/icons/rai/ai_shadow.png";
import OnevOneIcon from "assets/icons/rai/onevone.svg";
import GroupPlayIcon from "assets/icons/rai/groupplay.png";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Logo from "assets/icons/rai/rai.svg";
import Waitlist from "assets/images/rai/waitlist.svg";

import styles from "./style.module.scss";
import { logFirebaseEvent } from "config/firebase";
import { getWaitlistStatus } from "services/rai";
import { Toast } from "components/Toast";
import { AppContext } from "config/context";
import classNames from "classnames";

const config = {
  landing: {
    introduction: "Introducing",
    title: "Invite your friends and settle your debates",
    subtitle: "Create contract. Share with friends. AI will settle debate",
    info: {
      image:
        "https://gumlet-images-bucket.s3.ap-south-1.amazonaws.com/probo_product_images/IMAGE_a0296433-ff52-4946-a126-6e67dfb72ee9.png",
      text: "10K+ users have settled debates with ₹.Ai",
    },
    formats: [
      {
        icon: OnevOneIcon,
        text: "Play 1v1",
        subtext: "Make your own events. Match with your friends",
        link: "/question",
      },
      {
        icon: GroupPlayIcon,
        text: "Play with group",
        subtext: "Create event for everyone. Earn on every trade on your event",
        link: "/group/question/list",
      },
    ],
  },
};

const RaiHome = () => {
  const context = useContext(AppContext);

  const history = useHistory();

  const [waitlist, setWaitlist] = useState(false);
  const [waitlistJoined, setWaitlistJoined] = useState(false);

  const joinWaitlist = () => {
    setWaitlistJoined(true);
  };

  const getWaitlist = async () => {
    const response: any = await getWaitlistStatus();
    if (response?.data) {
      setWaitlist(response?.data?.isUserWaitListed);
    }
  };

  const handleClick = (link: string) => {
    if (waitlist && link === "/question") {
      joinWaitlist();
      logFirebaseEvent({
        event_name: "rai_waitlist_clicked",
        event_value_key1: "format_name",
        event_value_value1: "RAI",
        event_value_key2: "screen",
        event_value_value2: "home",
      });
    } else if (link === "/question") {
      logFirebaseEvent({
        event_name: "rai_get_started_clicked",
        event_value_key1: "format_name",
        event_value_value1: "RAI",
        event_value_key2: "screen",
        event_value_value2: "home",
        event_value_key3: "rai_format",
        event_value_value3: "1v1",
      });

      history.push(link);
    } else {
      logFirebaseEvent({
        event_name: "rai_get_started_clicked",
        event_value_key1: "format_name",
        event_value_value1: "RAI",
        event_value_key2: "screen",
        event_value_value2: "home",
        event_value_key3: "rai_format",
        event_value_value3: "group",
      });
      history.push(link);
    }
  };

  useEffect(() => {
    logFirebaseEvent({
      event_name: "rai_home_screen_loaded",
      event_value_key1: "format_name",
      event_value_value1: "RAI",
      event_value_key2: "screen",
      event_value_value2: "home",
    });
    getWaitlist();
  }, []);

  return (
    <RaiContext.Provider value={config}>
      <Container className={styles["rai__container"]}>
        <Navbar
          className={styles["home__navbar"]}
          title="₹.AI"
          subtitle={"India’s 1st smart contract platform"}
          portfolioDisabled
          customBackLink={"/home"}
          drawerEnabled
        />

        {!waitlistJoined && (
          <div className={styles["landing__container"]}>
            <div
              className={styles["contract__gif__wrapper"]}
              style={{ minHeight: context.windowHeight - 280 }}
            >
              <Player autoplay loop src={Header} />
            </div>
            <div className={styles["contract__cta__wrappper"]}>
              <RaiButton
                className={classNames({
                  [styles["contract__cta"]]: true,
                })}
                variant="contained"
                onClick={() => {
                  handleClick("/question");
                }}
              >
                {"Contract against 1"}
              </RaiButton>
              <RaiButton
                className={classNames({
                  [styles["contract__cta"]]: true,
                  [styles["contract__cta__secondary"]]: true,
                })}
                variant="outlined"
                onClick={() => {
                  handleClick("/group/question/list");
                }}
              >
                {"Contract vs a group"}
              </RaiButton>
            </div>
            <div className={styles["info__section"]}>
              <span className={styles["info__title"]}>
                What is{" "}
                <img className={styles["info__title__rai"]} src={Logo} />?
              </span>
              <div className={styles["info__description"]}>
                ₹.Ai is an{" "}
                <span className={styles["bold"]}>initiative by Probo</span>, to
                empower the true opinion master inside you, and let you take a
                strong position, when in debate or doubt.
              </div>
            </div>
            <div className={styles["info__section"]}>
              <span className={styles["info__title"]}>
                What does{" "}
                <img className={styles["info__title__rai"]} src={Logo} /> do?
              </span>
              <div
                className={classNames({
                  [styles["info__description"]]: true,
                  [styles["info__description__timeline"]]: true,
                })}
              >
                <ul className={styles["timeline"]}>
                  <li className={styles["timeline__item"]}>
                    We will take your opinion
                  </li>
                  <li className={styles["timeline__item"]}>
                    <span className={styles["bold"]}>
                      Our AI will interpret
                    </span>{" "}
                    and process it
                  </li>
                  <li className={styles["timeline__item"]}>
                    We shall make a contract <i>(with your help)</i>
                  </li>
                  <li className={styles["timeline__item"]}>
                    We shall, then, share it with your pal!
                  </li>
                </ul>
              </div>
              {/* <a className={styles["read__more"]} href={"/#"}>
                Read our research on smart contracts <ChevronRightIcon />
              </a> */}
            </div>
            <div className={styles["info__section"]}>
              <span className={styles["info__title"]}>Want to know more?</span>
              <div className={styles["info__description__video__wrapper"]}>
                <iframe
                  className={styles["info__description__video"]}
                  height={210}
                  src="https://www.youtube.com/embed/tVYQEXt4ql0?si=P8m28niohKLfQlQq&amp;controls=0"
                  title="RAI"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div className={styles["footer__section"]}>
              <span className={styles["footer__text"]}>
                © copyright 2023 by Probo Media
              </span>
            </div>
          </div>
        )}
        {waitlistJoined && (
          <div className={styles["landing__container"]}>
            <img className={styles["joined__image"]} src={Waitlist} />
            <span className={styles["joined__text"]}>
              {"You are in the waitlist"}
            </span>
            <span className={styles["joined__subtext"]}>
              {"We will notify you once your waitlist is cleared."}
            </span>
            <div className={styles["footer__section"]}>
              {waitlistJoined && (
                <>
                  <RaiButton
                    className={styles["cta"]}
                    variant="contained"
                    onClick={() => {
                      logFirebaseEvent({
                        event_name: "rai_back_to_probo__clicked",
                        event_value_key1: "format_name",
                        event_value_value1: "RAI",
                        event_value_key2: "screen",
                        event_value_value2: "home",
                      });
                      history.push("/home");
                    }}
                  >
                    Go back
                  </RaiButton>
                </>
              )}
            </div>
          </div>
        )}
      </Container>
    </RaiContext.Provider>
  );
};
export default RaiHome;
