import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import App from "views";
import "scss/app.scss";
import { allowSentry, resolveHostname } from "config/helper";

if (process.env.REACT_APP_ENV === "production") {
  console.log = () => {};
  console.warn = () => {};
}
if (process.env.REACT_APP_ENV === "staging") {
  console.warn = () => {};
}

if (allowSentry()) {
  Sentry.init({
    dsn: resolveHostname(),
    integrations: [new BrowserTracing()],
    debug: process.env.REACT_APP_ENV === "staging",
    environment: process.env.REACT_APP_ENV,
    release: process.env.REACT_APP_VERSION,
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
