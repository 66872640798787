import classNames from "classnames";
import { Button } from "@mui/material";

import styles from "./style.module.scss";

const containedStyles = {
  background: "#45DE92",
  borderRadius: 100,
  width: "100%",
  color: "#000000",
  height: 48,
  fontFamily: "Work Sans",
  fontSize: "14px",
  fontWeight: "600",
  lineHeight: "20px",
  letteSpacing: "0em",
  "&:hover": {
    background: "#45DE92",
    border: "#45DE92",
  },
};
const outlinedStyles = {
  background: "transparent",
  borderColor: "#45DE92",
  borderRadius: 100,
  width: "100%",
  color: "#ffffff",
  height: 48,
  "&:hover": {
    backgroundColor: "transparent",
    border: "1px solid #45DE92",
  },
};

export type ButtonProps = {
  key?: any;
  id?: string;
  style?: object;
  className?: string;
  name?: string;
  startIcon?: any;
  endIcon?: any;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  variant?: "text" | "outlined" | "contained";
  children?: React.ReactElement | React.ReactElement[] | React.ReactNode;
  disabled?: boolean;
};

const RaiButton = ({
  key,
  id,
  style,
  className,
  name,
  startIcon,
  endIcon,
  onClick,
  variant,
  children,
  disabled,
}: ButtonProps) => {
  console.log("Button Render", className);

  return (
    <>
      <Button
        key={key}
        id={id}
        sx={
          variant === "contained"
            ? containedStyles
            : variant === "outlined"
            ? outlinedStyles
            : {}
        }
        className={classNames(className, {
          [styles["btn"]]: true,
          [styles["disabled"]]: disabled,
        })}
        startIcon={startIcon}
        endIcon={endIcon}
        variant={variant}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </Button>
    </>
  );
};

export default RaiButton;
