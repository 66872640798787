import React, { createRef, useContext, useEffect, useState } from "react";
import { useScreenshot } from "use-react-screenshot";
import { getContractById, postContract, getWalletDetails } from "services/rai";
import { Player } from "@lottiefiles/react-lottie-player";

import Container from "components/Rai/Container";
import Navbar from "components/Rai/Navbar";
import EventCard, { EventProps } from "components/Rai/EventCard";
import RaiButton from "components/Rai/Button";
import ContractCard from "components/Rai/ContractCard";

import AI from "assets/icons/rai/ai_loader.gif";
import IosShare from "@mui/icons-material/IosShare";

import styles from "./style.module.scss";
import { CircularLoader } from "components/Loaders";
import { Toast } from "components/Toast";
import { useHistory } from "react-router-dom";
import { AppContext } from "config/context";
import { logFirebaseEvent } from "config/firebase";
import classNames from "classnames";
import { Constants } from "config/constants";

interface RefereeShareProps {
  id: string | number;
}

const RefereeShare = ({ id }: RefereeShareProps) => {
  const history = useHistory();

  const context = useContext(AppContext);
  const windowWidth = context.mobileLayoutWidth;

  const ref = createRef<any>();
  const [image, takeScreenshot] = useScreenshot();
  const [isCreator, setIsCreator] = useState(true);

  const [contract, setContract] = useState<any>();
  const [event, setEvent] = useState<EventProps>();
  const [loading, setLoading] = useState(true);

  const getContractDetails = async () => {
    const response: any = await getContractById(id);
    setLoading(false);
    if (response?.statusCode === 200) {
      const formattedEvent: EventProps = {
        contract_name: response?.data?.contract_details?.name,
        expiry_dt: response?.data?.contract_details?.expiry,
      };
      setEvent(formattedEvent);
      setContract(response?.data);
      setIsCreator(response?.data?.is_creator);
    } else {
      Toast({
        message: response?.message,
        type: "error",
      });
      setLoading(false);
    }
  };

  const onNextClicked = async () => {
    history.push(`/contract/${id}`);
  };

  const getImage = () => {
    if (ref?.current) {
      takeScreenshot(ref?.current);
    }
  };

  const getImageURI = async () => {
    let blob = await fetch(image).then((r) => r.blob());
    let dataUrl = await new Promise((resolve) => {
      let reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
    return dataUrl;
  };

  const dataURLtoFile = (dataurl: string, filename: string) => {
    const arr = dataurl.split(",");
    const mime = arr[0]?.match(/:(.*?);/)![1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const onShareClicked = async () => {
    logFirebaseEvent({
      event_name: "rai_share_clicked",
      event_value_key1: "format_name",
      event_value_value1: "RAI",
      event_value_key2: "screen",
      event_value_value2: "final_contract",
      event_value_key3: "event_id",
      event_value_value3: id,
    });
    navigator?.clipboard?.writeText(`${contract?.contract_link}/ref`);
    Toast({
      message: "Copied to clipboard",
      type: "success",
    });

    const imageURI: any = await getImageURI();
    const filesArray = [dataURLtoFile(imageURI, "contract.jpg")];

    if (navigator.share) {
      navigator
        .share({
          // title: `I have invited you on ₹.Ai to contract on "${event?.contract_name}"`,
          text: `Invite to be the referee for "${
            event?.contract_name
          }": ${`${contract?.contract_link}/ref`}`,
          // url: contract?.contract_link,
          // files: filesArray,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    }
  };

  const onWhatsappShareClicked = async () => {
    const imageURI: any = await getImageURI();
    const filesArray = [dataURLtoFile(imageURI, "contract.jpg")];

    window.open(
      `whatsapp://send?url=${contract?.contract_link}&file=${imageURI}`
    );

    // if (navigator.share) {
    //   navigator
    //     .share({
    //       title: "Contract",
    //       text: "Challenge",
    //       url: window.location.href,
    //       files: filesArray,
    //     })
    //     .then(() => console.log("Successful share"))
    //     .catch((error) => console.log("Error sharing", error));
    // }
  };

  useEffect(() => {
    if (contract) {
      getImage();
    }
  }, [contract]);

  useEffect(() => {
    getContractDetails();
    logFirebaseEvent({
      event_name: "rai_final_contract_screen_loaded",
      event_value_key1: "format_name",
      event_value_value1: "RAI",
      event_value_key2: "screen",
      event_value_value2: "final_contract",
      event_value_key3: "event_id",
      event_value_value3: id,
    });
  }, []);

  return (
    <>
      <Container>
        <>
          {loading && <CircularLoader />}
          {!loading && (
            <>
              <div className={styles["top__container"]}>
                <Navbar
                  title={"₹.AI"}
                  subtitle={isCreator ? "by Probo" : "by Probo"}
                  type={"close"}
                />
                <div
                  className={classNames({
                    [styles["contract__container"]]: true,
                  })}
                >
                  <div className={styles["contract__header"]}>
                    <span className={styles["contract__title"]}>Contract</span>
                  </div>
                  <div
                    className={classNames({
                      [styles["contract__details__container__screenshot"]]:
                        true,
                    })}
                    ref={ref}
                  >
                    <EventCard
                      event={event!}
                      opinion={contract?.initiator_rai_details?.creator_opinion}
                      referee_flow={true}
                    />
                  </div>
                  <div
                    className={classNames({
                      [styles["contract__share__container"]]: true,
                    })}
                  >
                    {/* <img src={AI} /> */}
                    <span className={styles["contract__share__title"]}>
                      Share the link below with the referee
                    </span>
                    <span
                      className={styles["contract__share__wrapper"]}
                      onClick={onShareClicked}
                    >
                      <span className={styles["contract__share__link"]}>
                        {`${contract?.contract_link}/ref`}
                      </span>
                      <IosShare className={styles["contract__share__icon"]} />
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles["footer__section"]}>
                {isCreator && (
                  <div className={styles["share__section"]}>
                    <RaiButton
                      className={styles["share__button"]}
                      variant="contained"
                      onClick={onNextClicked}
                    >
                      Next
                    </RaiButton>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      </Container>
    </>
  );
};

export default RefereeShare;
