// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_rai__container__2Fhw6 {\n  display: flex;\n  justify-content: space-between;\n  flex-direction: column;\n  background: linear-gradient(166deg, #1b3729 0% 0%, black 20% 100%);\n  color: #ffffff;\n  padding: 12px;\n  max-height: 100vh;\n  overflow: hidden;\n}", "",{"version":3,"sources":["webpack://src/components/Rai/Container/style.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,sBAAA;EACA,kEAAA;EACA,cAAA;EACA,aAAA;EACA,iBAAA;EACA,gBAAA;AACF","sourcesContent":[".rai__container {\n  display: flex;\n  justify-content: space-between;\n  flex-direction: column;\n  background: linear-gradient(166deg, #1b3729 0% 0%, black 20% 100%);\n  color: #ffffff;\n  padding: 12px;\n  max-height: 100vh;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rai__container": "style_rai__container__2Fhw6"
};
export default ___CSS_LOADER_EXPORT___;
