import { createRef, useContext, useEffect, useState } from "react";
import { useScreenshot } from "use-react-screenshot";
import {
  getContractById,
  getGroupContractDetails,
  getTradeSummary,
} from "services/rai";

import Collapsible from "components/Rai/Collapsible";
import Container from "components/Rai/Container";
import Navbar from "components/Rai/Navbar";
import { EventProps } from "components/Rai/EventCard";
import RaiButton from "components/Rai/Button";
import ContractCard from "components/Rai/ContractCard";

import Questionmark from "assets/icons/rai/question_mark_green.png";
import IosShareIcon from "@mui/icons-material/IosShare";

import Success from "assets/images/rai/checkmark.svg";
import Failed from "assets/images/rai/failed.svg";
import RefereeSettle from "assets/icons/rai/referee.svg";

import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

import { CircularLoader } from "components/Loaders";
import { cancelContract } from "services/rai";
import styles from "./style.module.scss";
import { Toast } from "components/Toast";
import { logFirebaseEvent } from "config/firebase";
import PollCard from "components/Rai/PollCard";
import { useHistory } from "react-router-dom";
import { SwipeableDrawer } from "@mui/material";
import { AppContext } from "config/context";
import IosShare from "@mui/icons-material/IosShare";

interface ContractSummaryProps {
  id: string | number;
  type?: string;
}

const ContractSummary = ({ id, type }: ContractSummaryProps) => {
  const history = useHistory();

  const context = useContext(AppContext);

  const windowWidth = (context?.windowWidth - context?.mobileLayoutWidth) / 2;

  const ref = createRef<any>();
  const [image, takeScreenshot] = useScreenshot();
  const [isCreator, setIsCreator] = useState(true);
  const [isMatched, setIsMatched] = useState(false);
  const [contract, setContract] = useState<any>();
  const [pollContract, setPollContract] = useState<any>();
  const [summary, setSummary] = useState<any>();
  const [event, setEvent] = useState<EventProps>();
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [bottomSheetOpen, setBottomSheetOpen] = useState(false);
  const [cancelStatus, setCancelStatus] = useState<
    "open" | "loading" | "cancelled" | "failed"
  >("open");
  const [cancelSubtitle, setcancelSubtitle] = useState("");

  const handleCancel = async () => {
    setCancelStatus("loading");
    const response: any = await cancelContract(contract?.order_id!);
    if (response?.statusCode === 200) {
      console.log(response);
      setCancelStatus("cancelled");
      setcancelSubtitle("Amount credited to your wallet balance");
    } else {
      setCancelStatus("failed");
      setcancelSubtitle(response?.message || "Something went wrong!");
    }
  };
  const getContractDetails = async () => {
    const response: any = await getContractById(id);
    if (response?.statusCode === 200) {
      const formattedEvent: EventProps = {
        contract_name: response?.data?.contract_details?.name,
        expiry_dt: response?.data?.contract_details?.expiry,
      };
      setEvent(formattedEvent);
      setContract(response?.data);
      setIsCreator(response?.data?.is_creator);
      setIsMatched(response?.data?.is_matched);
      setLoading(false);
    } else {
      Toast({
        message: response?.message,
        type: "error",
      });
      setLoading(false);
    }
  };

  const getPollContractDetails = async () => {
    const response: any = await getGroupContractDetails(id);
    if (response?.statusCode === 201) {
      setPollContract(response?.data);
      setIsCreator(response?.data?.is_creator);
      // setIsMatched(response?.data?.is_matched);
      setLoading(false);
    } else {
      Toast({
        message: response?.message,
        type: "error",
      });
      setLoading(false);
    }
  };

  const getTradeSummaryDetails = async () => {
    const response: any = await getTradeSummary(id);
    if (response?.statusCode === 200) {
      const formattedEvent: EventProps = {
        contract_name: response?.data?.contract_details?.name,
        expiry_dt: response?.data?.contract_details?.expiry,
      };
      setSummary(response?.data);
      setLoading(false);
    } else {
      Toast({
        message: response?.message,
        type: "error",
      });
      setLoading(false);
    }
  };

  const getImage = () => {
    if (ref?.current) {
      takeScreenshot(ref?.current);
    }
  };

  const getImageURI = async () => {
    let blob = await fetch(image).then((r) => r.blob());
    let dataUrl = await new Promise((resolve) => {
      let reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
    return dataUrl;
  };

  const dataURLtoFile = (dataurl: string, filename: string) => {
    const arr = dataurl.split(",");
    const mime = arr[0]?.match(/:(.*?);/)![1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const onShareClicked = async (ref?: boolean) => {
    logFirebaseEvent({
      event_name: "rai_share_clicked",
      event_value_key1: "format_name",
      event_value_value1: "RAI",
      event_value_key2: "screen",
      event_value_value2: "contract_summary",
      event_value_key3: "event_id",
      event_value_value3: id,
    });
    type !== "poll"
      ? navigator?.clipboard?.writeText(
          ref ? `${contract?.contract_link}/ref` : contract?.contract_link
        )
      : navigator?.clipboard?.writeText(pollContract?.shareable_link);
    Toast({
      message: "Copied to clipboard",
      type: "success",
    });

    const imageURI: any = await getImageURI();
    const filesArray = [dataURLtoFile(imageURI, "contract.jpg")];

    if (navigator.share) {
      navigator
        .share({
          // title: `I have invited you on ₹.Ai to contract on "${event?.contract_name}"`,
          text: `Invite to contract on "${
            type !== "poll" ? event?.contract_name : pollContract?.name
          }": ${
            type !== "poll"
              ? contract?.contract_link
              : pollContract?.shareable_link
          }`,
          // url: contract?.contract_link,
          // files: filesArray,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    }
  };

  const onWhatsappShareClicked = async () => {
    const imageURI: any = await getImageURI();
    const filesArray = [dataURLtoFile(imageURI, "contract.jpg")];

    // window.open(`whatsapp://send?url=${contract?.contract_link}&file=${imageURI}`);
    window.open(
      `https://web.whatsapp.com/send?url=${contract?.contract_link}&file=${imageURI}`
    );
  };

  useEffect(() => {
    if (contract) {
      getImage();
    }
  }, [contract]);

  useEffect(() => {
    type !== "poll" ? getContractDetails() : getPollContractDetails();
    getTradeSummaryDetails();
    logFirebaseEvent({
      event_name: "rai_contract_summary_loaded",
      event_value_key1: "format_name",
      event_value_value1: "RAI",
      event_value_key2: "screen",
      event_value_value2: "contract_summary",
      event_value_key3: "event_id",
      event_value_value3: id,
    });
  }, []);
  return (
    <>
      <Container>
        <>
          {loading && <CircularLoader />}
          {!loading && (
            <>
              <div className={styles["top__container"]}>
                <Navbar title={"₹.AI"} subtitle={"by Probo"} />
                <div className={styles["contract__details__container"]}>
                  <div className={styles["contract__container"]}>
                    <div className={styles["contract__header"]}>
                      <span className={styles["contract__title"]}>
                        Contract
                      </span>
                    </div>
                  </div>

                  <div
                    className={
                      styles["contract__details__container__screenshot"]
                    }
                    ref={ref}
                  >
                    <>
                      {type !== "poll" ? (
                        <ContractCard
                          event={event!}
                          reward_details={contract?.reward_details}
                          contract_winnings={contract?.contract_winnings}
                          initiator_rai_details={
                            contract?.initiator_rai_details
                          }
                          outcome_type={contract?.outcome_type}
                          result={contract?.result}
                          is_referee_flow={contract?.is_referee_flow}
                          referee_name={contract?.referee_name}
                        />
                      ) : (
                        <PollCard
                          contract={pollContract}
                          isCreator={isCreator}
                          selectedIndex={pollContract?.selected_option}
                        />
                      )}
                    </>
                  </div>

                  <div className={styles["summary__section"]}>
                    {contract?.is_referee_flow && contract?.is_creator && (
                      <div className={styles["summary__referee"]}>
                        <span className={styles["summary__title"]}>
                          Share link with referee
                        </span>
                        <span
                          className={styles["contract__share__wrapper"]}
                          onClick={() => onShareClicked(true)}
                        >
                          <span className={styles["contract__share__link"]}>
                            {`${contract?.contract_link}/ref`}
                          </span>
                          <IosShare
                            className={styles["contract__share__icon"]}
                          />
                        </span>
                      </div>
                    )}
                    <div className={styles["summary__participants"]}>
                      <span className={styles["summary__title"]}>
                        Participants
                      </span>
                      {contract?.max_user_limit !== undefined &&
                        contract?.members_qty !== undefined &&
                        contract?.members_qty !== contract?.max_user_limit &&
                        type !== "poll" && (
                          <div className={styles["cancel__details"]}>
                            <span className={styles["cancel__details__left"]}>
                              <ErrorOutlineOutlinedIcon
                                className={
                                  styles["cancel__details__left__icon"]
                                }
                              />
                              <span
                                className={
                                  styles["cancel__details__left__text"]
                                }
                              >
                                {`Open contracts: ${
                                  contract?.max_user_limit -
                                  contract?.members_qty
                                }/${contract?.max_user_limit}`}
                              </span>
                            </span>
                            <span className={styles["cancel__details__right"]}>
                              <div
                                className={styles["cancel__button"]}
                                onClick={() => {
                                  setBottomSheetOpen(true);
                                }}
                              >
                                Cancel
                                <CancelOutlinedIcon
                                  className={styles["cancel__button__icon"]}
                                />
                              </div>
                            </span>
                          </div>
                        )}
                      <div className={styles["summary__participants__list"]}>
                        {summary?.trade_feed_details?.trade_feed?.records
                          ?.length === 0 && (
                          <>
                            <img
                              className={styles["question__mark"]}
                              src={Questionmark}
                            />
                            <span
                              className={
                                styles["summary__participants__message"]
                              }
                            >
                              No participants... yet!
                            </span>
                            <span
                              className={
                                styles["summary__participants__submessage"]
                              }
                            >
                              Those who accept the contract will appear here
                            </span>
                          </>
                        )}
                        {summary?.trade_feed_details?.trade_feed?.records?.map(
                          (ele: any, index: number) => {
                            return (
                              <>
                                <div
                                  key={index}
                                  className={styles["participant__wrapper"]}
                                >
                                  <div className={styles["participant__left"]}>
                                    <img
                                      src={ele?.profile_image}
                                      className={styles["participant__profile"]}
                                    />
                                    <div
                                      className={
                                        styles["participant__user__details"]
                                      }
                                    >
                                      <span
                                        className={
                                          styles["participant__username"]
                                        }
                                      >
                                        {ele?.name}
                                      </span>
                                      <span
                                        className={
                                          styles["participant__user__time"]
                                        }
                                      >
                                        {ele?.trade_time}
                                      </span>
                                    </div>
                                  </div>
                                  <div className={styles["participant__right"]}>
                                    {ele?.type !== "REFEREE_APPROVED" ? (
                                      <span
                                        className={
                                          styles["participant__position"]
                                        }
                                      >
                                        {ele?.position}
                                      </span>
                                    ) : (
                                      <span
                                        className={
                                          styles["participant__position__ref"]
                                        }
                                      >
                                        <img
                                          className={
                                            styles["participant__referee__icon"]
                                          }
                                          src={RefereeSettle}
                                        />
                                        <span
                                          className={
                                            styles["participant__referee__text"]
                                          }
                                        >
                                          {" "}
                                          Referee
                                        </span>
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </>
                            );
                          }
                        )}
                      </div>
                    </div>
                    <div className={styles["summary__about"]}>
                      <span className={styles["summary__title"]}>
                        About the contract
                      </span>
                      <div className={styles["summary__timing__section"]}>
                        <div className={styles["summary__timing__wrapper"]}>
                          <div className={styles["summary__timing__title"]}>
                            {
                              summary?.event_details[0]?.data?.data_list[0]
                                ?.value
                            }
                          </div>
                          <div className={styles["summary__timing__subtitle"]}>
                            {
                              summary?.event_details[0]?.data?.data_list[0]
                                ?.label
                            }
                          </div>
                        </div>
                        <div className={styles["summary__timing__wrapper"]}>
                          <div className={styles["summary__timing__title"]}>
                            {
                              summary?.event_details[0]?.data?.data_list[1]
                                ?.value
                            }
                          </div>
                          <div className={styles["summary__timing__subtitle"]}>
                            {
                              summary?.event_details[0]?.data?.data_list[1]
                                ?.label
                            }
                          </div>
                        </div>
                      </div>
                      {summary?.event_details?.map(
                        (ele: any, index: number) => {
                          if (ele?.type === "text") {
                            return (
                              <div
                                key={index}
                                className={styles["summary__info__section"]}
                              >
                                <Collapsible
                                  title={ele?.title}
                                  subtitle={ele?.collapsed_subtitle}
                                  description={ele?.data?.text}
                                />
                              </div>
                            );
                          }
                        }
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles["footer__section"]}>
                {isCreator ? (
                  <>
                    <RaiButton
                      className={styles["share__button"]}
                      variant="contained"
                      endIcon={<IosShareIcon />}
                      onClick={() => onShareClicked(false)}
                    >
                      Share contract
                    </RaiButton>
                  </>
                ) : (
                  <>
                    <RaiButton
                      className={styles["share__button"]}
                      variant="contained"
                      onClick={() => {
                        history?.push("/");
                      }}
                    >
                      Create contract
                    </RaiButton>
                  </>
                )}
              </div>
            </>
          )}
        </>
        {contract?.max_user_limit !== undefined &&
          contract?.members_qty !== undefined && (
            <SwipeableDrawer
              container={window.document.body}
              anchor="bottom"
              open={bottomSheetOpen}
              onClose={() => {
                cancelStatus === "cancelled"
                  ? window?.location?.reload()
                  : setBottomSheetOpen(false);
                setBottomSheetOpen(false);
              }}
              onOpen={() => {}}
              PaperProps={{
                style: {
                  left: windowWidth < 0 ? 0 : windowWidth,
                  maxWidth: context.mobileLayoutWidth,
                  background: "none",
                  boxShadow: "none",
                },
              }}
              BackdropProps={{
                style: {
                  left: windowWidth < 0 ? 0 : windowWidth,
                  maxWidth: context.mobileLayoutWidth,
                },
              }}
              ModalProps={{
                keepMounted: true,
              }}
            >
              <div className={styles["close__sheet"]}></div>
              <div className={styles["cancel__bottom__sheet__container"]}>
                {cancelStatus === "loading" && <CircularLoader />}
                {cancelStatus === "cancelled" && (
                  <>
                    <img className={styles["cancel__image"]} src={Success} />
                    <span className={styles["cancel__title"]}>
                      Contract cancelled
                    </span>
                    <span className={styles["cancel__subtitle"]}>
                      {cancelSubtitle}
                    </span>
                    <RaiButton
                      className={styles["cancel__button__confirm"]}
                      variant="contained"
                      onClick={() => {
                        window?.location?.reload();
                      }}
                    >
                      Okay!
                    </RaiButton>
                  </>
                )}
                {cancelStatus === "failed" && (
                  <>
                    <img className={styles["cancel__image"]} src={Failed} />
                    <span className={styles["cancel__title"]}>
                      Unable to cancel!
                    </span>
                    <span className={styles["cancel__subtitle"]}>
                      {cancelSubtitle}
                    </span>
                    <RaiButton
                      className={styles["cancel__button__confirm"]}
                      variant="contained"
                      onClick={() => {
                        window?.location?.reload();
                      }}
                    >
                      Okay!
                    </RaiButton>
                  </>
                )}

                {cancelStatus === "open" && (
                  <>
                    <span className={styles["cancel__bottom__sheet__title"]}>
                      {contract?.contract_details?.name}
                    </span>
                    <div className={styles["cancel__info__container"]}>
                      <span className={styles["cancel__info__title"]}>
                        Cancel order
                      </span>
                      <div className={styles["cancel__info__data__container"]}>
                        <span className={styles["cancel__data__wrapper"]}>
                          <span className={styles["cancel__data__name"]}>
                            Total contracts
                          </span>
                          <span className={styles["cancel__data__value"]}>
                            {contract?.max_user_limit}
                          </span>
                        </span>
                        <span className={styles["cancel__data__wrapper"]}>
                          <span className={styles["cancel__data__name"]}>
                            Matched contracts
                          </span>
                          <span className={styles["cancel__data__value"]}>
                            {contract?.members_qty}
                          </span>
                        </span>
                        <span className={styles["cancel__data__wrapper"]}>
                          <span className={styles["cancel__data__name"]}>
                            Open contracts
                          </span>
                          <span className={styles["cancel__data__value"]}>
                            {contract?.max_user_limit - contract?.members_qty}
                          </span>
                        </span>
                        <span className={styles["cancel__data__wrapper"]}>
                          <span className={styles["cancel__data__name"]}>
                            Investment per contract
                          </span>
                          <span className={styles["cancel__data__value"]}>
                            ₹
                            {(
                              contract?.contract_winnings?.value /
                              contract?.max_user_limit
                            )?.toFixed(1)}
                          </span>
                        </span>
                      </div>
                      <div className={styles["cancel__info__total"]}>
                        <span className={styles["cancel__info__total__text"]}>
                          Total refund value
                        </span>
                        <span className={styles["cancel__info__total__value"]}>
                          ₹
                          {(
                            (contract?.contract_winnings?.value /
                              contract?.max_user_limit) *
                            (contract?.max_user_limit - contract?.members_qty)
                          )?.toFixed(1)}
                        </span>
                      </div>
                    </div>
                    <RaiButton variant="contained" onClick={handleCancel}>
                      Cancel all open contracts
                    </RaiButton>
                  </>
                )}
              </div>
            </SwipeableDrawer>
          )}
      </Container>
    </>
  );
};

export default ContractSummary;
