import React, { useEffect, useState } from "react";

import Container from "components/Rai/Container";
import EventCard, {
  EventCardProps,
  EventProps,
  OptionsProps,
} from "components/Rai/EventCard";
import Navbar from "components/Rai/Navbar";

import EditIcon from "assets/icons/rai/edit.svg";

import styles from "./style.module.scss";
import RaiButton from "components/Rai/Button";
import { useHistory } from "react-router-dom";
import { RaiContext } from "config/RaiContext";
import { logFirebaseEvent } from "config/firebase";

interface OpinionProps {
  event: EventProps;
}

const Opinion = ({ event, referee_flow }: EventCardProps) => {
  console.log("Opinion", event);
  const history = useHistory();

  const [opinion, setOpinion] = useState<"agree" | "disagree">();

  useEffect(() => {
    logFirebaseEvent({
      event_name: "rai_opinion_screen_loaded",
      event_value_key1: "format_name",
      event_value_value1: "RAI",
      event_value_key2: "screen",
      event_value_value2: "opinion",
      event_value_key3: "request_id",
      event_value_value3: event?.request_id,
    });
  }, []);

  return (
    <RaiContext.Consumer>
      {(config) => (
        <Container>
          <div className={styles["top__container"]}>
            <Navbar title={"₹.AI"} subtitle={"by Probo"} />

            <div className={styles["create__container"]}>
              <span className={styles["contract__title__wrapper"]}>
                <span className={styles["contract__title"]}>Contract</span>
              </span>
              <EventCard event={event} referee_flow={referee_flow} />
              <div className={styles["contract__opinion__container"]}>
                <span className={styles["contract__opinion__title"]}>
                  {config?.opinion?.title}
                </span>
                <div className={styles["contract__opinion__options__wrapper"]}>
                  {config?.opinion?.options?.map((ele: any, index: number) => {
                    return (
                      <RaiButton
                        variant={"outlined"}
                        key={index}
                        className={styles["contract__opinion__cta"]}
                        onClick={() => {
                          logFirebaseEvent({
                            event_name: "rai_opinion_selected",
                            event_value_key1: "format_name",
                            event_value_value1: "RAI",
                            event_value_key2: "screen",
                            event_value_value2: "opinion",
                            event_value_key3: "opinion",
                            event_value_value3:
                              index === 0 ? "agree" : "disagree",
                            event_value_key4: "request_id",
                            event_value_value4: event?.request_id,
                          });
                          setOpinion(index === 0 ? "agree" : "disagree");
                          history.push("/create/stakes", {
                            event: event,
                            opinion: index === 0 ? "agree" : "disagree",
                            referee_flow: referee_flow,
                          });
                        }}
                      >
                        {ele?.btn_text}
                      </RaiButton>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </Container>
      )}
    </RaiContext.Consumer>
  );
};

export default Opinion;
