import { initializeApp } from "firebase/app";
import { logEvent, initializeAnalytics } from "firebase/analytics";
import {
  getAppVersion,
  getLanguagePreferenceRequired,
  getPlatformType,
  getPseudoUserId,
  getUserId,
  setPseudoUserId,
} from "./helper";
import {
  ConfigParams,
  EventOptions,
  EventType,
} from "prolytics/dist/analytics_types";
import prolytics from "prolytics";
import { Constants } from "./constants";

const proboFirebaseConfig = {
  apiKey: "AIzaSyA0uuT7r4bKgTY-xkUSC6zxDit1xK1KORU",
  authDomain: "probo-b30d3.firebaseapp.com",
  projectId: "probo-b30d3",
  storageBucket: "probo-b30d3.appspot.com",
  messagingSenderId: "530071772200",
  appId: "1:530071772200:web:38ba8735b6fd3ff69a291d",
  measurementId: "G-8Q3L9X9S1C",
};

const app = initializeApp(proboFirebaseConfig);

console.warn({ hostname: window?.location?.hostname });

const analytics = initializeAnalytics(app);

/**
 * Initializing Prolytics
 */
const configParams: ConfigParams = {
  sessionTimeoutInMillis: 3000,
  isLocationAllowed: false,
  intervalBetweenEventBatchUpload: 60000,
  eventBatchSize: 50,
  staleEventTimeoutInMillis: 60000,
  enabledSdk: ["FACEBOOK", "FIREBASE", "PROLYTICS"],
  environment: "",
  isLoggingEnabled: false,
  appBackgroundTime: 0,
};
let isDebug;
let apiKey = process?.env?.REACT_APP_PROLYTICS_KEY;
if (process.env.REACT_APP_ENV === "staging") {
  isDebug = true;
}

if (process.env.REACT_APP_ENV === "production") {
  isDebug = false;
}

if (process.env.REACT_APP_ENV === "preprod") {
  isDebug = false;
}

prolytics.initialize(
  "in.probo.pro",
  apiKey ? apiKey : "",
  configParams,
  isDebug
);

type LogFirebaseEventProps = {
  event_name: string;
  event_key?: string;
  event_page?: string;
  event_position?: string;
  event_source?: string;
  event_value?: string;
  event_id?: number | string;
  category_id?: number | string;
  topic_id?: number | string;
  order_id?: number | string;
  order_qty?: number | string;
  order_price?: number | string;
  action_type?: string;
  feature?: string;
  type?: string;
  reason?: string;
  click_id?: string;
  poll_id?: number | string;
  option_id?: number | string;
  ballot_id?: number | string;
  user_pseudo_id?: number | string;
  event_action?: string;
  event_value_key1?: string;
  event_value_key2?: string;
  event_value_key3?: string;
  event_value_key4?: string;
  event_value_key5?: string;
  event_value_key6?: string;
  event_value_value1?: string | number | null | undefined;
  event_value_value2?: string | number | null | undefined;
  event_value_value3?: string | number | null | undefined;
  event_value_value4?: string | number | null | undefined;
  event_value_value5?: string | number | null | undefined;
  event_value_value6?: string | number | null | undefined;
  event_type?: string;
  event_section?: string;
  user_logged_in?: string;
  partner_name?: string;
  partner_id?: number;
};

if (!getPseudoUserId()) {
  setPseudoUserId();
}

export const logFirebaseEvent = ({
  event_name,
  event_key,
  event_page,
  event_position,
  event_source,
  event_value,
  event_id,
  category_id,
  topic_id,
  order_id,
  order_qty,
  order_price,
  action_type,
  feature,
  type,
  reason,
  click_id,
  poll_id,
  option_id,
  ballot_id,
  user_pseudo_id,
  event_action,
  event_value_key1,
  event_value_key2,
  event_value_key3,
  event_value_key4,
  event_value_key5,
  event_value_key6,
  event_value_value1,
  event_value_value2,
  event_value_value3,
  event_value_value4,
  event_value_value5,
  event_value_value6,
  event_type,
  event_section,
  user_logged_in,
  partner_name,
  partner_id,
}: LogFirebaseEventProps) => {
  const bundle: any = {
    event_key: event_name,
    event_timestamp: Date.now(),
    user_id: getUserId(),
    user_pseudo_id: getPseudoUserId(),
  };

  if (event_key) bundle.event_key = event_key;
  if (event_page) bundle.event_page = event_page;
  if (event_position) bundle.event_position = event_position;
  if (event_source) bundle.event_source = event_source;
  if (event_value) bundle.event_value = event_value;
  if (event_id) bundle.event_id = event_id;
  if (category_id) bundle.category_id = category_id;
  if (topic_id) bundle.topic_id = topic_id;
  if (order_id) bundle.order_id = order_id;
  if (order_qty) bundle.order_qty = order_qty;
  if (order_price) bundle.order_price = order_price;
  if (action_type) bundle.action_type = action_type;
  if (feature) bundle.feature = feature;
  if (type) bundle.type = type;
  if (reason) bundle.reason = reason;
  if (click_id) bundle.click_id = click_id;
  if (poll_id) bundle.poll_id = poll_id;
  if (option_id) bundle.option_id = option_id;
  if (ballot_id) bundle.ballot_id = ballot_id;
  if (user_pseudo_id) bundle.user_pseudo_id = user_pseudo_id;
  if (event_action) bundle.event_action = event_action;
  if (event_value_key1) bundle.event_value_key1 = event_value_key1;
  if (event_value_key2) bundle.event_value_key2 = event_value_key2;
  if (event_value_key3) bundle.event_value_key3 = event_value_key3;
  if (event_value_key4) bundle.event_value_key4 = event_value_key4;
  if (event_value_key5) bundle.event_value_key5 = event_value_key5;
  if (event_value_value1) bundle.event_value_value1 = event_value_value1;
  if (event_value_value2) bundle.event_value_value2 = event_value_value2;
  if (event_value_value3) bundle.event_value_value3 = event_value_value3;
  if (event_value_value4) bundle.event_value_value4 = event_value_value4;
  if (event_value_value5) bundle.event_value_value5 = event_value_value5;
  if (event_type) bundle.event_type = event_type;
  if (event_section) bundle.event_section = event_section;
  if (user_logged_in) bundle.user_logged_in = user_logged_in;
  if (partner_name) bundle.partner_name = partner_name;
  if (partner_id) bundle.partner_id = partner_id;
  console.warn({ event_name, bundle, logEvent });
  logEvent(analytics, event_name, bundle);

  var eventParams = {};
  if (event_value_key1 && event_value_value1) {
    const pair = { [event_value_key1]: event_value_value1 };
    eventParams = { ...eventParams, ...pair };
  }
  if (event_value_key2 && event_value_value2) {
    const pair = { [event_value_key2]: event_value_value2 };
    eventParams = { ...eventParams, ...pair };
  }
  if (event_value_key3 && event_value_value3) {
    const pair = { [event_value_key3]: event_value_value3 };
    eventParams = { ...eventParams, ...pair };
  }
  if (event_value_key4 && event_value_value4) {
    const pair = { [event_value_key4]: event_value_value4 };
    eventParams = { ...eventParams, ...pair };
  }
  if (event_value_key5 && event_value_value5) {
    const pair = { [event_value_key5]: event_value_value5 };
    eventParams = { ...eventParams, ...pair };
  }
  if (event_value_key6 && event_value_value6) {
    const pair = { [event_value_key6]: event_value_value6 };
    eventParams = { ...eventParams, ...pair };
  }

  const eventOptions: EventOptions = {
    event_page: event_page,
    event_section: event_section,
    event_action: event_action,
    event_type: event_type,
    event_trigger_source: event_source,
    id: Number(event_id),
    type: EventType.SESSION,
    event_name: event_name,
    hash: "",
    session_id: "",
    session_time: "",
    timestamp: `${Date.now()}`,
    user_id: `${getUserId()}`,
    geo: {
      lat: 0,
      lng: 0,
    },
    platform: getPlatformType() || "WEB",
    app_version: getAppVersion() || Constants.APP_VERSION,
    event_parameters: eventParams,
    client_info: {
      advertising_id: "",
      app_set_id: "",
      brand: "",
      carrier: "",
      country_from_network: "",
      ip_address: "",
      is_limit_ad_tracking_enabled: false,
      language: getLanguagePreferenceRequired() || "",
      locale: "",
      manufacturer: "",
      model: "",
      os_name: "",
      os_version: "",
      timezone: "",
      version_code: 0,
      version_name: getAppVersion() || Constants.APP_VERSION,
    },
  };
  prolytics.track(event_name, eventOptions);
};
