import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "config/context";
import { Tabs, Tab } from "@mui/material";
import SwipeableViews from "react-swipeable-views";

import styles from "./style.module.scss";
import Container from "components/Rai/Container";
import Navbar from "components/Rai/Navbar";
import { getWalletDetails } from "services/rai";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import PortfolioTab from "./PortfolioTab";
import TabPanel from "components/TabPanel";
import { logFirebaseEvent } from "config/firebase";

interface PortfolioProps {
  from?: string;
}

const Portfolio = ({ from }: PortfolioProps) => {
  const context = useContext(AppContext);
  const history = useHistory();
  const [value, setValue] = useState(
    history.location.hash === "#settled" ? 1 : 0
  );

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === 1) {
      history.push("#settled", { from: from });
    } else {
      history?.push("/portfolio", { from: from });
    }
    setValue(newValue);
  };

  useEffect(() => {
    logFirebaseEvent({
      event_name: "rai_portfolio_loaded",
      event_value_key1: "format_name",
      event_value_value1: "RAI",
      event_value_key2: "screen",
      event_value_value2: "portfolio",
      event_value_key3: "tab",
      event_value_value3:
        history.location.hash === "#settled" ? "settled" : "live",
    });
  }, []);

  return (
    <div className={styles["rai__container"]}>
      <div className={styles["nav__container"]}>
        <Navbar walletEnabled customBackLink={from} />
      </div>
      <Tabs
        className={classNames({
          [styles["portfolio__tabs"]]: true,
        })}
        style={{
          maxWidth: context.mobileLayoutWidth,
          marginTop: 50,
        }}
        value={value}
        onChange={handleChange}
        centered
        variant="fullWidth"
        TabIndicatorProps={{
          style: {
            backgroundColor: "#efefef",
          },
        }}
      >
        <Tab className={styles["portfolio__tab"]} label="Active" />
        <Tab className={styles["portfolio__tab"]} label="Settled" />
      </Tabs>

      <div className={styles["porfolio__list__container"]}>
        <SwipeableViews
          className={styles["portfolio__content__swipeable"]}
          index={value}
          onChangeIndex={handleChangeIndex}
          containerStyle={{}}
          slideClassName={styles["portfolio__content__slide"]}
        >
          <TabPanel value={value} index={0}>
            <PortfolioTab tabType="open" />
          </TabPanel>

          <TabPanel value={value} index={1}>
            <PortfolioTab tabType="closed" />
          </TabPanel>
        </SwipeableViews>
      </div>
    </div>
  );
};

export default Portfolio;
