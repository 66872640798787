import {
  getSourceName,
  getUserToken,
  getAppVersion,
  getPlatformType,
  getLanguagePreferenceRequired,
} from "config/helper";
import { Constants } from "config/constants";

let BASE_URL = "http://localhost:9000/api";

let RAI_BASE_URL = "https://localhost:9000/rai-backend/api/v1";

if (process.env.REACT_APP_ENV === "staging") {
  BASE_URL = "https://dev.api.probo.in/api";

  RAI_BASE_URL = "https://dev.api.probo.in/rai-backend/api/v1";
}

if (process.env.REACT_APP_ENV === "production") {
  BASE_URL = "https://prod.api.probo.in/api";

  RAI_BASE_URL = "https://prd.api.rai.live/api/v1";
}

if (process.env.REACT_APP_ENV === "preprod") {
  BASE_URL = "https://pre-prod.probo.in/api";

  RAI_BASE_URL = "https://prd.api.rai.live/api/v1";
}

if (process.env.REACT_APP_ENV === "master") {
  BASE_URL = "https://stg-master-api.probolabs.com/api";

  RAI_BASE_URL = "https://dev.api.probo.in/rai-backend/api/v1";
}

export { BASE_URL };
// if (process.env.REACT_APP_ENV === "staging-partner") {
//   BASE_URL = "http://backend-mpl.stg.probolabs.com/api";
//   MPL_BASE_URL = "https://qa-partner.mpl.live";
//   CASHFREE_BASE_URL = "https://sandbox.cashfree.com/pg";
// }

// API URL's

export const USER_LOGIN_URL = `${BASE_URL}/v1/user/login`;

export const VALIDATE_OTP_URL = `${BASE_URL}/v1/user/validateOtp`;

export const GET_USER_DETAILS = `${RAI_BASE_URL}/user`;

export const SET_USER_DETAILS = `${RAI_BASE_URL}/user`;

//RAI related APIs
export const RAI_GET_WAITLIST_STATUS = `${RAI_BASE_URL}/contract/waitList`;

export const RAI_PROMPT = `${RAI_BASE_URL}/prompt`;

export const RAI_CONTRACT = `${RAI_BASE_URL}/contract`;

export const RAI_GET_WALLET_DETAILS = `${RAI_BASE_URL}/wallet`;

export const RAI_GET_CONTRACT_BY_ID = `${RAI_BASE_URL}/contract/details`;

export const RAI_GET_PORTFOLIO = `${RAI_BASE_URL}/portfolio/summary`;

export const RAI_GET_PORTFOLIO_CARD = `${RAI_BASE_URL}/portfolio/card`;

export const RAI_GET_TRADE_SUMMARY = `${RAI_BASE_URL}/portfolio/tradeSummary`;

export const RAI_GET_PUBLIC_CONTRACT_DETAILS = `${RAI_BASE_URL}/contract/public/details`;

export const RAI_GET_SUGGESTION_LIST = `${RAI_BASE_URL}/contract/suggestion/list`;

export const RAI_CREATE_GROUP_CONTRACT = `${RAI_BASE_URL}/contract/create/group/play`;

export const RAI_GET_GROUP_CONTRACT = `${RAI_BASE_URL}/contract/group/play/details`;

export const RAI_CANCEL_CONTRACT = `${RAI_BASE_URL}/contract/order/cancel`;

export const RAI_VALIDATE_CONTRACT = `${RAI_BASE_URL}/referee/validate`;

export const RAI_EXPIRE_CONTRACT = `${RAI_BASE_URL}/referee/expire`;

export const RAI_CLOSE_CONTRACT = `${RAI_BASE_URL}/referee/close/contract`;

// Request (GET, POST, PUT, DELETE)
const requestHeaders = {
  "Content-type": "application/json",
  "x-device-os": "ANDROID",
  "x-version-name": Constants.APP_VERSION,
  appid: "in.probo.pro",
};

const cashfreeHeaders = {
  "Content-Type": "application/json",
  "x-api-version": "2021-05-21",
};

// Types
export type HttpClient = {
  url: string;
  data?: object;
  token?: boolean;
  tokenValue?: any;
  headers?: object;
  paymentHeaders?: object;
  isFormData?: boolean;
  includeDeviceInfo?: boolean;
  signal?: any;
};

export type HandleResponse = {
  requestOptions: object;
  method: string;
  url: string;
};

async function handleResponse({ requestOptions, method, url }: HandleResponse) {
  const globalThis: any = window;
  try {
    console.log(`${method} request data (${url}):`, requestOptions);
    const response = await fetch(url, requestOptions);

    let responseData: any = {};

    // if (response && [401].includes(response?.status)) {
    //   responseData = await response.json();

    //   if (responseData?.message === "Authentication failure") {
    //     setTimeout(() => {
    //       clearlocalStorage();
    //       history.push("/login");
    //     }, 500);
    //   }
    // }

    // specific to MPL
    if (
      response &&
      [401].includes(response?.status) &&
      getSourceName() === Constants.PROBO_MPL
    ) {
      globalThis?.mplAndroidView?.closeWeb();
    }
    if (response && [204].includes(response?.status)) {
      // Response successful Empty Data
      return { isError: false, message: "Successfully Done!!", data: null };
    }

    if (response && response?.status) {
      responseData = await response.json();
    }

    // if (response && [200].includes(response?.status)) {
    //   // Response successful
    //   console.log(`${method} request success!`);
    //   console.log(`${method} response data (${url}):`, responseData);
    // }

    // if (response && [400].includes(response?.status)) {
    //   // Bad request
    //   console.error(`${method} request error!`);
    //   responseData = {
    //     data: {},
    //     isError: true,
    //     // message: `Error Code: (400) Bad Request!`,
    //     message: response?.message,
    //   };
    // }

    // if (response && [401].includes(response?.status)) {
    //   // Authorization error
    //   console.error(`${method} request authorization error!`);
    //   responseData = {
    //     data: {},
    //     isError: true,
    //     // message: `Error Code: (401) You are not authorized to perform this action!`,
    //     message: response?.message,
    //   };
    // }

    // if (response && [500].includes(response?.status)) {
    //   // Internal server error
    //   console.error(`${method} request internal server error!`);
    //   responseData = {
    //     data: {},
    //     isError: true,
    //     // message: `Error Code: (501) Internal server error!`,
    //     message: response?.message,
    //   };
    // }
    return responseData;
  } catch (error) {
    // Backend service down
    console.error(`${method} backend service down`);
    const data = {
      data: {},
      isError: true,
      message: `Service unavailable. Please try again after sometime`,
    };
    return data;
  }
}

export const httpClient = {
  get: async ({
    url = "",
    token = false,
    headers = {},
    paymentHeaders,
    signal = null,
  }: HttpClient) => {
    const method = "GET";

    let conditionalHeaders: any = { ...requestHeaders, ...headers };

    if (token) {
      conditionalHeaders.Authorization = `Bearer ${getUserToken()}`;
    }

    if (getAppVersion()) {
      conditionalHeaders = {
        ...conditionalHeaders,
        "x-version-name": `${getAppVersion()}`,
      };
    }

    if (paymentHeaders) {
      conditionalHeaders = cashfreeHeaders;
    }

    if (getLanguagePreferenceRequired()) {
      conditionalHeaders = {
        ...conditionalHeaders,
        "Accept-Language": getLanguagePreferenceRequired(),
      };
    }

    const requestOptions = {
      method,
      headers: conditionalHeaders,
      signal,
    };
    return handleResponse({ requestOptions, method, url });
  },

  post: async ({
    url = "",
    data = {},
    token = false,
    headers = {},
    paymentHeaders = {},
    isFormData = false,
    includeDeviceInfo = false,
  }: HttpClient) => {
    const method = "POST";

    let conditionalHeaders: any = { ...requestHeaders, ...headers };

    if (token) {
      conditionalHeaders.Authorization = `Bearer ${getUserToken()}`;
    }

    if (getAppVersion()) {
      conditionalHeaders = {
        ...conditionalHeaders,
        "x-version-name": `${getAppVersion()}`,
      };
    }

    if (Object.keys(paymentHeaders).length > 0) {
      conditionalHeaders = paymentHeaders;
    }

    if (isFormData) {
      delete conditionalHeaders["Content-type"];
    }

    if (includeDeviceInfo) {
      conditionalHeaders["x-platform-type"] = getPlatformType();
    }

    if (getLanguagePreferenceRequired()) {
      conditionalHeaders = {
        ...conditionalHeaders,
        "Accept-Language": getLanguagePreferenceRequired(),
      };
    }

    const requestOptions = {
      method,
      body: isFormData ? data : JSON.stringify(data),
      headers: conditionalHeaders,
      redirect: "follow",
    };
    return handleResponse({ requestOptions, method, url });
  },

  patch: async ({
    url = "",
    data = {},
    token = false,
    tokenValue,
    headers = {},
  }: HttpClient) => {
    const method = "PATCH";

    const vendorsHeaders = {
      "Content-type": "application/json",
    };

    let conditionalHeaders: any = { ...vendorsHeaders, ...headers };

    if (token && tokenValue) {
      conditionalHeaders.Authorization = tokenValue;
    } else if (token) {
      conditionalHeaders.Authorization = `Bearer ${getUserToken()}`;
    }

    if (getAppVersion()) {
      conditionalHeaders = {
        ...conditionalHeaders,
        "x-version-name": `${getAppVersion()}`,
      };
    }

    if (getLanguagePreferenceRequired()) {
      conditionalHeaders = {
        ...conditionalHeaders,
        "Accept-Language": getLanguagePreferenceRequired(),
      };
    }

    const requestOptions = {
      method,
      body: JSON.stringify(data),
      headers: conditionalHeaders,
    };
    return handleResponse({ requestOptions, method, url });
  },

  put: async ({
    url = "",
    data = {},
    token = false,
    headers = {},
    paymentHeaders,
    isFormData = false,
  }: HttpClient) => {
    const method = "PUT";

    let conditionalHeaders: any = { ...requestHeaders, ...headers };

    if (token) {
      conditionalHeaders.Authorization = `Bearer ${getUserToken()}`;
    }

    if (getAppVersion()) {
      conditionalHeaders = {
        ...conditionalHeaders,
        "x-version-name": `${getAppVersion()}`,
      };
    }

    if (paymentHeaders) {
      conditionalHeaders = cashfreeHeaders;
    }

    if (isFormData) {
      delete conditionalHeaders["Content-type"];
    }

    if (getLanguagePreferenceRequired()) {
      conditionalHeaders = {
        ...conditionalHeaders,
        "Accept-Language": getLanguagePreferenceRequired(),
      };
    }

    const requestOptions = {
      method,
      body: isFormData ? data : JSON.stringify(data),
      headers: conditionalHeaders,
    };
    return handleResponse({ requestOptions, method, url });
  },

  delete: async ({
    url = "",
    data = {},
    token = false,
    headers = {},
    paymentHeaders,
  }: HttpClient) => {
    const method = "DELETE";

    let conditionalHeaders: any = { ...requestHeaders, ...headers };

    if (token) {
      conditionalHeaders.Authorization = `Bearer ${getUserToken()}`;
    }

    if (getAppVersion()) {
      conditionalHeaders = {
        ...conditionalHeaders,
        "x-version-name": `${getAppVersion()}`,
      };
    }

    if (paymentHeaders) {
      conditionalHeaders = cashfreeHeaders;
    }

    if (getLanguagePreferenceRequired()) {
      conditionalHeaders = {
        ...conditionalHeaders,
        "Accept-Language": getLanguagePreferenceRequired(),
      };
    }

    const requestOptions = {
      method,
      headers: conditionalHeaders,
    };
    return handleResponse({ requestOptions, method, url });
  },

  upload: async ({ url = "", data = {}, headers = {} }) => {
    const method = "POST";

    const requestOptions = {
      method,
      body: data,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    return handleResponse({ requestOptions, method, url });
  },
};
