import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

import Container from "components/Rai/Container";
import Navbar from "components/Rai/Navbar";
import EventCard, { EventProps } from "components/Rai/EventCard";
import RaiButton from "components/Rai/Button";
import Loading from "components/Rai/Loading";

import EditIcon from "assets/icons/rai/edit.svg";
import Failed from "assets/images/rai/failed.svg";
import Magnifier from "assets/images/rai/magnifier.svg";
import styles from "./style.module.scss";
import { logFirebaseEvent } from "config/firebase";

const details = [
  "Virat Kohli: 35 years, Male Indian Cricketer",
  "Double Century: scoring >200 runs ",
  "This contract will end by the end of 2023 ",
  "Source of truth: Google.com ",
];

interface SuggestionsProps {
  event?: EventProps;
  type: "suggestion" | "profanity" | "beyond";
  question: any;
}

const Suggestions = ({ event, type, question }: SuggestionsProps) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    logFirebaseEvent({
      event_name: "rai_suggestion_screen_loaded",
      event_value_key1: "format_name",
      event_value_value1: "RAI",
      event_value_key2: "screen",
      event_value_value2: "suggestion",
    });
  }, []);

  return (
    <Container>
      {loading && (
        <>
          <Navbar title={"₹.AI"} subtitle={"by Probo"} />
          <Loading
            message="Relax! ₹.AI will setlle this contract for you"
            status="success"
          />
        </>
      )}
      {!loading && (
        <>
          <div className={styles["top__container"]}>
            <Navbar title={"₹.AI"} subtitle={"by Probo"} />

            <div className={styles["create__container"]}>
              <span className={styles["contract__title__wrapper"]}>
                <span className={styles["contract__title"]}>Your question</span>
              </span>

              <div className={styles["event__card__question"]}>
                {event?.contract_name}
              </div>
            </div>
          </div>

          <div className={styles["error__container"]}>
            <img src={Failed} />
            <div className={styles["error__message__wrapper"]}>
              <span className={styles["error__title"]}>
                {
                  "This input is beyond the scope of auto-settlement for now. You can choose to settle this contract privately via referee."
                }
              </span>
            </div>
            <RaiButton
              className={styles["suggestion__button"]}
              variant="contained"
              onClick={() => {
                logFirebaseEvent({
                  event_name: "rai_invite_referee_pressed",
                  event_value_key1: "format_name",
                  event_value_value1: "RAI",
                  event_value_key2: "screen",
                  event_value_value2: "suggestion",
                  event_value_key3: "question",
                  event_value_value3: question,
                });
                history.push("/create/opinion", {
                  event: event,
                  referee_flow: true,
                });
              }}
            >
              Invite a referee
            </RaiButton>
            <RaiButton
              className={styles["suggestion__button"]}
              variant="outlined"
              onClick={() => {
                logFirebaseEvent({
                  event_name: "rai_edit_pressed",
                  event_value_key1: "format_name",
                  event_value_value1: "RAI",
                  event_value_key2: "screen",
                  event_value_value2: "suggestion",
                  event_value_key3: "question",
                  event_value_value3: question,
                });
                history.push("/question", { value: event?.contract_name });
              }}
            >
              Try editing for auto-settlement
            </RaiButton>
          </div>
          <div className={styles["footer__section"]}></div>
        </>
      )}
    </Container>
  );
};

export default Suggestions;
