import React, { useContext, useEffect, useState } from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import PortfolioIcon from "assets/icons/rai/portfolio.svg";
import RupeeIcon from "assets/icons/rai/currency-rupee.svg";
import Logo from "assets/icons/rai/rai.svg";

import DrawerItems from "components/DrawerItems";

import styles from "./style.module.scss";
import { useHistory } from "react-router-dom";
import { getWalletDetails } from "services/rai";
import { AppContext } from "config/context";
import classNames from "classnames";
import { Constants } from "config/constants";

interface NavbarProps {
  title?: string;
  subtitle?: string;
  className?: any;
  backButtonDisabled?: boolean;
  portfolioDisabled?: boolean;
  walletEnabled?: boolean;
  customBackLink?: string;
  type?: "close" | null;
  drawerEnabled?: boolean;
}

const Navbar = ({
  title,
  subtitle,
  className,
  backButtonDisabled,
  portfolioDisabled,
  walletEnabled,
  customBackLink,
  type,
  drawerEnabled,
}: NavbarProps) => {
  const history = useHistory();
  const context = useContext(AppContext);

  const [walletBalance, setWalletBalance] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const windowWidth = (context.windowWidth - context.mobileLayoutWidth) / 2;

  const getWallet = async () => {
    const response: any = await getWalletDetails();
    if (response?.statusCode === 200) {
      setWalletBalance(response?.data?.total_amount);
    }
  };
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    (walletEnabled || drawerEnabled) && getWallet();
  }, [walletEnabled, drawerEnabled]);

  return (
    <div className={classNames([styles["navbar__container"], className])}>
      <div className={styles["navbar__item"]}>
        {!(backButtonDisabled || type || drawerEnabled) && (
          <ArrowBackIcon
            className={styles["back__icon"]}
            onClick={() => {
              if (customBackLink) {
                history?.push(customBackLink);
              } else {
                history.goBack();
              }
            }}
          />
        )}

        {type && (
          <CloseIcon
            onClick={() => {
              if (customBackLink) {
                history?.push(customBackLink);
              } else {
                history.push("/");
              }
            }}
          />
        )}
      </div>
      <div className={styles["navbar__item"]}>
        {title && (
          <div className={styles["navbar__title__wrapper"]}>
            <span
              className={styles["navbar__title"]}
              onClick={() => history?.push("/")}
            >
              <img src={Logo} />
            </span>
            <span
              className={styles["navbar__subtitle"]}
              onClick={() => history?.push("/")}
            >
              {subtitle}
            </span>
          </div>
        )}
      </div>

      <div className={styles["navbar__item"]}>
        {!portfolioDisabled && !walletEnabled && !drawerEnabled && (
          <img
            className={styles["portfolio__icon"]}
            onClick={() => {
              if (history?.location?.pathname?.includes("/group/question")) {
                history?.push("/portfolio", {
                  from: "/group/question/list",
                });
              } else {
                history?.push("/portfolio", {
                  from: history?.location?.pathname,
                });
              }
            }}
            src={PortfolioIcon}
            alt={"Portfolio"}
          />
        )}
        {drawerEnabled && (
          <MenuIcon
            className={styles["menu__icon"]}
            onClick={() => {
              toggleDrawer();
            }}
          />
        )}
      </div>
      {!portfolioDisabled && walletEnabled && (
        <div
          className={styles["wallet__details"]}
          onClick={() => {
            window.open(`${Constants?.TRADING_WEB_URL}/payments`, "_blank");
          }}
        >
          <img
            className={styles["rupee__icon"]}
            src={RupeeIcon}
            alt={"Rupee"}
          />
          {walletBalance?.toFixed(1)}
        </div>
      )}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer}
        PaperProps={{
          style: {
            right: windowWidth < 0 ? 0 : windowWidth,
            width: 270,
            justifyContent: "space-between",
            background: "#19171D",
          },
        }}
        BackdropProps={{
          style: {
            left: windowWidth < 0 ? 0 : windowWidth,
            maxWidth: context.mobileLayoutWidth,
          },
        }}
        ModalProps={{
          keepMounted: true,
          style: {
            position: "absolute",
            left: windowWidth < 0 ? 0 : windowWidth,
            width: "100%",
            maxWidth: context.mobileLayoutWidth,
          },
        }}
        SlideProps={{
          // appear: false,
          onEntering: (node) => {
            // node.style.transform = "none";
            node.style.transition = "none";
          },
          onEntered: (node) => {
            // node.style.transform = "none";
            node.style.transition = "none";
          },
          onExiting: (node) => {
            // node.style.transform = "none";
            node.style.transition = "none";
          },
        }}
      >
        <DrawerItems
          toggleDrawer={toggleDrawer}
          walletBalance={walletBalance}
        />
      </Drawer>
    </div>
  );
};

export default Navbar;
