import React from "react";

import styles from "./style.module.scss";

interface DetailsListCardProps {
  title?: string;
  list?: Array<string>;
}

const DetailsListCard = ({ title, list }: DetailsListCardProps) => {
  return (
    <div className={styles["details__card__container"]}>
      <span className={styles["details__card__title"]}>{title}</span>
      <ul className={styles["details__card__list__container"]}>
        {list?.map((ele: any, index: number) => {
          if (ele.value) {
            return (
              <li className={styles["details__card__list__item"]}>
                <span key={index}>
                  {ele.key}: {ele.value}
                </span>
              </li>
            );
          }
          return <></>;
        })}
      </ul>
    </div>
  );
};

export default DetailsListCard;
