import EventCard, { EventProps } from "../EventCard";

import styles from "./style.module.scss";
import Divider from "assets/images/rai/divider.svg";
import classNames from "classnames";
import { Constants } from "config/constants";

interface DetailsProps {
  title?: string;
  text?: string;
  value?: string;
}
interface ContractCardProps {
  event: EventProps;
  initiator_rai_details?: DetailsProps;
  reward_details?: DetailsProps;
  rewardDisabled?: boolean;
  outcome_type?: string;
  result?: string;
  contract_winnings?: any;
  is_referee_flow?: boolean;
  referee_name?: string;
}

const ContractCard = ({
  event,
  initiator_rai_details,
  reward_details,
  rewardDisabled,
  outcome_type,
  result,
  contract_winnings,
  is_referee_flow,
  referee_name,
}: ContractCardProps) => {
  console.log("Referee", is_referee_flow, rewardDisabled);
  return (
    <div className={styles["contract__details__container"]}>
      {outcome_type && result && (
        <div
          className={classNames({
            [styles["result__section"]]: true,
            [styles["result__section__won"]]: outcome_type === Constants?.WON,
            [styles["result__section__lost"]]: outcome_type === Constants?.LOST,
          })}
        >
          {result}
        </div>
      )}
      <EventCard
        className={styles["event__card"]}
        event={event}
        referee_flow={is_referee_flow}
        referee_name={referee_name}
      />
      <div className={styles["contract__info__container"]}>
        <div className={styles["contract__info"]}>
          <span className={styles["contract__info__title__wrapper"]}>
            <img src={Divider} alt={"Divider1"} />
            <span className={styles["contract__info__title"]}>
              {initiator_rai_details?.title}
            </span>
            <img src={Divider} alt={"Divider2"} />
          </span>
          <span className={styles["contract__info__text"]}>
            {initiator_rai_details?.text}
          </span>
        </div>
        {!rewardDisabled && (
          <>
            {outcome_type && result && !is_referee_flow ? (
              <div className={styles["investment__return__wrapper"]}>
                <div className={styles["investment__return__container"]}>
                  <div className={styles["investment__container"]}>
                    <span className={styles["investment__amount"]}>
                      ₹{contract_winnings?.value}
                    </span>
                    <span className={styles["investment__text"]}>Invested</span>
                  </div>
                  <div className={styles["return__container"]}>
                    <span
                      className={classNames({
                        [styles["return__amount"]]: true,
                        [styles["return__amount__won"]]:
                          outcome_type === Constants?.WON,
                      })}
                    >
                      ₹
                      {outcome_type === Constants?.WON
                        ? reward_details?.value + contract_winnings?.value
                        : 0}
                    </span>
                    <span className={styles["return__text"]}>Returns</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles["contract__info"]}>
                <span className={styles["contract__info__title__wrapper"]}>
                  <img src={Divider} alt={"Divider3"} />
                  <span className={styles["contract__info__title"]}>
                    {reward_details?.title}
                  </span>
                  <img src={Divider} alt={"Divide4"} />
                </span>

                <span className={styles["contract__info__text"]}>
                  {reward_details?.text}
                </span>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ContractCard;
