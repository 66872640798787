import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import OtpInput from "react-otp-input";
import { LoginServiceProps, login } from "services/auth/login";
import { validateOtp } from "services/auth/otp";
import { Constants } from "config/constants";
import {
  setUserType,
  setSessionId,
  setUserMobileNumber,
  deleteUserReferralCode,
  setUserId,
  getPlatformType,
} from "config/helper";
import { Toast } from "components/Toast";

import Container from "components/Rai/Container";
import Navbar from "components/Rai/Navbar";
import styles from "./style.module.scss";
import RaiButton from "components/Rai/Button";
import classNames from "classnames";
import { getPublicContractDetails } from "services/rai";
import { logFirebaseEvent } from "config/firebase";

const publicContract = {
  title: "You are invited to a contract with",
  profile: {
    image:
      "https://gumlet-images-bucket.s3.ap-south-1.amazonaws.com/probo_product_images/IMAGE_24f6ecef-e5d0-40e3-b8c9-9ed57b5d546b.png",
    name: "@Aman_Gol",
  },
  expiry: {
    text: "with tentative expiry Today",
  },
};

const Login = () => {
  const history = useHistory();
  const { search } = useLocation();
  const param = new URLSearchParams(search);
  const contractId = param.get("contractId");
  const user = param.get("user");
  const [contract, setContract] = useState<any>();
  const [isFocused, setIsFocused] = useState(false);

  const [number, setNumber] = useState<number>();

  const handleChange = (e: any) => {
    if (e?.target?.value?.toString()?.length <= 10) setNumber(e?.target?.value);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" && number?.toString()?.length === 10) {
      handleNumberSubmit();
    }
  };

  const getPublicContract = async () => {
    const response: any = await getPublicContractDetails(contractId!);
    if (response?.statusCode === 200) {
      setContract(response?.data);
    } else {
      Toast({
        message: response?.message,
        type: "error",
      });
    }
  };

  const handleNumberSubmit = async () => {
    const param = new URLSearchParams(search);
    const contractId = param.get("contractId");
    const format = param.get("format");

    number && localStorage?.setItem("number", number.toString());

    const data: LoginServiceProps = {
      mobile: number?.toString(),
      is_following_referee: false,
    };
    const response: any = await login(data);

    if (response && !response?.isError) {
      setUserType(response?.data?.is_new_user);
      setSessionId(response?.data?.sessionId);
      setUserId(response?.data?.userId);
      if (response?.data?.message) {
        Toast({
          message: response?.data?.message,
          type: "success",
        });
      }
      logFirebaseEvent({
        event_name: "rai_phone_number_entered",
        event_value_key1: "format_name",
        event_value_value1: "RAI",
        event_value_key2: "screen",
        event_value_value2: "login",
        event_value_key3: "phone_number",
        event_value_value3: number,
      });
      setUserMobileNumber(number?.toString() || "");
      user === "ref"
        ? history.push(`/otp?contractId=${contractId}&user=ref`)
        : format !== "group"
        ? history.push(`/otp?contractId=${contractId}`)
        : history.push(`/otp?contractId=${contractId}&format=group`);
    } else {
      Toast({
        message: response?.message,
        type: "error",
      });
      if (response?.metaData?.user_exist) {
        deleteUserReferralCode();
      }
    }
  };

  const handleFocus = (focus: boolean) => {
    if (["android", "iOS", "m_web"].includes(getPlatformType())) {
      if (!focus) {
        handleNumberSubmit();
      }
      setIsFocused(focus);
    }
  };

  useEffect(() => {
    if (contractId) {
      getPublicContract();
    }
    logFirebaseEvent({
      event_name: "rai_login_screen_loaded",
      event_value_key1: "format_name",
      event_value_value1: "RAI",
      event_value_key2: "screen",
      event_value_value2: "login",
    });
  }, []);

  return (
    <Container>
      <div className={styles["top__container"]}>
        <Navbar
          title="₹.AI"
          subtitle="by Probo"
          backButtonDisabled
          portfolioDisabled
        />
        <div className={styles["contract__container"]}>
          {contractId && (
            <>
              {contract?.initiator_rai_details?.text && (
                <span className={styles["title"]}>
                  {"You are invited to a contract with"}
                </span>
              )}
              <div className={styles["profile__container"]}>
                {contract?.initiator_rai_details?.profile_pic && (
                  <img
                    className={styles["profile__image"]}
                    src={contract?.initiator_rai_details?.profile_pic}
                  />
                )}
                <span className={styles["profile__name"]}>
                  {contract?.initiator_rai_details?.text}
                </span>
                {/* {contract?.contract_details?.expiry && (
                  <span className={styles["expiry__text"]}>
                    with tentative expiry: {contract?.contract_details?.expiry}
                  </span>
                )} */}
              </div>
            </>
          )}

          <div className={styles["input__container"]}>
            <span className={styles["input__text"]}>
              {"Enter mobile number to continue"}
            </span>

            <div className={styles["input__wrapper"]}>
              <select className={styles["country__code"]}>
                <option className={styles["country__code__option"]}>+91</option>
              </select>
              <input
                className={styles["phone__number"]}
                type="tel"
                autoComplete="off"
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                value={number}
                onFocus={(e) => handleFocus(true)}
                onBlur={(e) => handleFocus(false)}
                autoFocus
              />
            </div>
            {isFocused && (
              <RaiButton
                variant="contained"
                onClick={handleNumberSubmit}
                disabled={number?.toString()?.length !== 10}
              >
                Continue
              </RaiButton>
            )}
          </div>
        </div>
      </div>
      {!isFocused && (
        <RaiButton
          variant="contained"
          onClick={handleNumberSubmit}
          disabled={number?.toString()?.length !== 10}
        >
          Continue
        </RaiButton>
      )}
    </Container>
  );
};

export default Login;
