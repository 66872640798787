import React, { useState } from "react";

import Container from "components/Rai/Container";
import Navbar from "components/Rai/Navbar";

import UserIcon from "assets/icons/rai/user.svg";

import styles from "./style.module.scss";
import RaiButton from "components/Rai/Button";
import classNames from "classnames";
import Loading from "components/Rai/Loading";
import { useHistory } from "react-router-dom";
import { EventCardProps, EventProps } from "components/Rai/EventCard";
import { setUserDetails } from "services/user";
import { logFirebaseEvent } from "config/firebase";
import { Toast } from "components/Toast";
import { postContract } from "services/rai";

interface EnterNameProps {
  event: EventProps;
  opinion?: "agree" | "disagree";
  contract_price?: number;
  contract_qty?: number;
  referee_flow?: boolean;
  is_money_flow?: boolean;
  winning_text?: string;
}

const EnterName = ({
  event,
  opinion,
  contract_price,
  contract_qty,
  referee_flow,
  is_money_flow,
  winning_text,
}: EventCardProps) => {
  const history = useHistory();

  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  const createContract = async () => {
    logFirebaseEvent({
      event_name: "rai_create_contract_pressed",
      event_value_key1: "format_name",
      event_value_value1: "RAI",
      event_value_key2: "screen",
      event_value_value2: "number_of_contracts",
      event_value_key3: "number_of_contracts",
      event_value_value3: contract_qty,
      event_value_key4: "stakes",
      event_value_value4: contract_price,
      event_value_key5: "question",
      event_value_value5: event?.contract_name,
      event_value_key6: "request_id",
      event_value_value6: event?.request_id,
    });
    setLoading(true);
    const data = {
      request_id: event?.request_id,
      contract_qty: contract_qty,
      contract_price: contract_price,
      winning_text: winning_text,
      is_money_flow: is_money_flow,
      offer_type: opinion === "agree" ? "BUY" : "SELL",
    };
    const response: any = await postContract(data);

    if (response.statusCode === 201) {
      logFirebaseEvent({
        event_name: "rai_contract_created",
        event_value_key1: "format_name",
        event_value_value1: "RAI",
        event_value_key2: "screen",
        event_value_value2: "number_of_contracts",
        event_value_key3: "request_id",
        event_value_value3: event?.request_id,
        event_value_key4: "event_id",
        event_value_value4: response?.data?.event_id,
      });
      // const id = 111;

      const ref = response?.referee_flow;
      if (ref) {
        history.push(`/contract/ref-share/${response?.data?.event_id}`);
      } else {
        history.push(`/contract/${response?.data?.event_id}`);
      }
    } else {
      logFirebaseEvent({
        event_name: "rai_contract_creation_failed",
        event_value_key1: "format_name",
        event_value_value1: "RAI",
        event_value_key2: "screen",
        event_value_value2: "number_of_contracts",
        event_value_key3: "request_id",
        event_value_value3: event?.request_id,
      });
      Toast({
        message: response?.message,
        type: "error",
      });
      setLoading(false);
    }
  };

  const handleChange = (e: any) => {
    setName(e?.target?.value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const data = { name: name };
    const response: any = await setUserDetails(data);
    if (response?.statusCode === 200) {
      createContract();
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" && name?.length > 0) {
      handleSubmit();
    }
  };

  return (
    <Container>
      <div className={styles["top__container"]}>
        <Navbar title={"₹.AI"} subtitle={"by Probo"} />
        {!loading && (
          <>
            <div className={styles["create__container"]}>
              <img src={UserIcon} />
              <div className={styles["user__title__container"]}>
                <span className={styles["user__title"]}>Tell us your name</span>
                <span className={styles["user__subtitle"]}>
                  Enter your full name so your friends know it’s you
                </span>
              </div>
              <input
                className={styles["user__input"]}
                placeholder="Enter your name"
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              />
            </div>{" "}
          </>
        )}
      </div>
      {!loading && (
        <RaiButton
          className={classNames({ [styles["disabled__button"]]: !name })}
          variant="contained"
          onClick={handleSubmit}
        >
          Continue
        </RaiButton>
      )}

      {loading && <Loading message="Processing contract..." />}
    </Container>
  );
};

export default EnterName;
