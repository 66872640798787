import React, { useContext } from "react";

import styles from "./style.module.scss";
import { AppContext } from "config/context";
import classNames from "classnames";
interface ContainerProps {
  children: any;
  className?: any;
}
const Container = ({ children, className }: ContainerProps) => {
  const context = useContext(AppContext);

  return (
    <div className={classNames([styles["rai__container"]], className)} style={{ minHeight: context.windowHeight }}>
      {children}
    </div>
  );
};

export default Container;
