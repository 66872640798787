import React, { useEffect, useState } from "react";

import styles from "./style.module.scss";
import Container from "components/Rai/Container";
import Navbar from "components/Rai/Navbar";
import EventCard, { EventCardProps } from "components/Rai/EventCard";
import RaiButton from "components/Rai/Button";
import DetailsListCard from "components/Rai/DetailsListCard";
import EditIcon from "assets/icons/rai/edit.svg";
import { useHistory } from "react-router-dom";
import { logFirebaseEvent } from "config/firebase";

const Create = ({ event, referee_flow }: EventCardProps) => {
  const history = useHistory();
  console.log("event Details", event);

  useEffect(() => {
    logFirebaseEvent({
      event_name: "rai_create_screen_loaded",
      event_value_key1: "format_name",
      event_value_value1: "RAI",
      event_value_key2: "screen",
      event_value_value2: "create",
      event_value_key3: "request_id",
      event_value_value3: event?.request_id,
    });
  }, []);
  return (
    <Container>
      <div className={styles["top__container"]}>
        <Navbar title={"₹.AI"} subtitle={"by Probo"} />
        <div className={styles["create__container"]}>
          <span className={styles["contract__title__wrapper"]}>
            <span className={styles["contract__title"]}>Contract</span>
          </span>
          <EventCard event={event} referee_flow={referee_flow} />
          {!referee_flow && (
            <DetailsListCard
              title={"Contract Details"}
              list={event?.contractDetails}
            />
          )}
          <span
            className={styles["edit__contract__wrapper"]}
            onClick={() => {
              logFirebaseEvent({
                event_name: "rai_edit_pressed",
                event_value_key1: "format_name",
                event_value_value1: "RAI",
                event_value_key2: "screen",
                event_value_value2: "create",
                event_value_key3: "request_id",
                event_value_value3: event?.request_id,
              });
              history.push("/question", { value: event?.contract_name });
            }}
          >
            Edit contract{" "}
            <img className={styles["edit__contract__icon"]} src={EditIcon} />
          </span>
        </div>
      </div>
      <RaiButton
        variant="contained"
        onClick={() => {
          logFirebaseEvent({
            event_name: "rai_continue_pressed",
            event_value_key1: "format_name",
            event_value_value1: "RAI",
            event_value_key2: "screen",
            event_value_value2: "create",
            event_value_key3: "request_id",
            event_value_value3: event?.request_id,
          });
          history.push("/create/opinion", {
            event: event,
            referee_flow: referee_flow,
          });
        }}
      >
        Continue
      </RaiButton>
    </Container>
  );
};

export default Create;
