import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { logFirebaseEvent } from "config/firebase";
import { SwipeableDrawer } from "@mui/material";
import { AppContext } from "config/context";

import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CloseIcon from "@mui/icons-material/Close";
import Success from "assets/images/rai/checkmark.svg";
import Failed from "assets/images/rai/failed.svg";
import RefereeSettle from "assets/icons/rai/referee.svg";

import RaiButton from "../Button";
import { CircularLoader } from "components/Loaders";
import { cancelContract } from "services/rai";

import GreenCheck from "assets/icons/rai/green_check_small.svg";

import styles from "./style.module.scss";
import classNames from "classnames";

export interface EventProps {
  request_id?: string | number;
  category_ids?: Array<number>;
  contract_name?: string;
  template_params?: any;
  expiry_dt?: string | null;
  profile?: any;
  rai_details?: any;
  creator_details?: any;
  event_id?: string | number;
  type?: string;
  poll_id?: string | number;
  max_user_limit?: number;
  members_qty?: number;
  order_id?: string | number;
  order_details?: any;
  bottom_layer_right_value?: string;
  bottom_layer_right_color?: string;
  is_referee_flow?: boolean;
  is_money_flow?: boolean;
}
export interface PortfolioEventCardProps {
  event: EventProps;
}

const PortfolioEventCard = ({
  contract_name,
  expiry_dt,
  rai_details,
  creator_details,
  event_id,
  type,
  poll_id,
  max_user_limit,
  members_qty,
  order_id,
  order_details,
  bottom_layer_right_value,
  bottom_layer_right_color,
  is_referee_flow,
  is_money_flow,
  ...rest
}: EventProps) => {
  const history = useHistory();
  const context = useContext(AppContext);

  const windowWidth = (context?.windowWidth - context?.mobileLayoutWidth) / 2;

  const [bottomSheetOpen, setBottomSheetOpen] = useState(false);
  const [cancelStatus, setCancelStatus] = useState<
    "open" | "loading" | "cancelled" | "failed"
  >("open");
  const [cancelSubtitle, setcancelSubtitle] = useState("");

  const handleCancel = async () => {
    setCancelStatus("loading");
    const response: any = await cancelContract(order_id!);
    if (response?.statusCode === 200) {
      console.log(response);
      setCancelStatus("cancelled");
      setcancelSubtitle("Amount credited to your wallet balance");
    } else {
      setCancelStatus("failed");
      setcancelSubtitle(response?.message || "Something went wrong!");
    }
  };

  const profit = bottom_layer_right_value?.includes("-")
    ? "₹0"
    : bottom_layer_right_value;

  return (
    <>
      <div className={styles["portfolio__event__card__container"]}>
        <div
          className={styles["event__card__question"]}
          onClick={() => {
            logFirebaseEvent({
              event_name: "rai_portfolio_card_clicked",
              event_value_key1: "format_name",
              event_value_value1: "RAI",
              event_value_key2: "screen",
              event_value_value2: "portfolio",
              event_value_key3: "tab",
              event_value_value3:
                history.location.hash === "#settled" ? "settled" : "live",
              event_value_key4: "event_id",
              event_value_value4: event_id,
            });
            type !== "poll"
              ? history?.push(`/contract/${event_id}/details`)
              : history?.push(`/group/contract/${event_id}/details`);
          }}
        >
          <span className={styles["event__card__top__info"]}>
            {rai_details?.title && type !== "poll" && (
              <span className={styles["event__card__tag"]}>
                {rai_details?.title}
              </span>
            )}

            <span
              className={classNames({
                [styles["event__card__tag"]]: true,
                [styles["event__card__tag__money"]]: is_money_flow,
                [styles["event__card__tag__non__money"]]: !is_money_flow,
              })}
            >
              {is_money_flow ? "REAL MONEY" : "NON MONEY"}
            </span>

            {type === "poll" && (
              <span
                className={classNames({
                  [styles["event__card__tag"]]: true,
                  [styles["event__card__tag__poll"]]: true,
                })}
              >
                {type}
              </span>
            )}
            {creator_details?.title && (
              <span className={styles["event__card__creator"]}>
                {creator_details?.title}
              </span>
            )}
          </span>
          {contract_name}
        </div>
        <div className={styles["event__card__details"]}>
          {(members_qty === max_user_limit || type === "poll") && (
            <>
              <span className={styles["event__card__details__left"]}>
                {bottom_layer_right_color === "#45DE92" ? (
                  <>
                    <img src={GreenCheck} />
                    <span className={styles["win__text"]}>You won</span>
                  </>
                ) : (
                  <>{expiry_dt && <span>Expiry : {expiry_dt}</span>}</>
                )}
              </span>

              <span className={styles["event__card__details__right"]}>
                {profit && is_money_flow ? (
                  <span className={styles["profit__wrapper"]}>
                    Profit:
                    <span
                      className={styles["profit__text"]}
                      style={{
                        color:
                          profit === "₹0" || profit == "Pending"
                            ? "#ffffff"
                            : bottom_layer_right_color,
                      }}
                    >
                      {profit}
                    </span>
                  </span>
                ) : (
                  <>
                    {is_referee_flow ? "Referee settle" : "Settled by ₹.AI"}
                    <img
                      className={styles["settle__icon"]}
                      src={
                        is_referee_flow
                          ? RefereeSettle
                          : "https://gumlet-images-bucket.s3.ap-south-1.amazonaws.com/probo_product_images/IMAGE_23f5d82f-13bb-46a1-9e82-43b0cd39ff85.png"
                      }
                      alt={"Settle"}
                    />
                  </>
                )}
              </span>
            </>
          )}
          {max_user_limit !== undefined &&
            members_qty !== undefined &&
            members_qty !== max_user_limit &&
            type === "RAI" && (
              <>
                <span className={styles["event__card__details__left"]}>
                  <ErrorOutlineOutlinedIcon
                    className={styles["event__card__details__left__icon"]}
                  />
                  <span className={styles["event__card__details__left__text"]}>
                    {`Open contracts: ${
                      max_user_limit - members_qty
                    }/${max_user_limit}`}
                  </span>
                </span>
                <span className={styles["event__card__details__right"]}>
                  <div
                    className={styles["cancel__button"]}
                    onClick={() => {
                      setBottomSheetOpen(true);
                    }}
                  >
                    Cancel
                    <CancelOutlinedIcon
                      className={styles["cancel__button__icon"]}
                    />
                  </div>
                </span>
              </>
            )}
        </div>
      </div>
      {max_user_limit !== undefined && members_qty !== undefined && (
        <SwipeableDrawer
          container={window.document.body}
          anchor="bottom"
          open={bottomSheetOpen}
          onClose={() => {
            cancelStatus === "cancelled"
              ? window?.location?.reload()
              : setBottomSheetOpen(false);
          }}
          onOpen={() => {}}
          PaperProps={{
            style: {
              left: windowWidth < 0 ? 0 : windowWidth,
              maxWidth: context.mobileLayoutWidth,
              background: "none",
              boxShadow: "none",
            },
          }}
          BackdropProps={{
            style: {
              left: windowWidth < 0 ? 0 : windowWidth,
              maxWidth: context.mobileLayoutWidth,
            },
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <div className={styles["close__sheet"]}></div>
          <div className={styles["cancel__bottom__sheet__container"]}>
            {cancelStatus === "loading" && <CircularLoader />}
            {cancelStatus === "cancelled" && (
              <>
                <img className={styles["cancel__image"]} src={Success} />
                <span className={styles["cancel__title"]}>
                  Contract cancelled
                </span>
                <span className={styles["cancel__subtitle"]}>
                  {cancelSubtitle}
                </span>
                <RaiButton
                  className={styles["cancel__button__confirm"]}
                  variant="contained"
                  onClick={() => {
                    window?.location?.reload();
                  }}
                >
                  Okay!
                </RaiButton>
              </>
            )}
            {cancelStatus === "failed" && (
              <>
                <img className={styles["cancel__image"]} src={Failed} />
                <span className={styles["cancel__title"]}>
                  Unable to cancel!
                </span>
                <span className={styles["cancel__subtitle"]}>
                  {cancelSubtitle}
                </span>
                <RaiButton
                  className={styles["cancel__button__confirm"]}
                  variant="contained"
                  onClick={() => {
                    window?.location?.reload();
                  }}
                >
                  Okay!
                </RaiButton>
              </>
            )}

            {cancelStatus === "open" && (
              <>
                <span className={styles["cancel__bottom__sheet__title"]}>
                  {contract_name}
                </span>
                <div className={styles["cancel__info__container"]}>
                  <span className={styles["cancel__info__title"]}>
                    Cancel order
                  </span>
                  <div className={styles["cancel__info__data__container"]}>
                    <span className={styles["cancel__data__wrapper"]}>
                      <span className={styles["cancel__data__name"]}>
                        Total contracts
                      </span>
                      <span className={styles["cancel__data__value"]}>
                        {max_user_limit}
                      </span>
                    </span>
                    <span className={styles["cancel__data__wrapper"]}>
                      <span className={styles["cancel__data__name"]}>
                        Matched contracts
                      </span>
                      <span className={styles["cancel__data__value"]}>
                        {members_qty}
                      </span>
                    </span>
                    <span className={styles["cancel__data__wrapper"]}>
                      <span className={styles["cancel__data__name"]}>
                        Open contracts
                      </span>
                      <span className={styles["cancel__data__value"]}>
                        {max_user_limit - members_qty}
                      </span>
                    </span>
                    <span className={styles["cancel__data__wrapper"]}>
                      <span className={styles["cancel__data__name"]}>
                        Investment per contract
                      </span>
                      <span className={styles["cancel__data__value"]}>
                        ₹
                        {(
                          order_details?.investment_value / max_user_limit
                        )?.toFixed(1)}
                      </span>
                    </span>
                  </div>
                  <div className={styles["cancel__info__total"]}>
                    <span className={styles["cancel__info__total__text"]}>
                      Total refund value
                    </span>
                    <span className={styles["cancel__info__total__value"]}>
                      ₹
                      {(
                        (order_details?.investment_value / max_user_limit) *
                        (max_user_limit - members_qty)
                      )?.toFixed(1)}
                    </span>
                  </div>
                </div>
                <RaiButton variant="contained" onClick={handleCancel}>
                  Cancel all open contracts
                </RaiButton>
              </>
            )}
          </div>
        </SwipeableDrawer>
      )}
    </>
  );
};

export default PortfolioEventCard;
