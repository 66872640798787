import React from "react";
import classNames from "classnames";

import SettledIcon from "assets/icons/rai/settled.svg";
import RefereeSettle from "assets/icons/rai/referee.svg";

import styles from "./style.module.scss";

export interface SettlementInfo {
  text?: string;
  icon?: any;
}
export interface ExpiryInfo {
  text?: string;
  time?: string;
}

export interface OptionsProps {
  btn_text?: string;
  type?: "primary" | "secondary" | string;
}
export interface OpinionProps {
  title?: string;
  options?: Array<OptionsProps>;
}

export interface EventProps {
  request_id?: string | number;
  category_ids?: Array<number>;
  contract_name?: string;
  template_params?: any;
  expiry_dt?: string | null;
  type?:
    | "missing"
    | "profanity"
    | "suggestion"
    | "invalid"
    | "success"
    | string;
  profile?: any;
  eventDetails?: any;
  contractDetails?: any;
}
export interface EventCardProps {
  event: EventProps;
  opinion?: "agree" | "disagree" | null;
  contract_price?: number;
  is_money_flow?: boolean;
  winning_text?: string;
  className?: any;
  referee_flow?: boolean;
  contract_qty?: number;
  referee_name?: string;
}

const EventCard = ({
  event,
  opinion,
  className,
  referee_flow,
  referee_name,
}: EventCardProps) => {
  return (
    <div className={classNames([styles["event__card__container"]], className)}>
      <div className={styles["event__card__question"]}>
        {opinion && (
          <span className={styles["event__card__top__info"]}>
            <span className={styles["event__card__opinion"]}>
              {opinion === "agree" ? "You Agreed" : "You Disagreed"}
            </span>
          </span>
        )}
        {event?.contract_name}
      </div>
      <div className={styles["event__card__details"]}>
        <>
          <span className={styles["event__card__details__left"]}>
            {event?.expiry_dt && !referee_flow && (
              <span>Expiry: {event?.expiry_dt}</span>
            )}
            {referee_name?.length! > 0 && <span>Referee: {referee_name}</span>}
          </span>
          <span className={styles["event__card__details__right"]}>
            {referee_flow ? "Referee settle" : "Settled by ₹.AI"}
            <img
              className={styles["settle__icon"]}
              src={referee_flow ? RefereeSettle : SettledIcon}
              alt={"Settle"}
            />
          </span>
        </>
      </div>
    </div>
  );
};

export default EventCard;
