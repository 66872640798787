import React, { CSSProperties } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

// Components
import { CircularLoader } from "components/Loaders";
import { Constants } from "config/constants";
import { getSourceName } from "config/helper";

type MultiComponent = {
  event?: any;
  poll?: any;
  forecast?: any;
  categoryPreference?: any;
};

// Types
export type PaginatedListProps = {
  loading: boolean;
  next: () => any;
  hasMore: boolean;
  data: object[];
  component?: React.ReactNode;
  props?: object;
  refreshFunction?: any;
  multiComponent?: MultiComponent;
  pullDownToRefresh?: boolean;
  height?: number;
  style?: CSSProperties;
  inverse?: boolean;
  className?: string;
};

const PaginatedList = ({
  loading,
  next,
  hasMore,
  data,
  component,
  refreshFunction,
  multiComponent,
  props,
  pullDownToRefresh,
  height,
  style,
  inverse,
  className,
}: PaginatedListProps) => {
  console.log("PaginatedList Render");
  const Component: any = component;
  return (
    <InfiniteScroll
      className={className}
      height={height}
      style={style}
      inverse={inverse}
      pullDownToRefresh={pullDownToRefresh}
      refreshFunction={
        pullDownToRefresh ? () => refreshFunction(true) : () => {}
      }
      pullDownToRefreshThreshold={50}
      pullDownToRefreshContent={
        (getSourceName() === Constants.PROBO_MPL || pullDownToRefresh) && (
          <h3 style={{ textAlign: "center" }}>&#8595;</h3>
        )
      }
      releaseToRefreshContent={
        (getSourceName() === Constants.PROBO_MPL || pullDownToRefresh) && (
          <h3 style={{ textAlign: "center" }}>&#8593;</h3>
        )
      }
      dataLength={data?.length}
      next={next}
      hasMore={hasMore}
      loader={<CircularLoader />}
    >
      {data?.map((item: any, index) => {
        if (
          !loading &&
          multiComponent &&
          Object.keys(multiComponent).length > 0
        ) {
          let NewComponent: any = null;
          if (["probabilistic", "versus"].includes(item?.type)) {
            NewComponent = multiComponent?.event;
            return (
              <NewComponent key={index} index={index} {...item} {...props} />
            );
          }

          if (["poll"].includes(item?.type)) {
            NewComponent = multiComponent?.poll;
            return (
              <NewComponent
                key={index}
                index={index}
                poll={{ ...item.poll_details, ...props }}
              />
            );
          }

          if (
            item?.type === "forecast" &&
            getSourceName() !== Constants.PROBO_MPL
          ) {
            NewComponent = multiComponent?.forecast;
            return (
              <NewComponent key={index} index={index} {...item} {...props} />
            );
          }

          if (
            item?.type === "categoryPreference" &&
            getSourceName() !== Constants.PROBO_MPL
          ) {
            NewComponent = multiComponent?.categoryPreference;
            return (
              <NewComponent key={index} index={index} {...item} {...props} />
            );
          }

          return null;
        }

        return <Component key={index} {...item} {...props} />;
      })}
    </InfiniteScroll>
  );
};

export default PaginatedList;
