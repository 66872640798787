import { USER_LOGIN_URL, httpClient } from "config/api";
import { setUserOs } from "config/helper";

export type LoginServiceProps = {
  referral_code?: string;
  mobile?: string;
  is_following_referee?: boolean;
  token?: string;
  type?: string;
};

/**
 * Function to login user
 * @param data
 * @returns
 */
export const login = async (data: LoginServiceProps, headers = {}) => {
  setUserOs();
  return httpClient.post({
    url: `${USER_LOGIN_URL}`,
    data,
    headers,
  });
};
