import React from "react";
import {
  Switch,
  Route,
  withRouter,
  RouteComponentProps,
} from "react-router-dom";

// Config
import { AppContext, DefaultStateProps } from "config/context";
import { defaultState } from "config/context";
import { history } from "config/history";
import {
  getSeoCard,
  getUserToken,
  getUtmSource,
  setBackButton,
  setGraphType,
  setPollsSource,
  setSeoCard,
  setUserClickId,
  setUserEvent,
  setUserOs,
  setUserPoll,
  setUserToken,
  setUtmSource,
  setSourceName,
  setSessionId,
  setDecodedToken,
  setTopicId,
  setUserId,
  setAppVersion,
  setBannerType,
  setElectionCode,
  decodePartnerUId,
} from "config/helper";
import { logFirebaseEvent } from "config/firebase";

// Layout
import MainLayout from "components/MainLayout";

// Screens
import { Constants } from "config/constants";

// constant imports

//Rai
import RaiHome from "views/Rai/Home";
import RaiFinalContract from "views/Rai/FinalContract";
import RaiRefereeFinalContract from "views/Rai/FinalContract/Referee";
import RaiContractSummary from "views/Rai/ContractSummary";
import RaiQuestion from "views/Rai/Question";
import RaiCreate from "views/Rai/Create";
import RaiOpinion from "views/Rai/Create/Opinion";
import RaiStakes from "views/Rai/Create/Stakes";
import RaiMonetary from "views/Rai/Create/Monetary";
import RaiNumberOfContracts from "views/Rai/Create/NumberOfContracts";
import RaiRefereeShare from "views/Rai/Create/RefereeShare";
import RaiEnterName from "views/Rai/Create/EnterName";
import RaiSuggestions from "views/Rai/Question/Suggestions";
import RaiPortfolio from "views/Rai/Portfolio";

import RaiGroupQuestionList from "views/Rai/Group/QuestionList";
import RaiGroupQuestion from "views/Rai/Group/Question";
import RaiGroupContract from "views/Rai/Group/Contract";

import RaiLogin from "views/Rai/Login";
import RaiOtp from "views/Rai/Login/Otp";

import { Toast } from "components/Toast";

// AppRouter Props
export type AppRouterProps = RouteComponentProps;

// AppRouter State
export type AppRouterState = DefaultStateProps;

const globalThis: any = window;
let sourceNameLocalValue: string = "";

class AppRouter extends React.PureComponent<AppRouterProps, AppRouterState> {
  constructor(props: AppRouterProps) {
    super(props);
    this.state = {
      ...defaultState,
      handleAppDrawer: () => {
        this.setState({ openDrawer: !this.state.openDrawer });
      },
      handleLogout: () => {
        localStorage.clear();
        history.replace("/login");
        Toast({
          message: "Successfully logged out",
          type: "success",
        });
      },
      handleDownloadBottomSheet: () => {
        this.setState({
          openDownloadBottomSheet: !this.state.openDownloadBottomSheet,
        });
      },
      setBottomSheetOrderSuccess: () => {
        this.setState({
          bottomSheetOrderSucess: !this.state.bottomSheetOrderSucess,
        });
      },
      setCancelOrderEventSuccess: () => {
        this.setState({
          cancelOrderEventSuccess: !this.state.cancelOrderEventSuccess,
        });
      },
      setUserLoggedIn: (val: boolean) => {
        this.setState({ userLoggedIn: val });
      },
      setBureauContext: (bureauContext: any) => {
        this.setState({ bureauContext });
      },
      setShowMplBanner: (showMplBanner: any) => {
        this.setState({ showMplBanner });
      },
      setTradeCount: (tradeCount: any) => {
        this.setState({ tradeCount });
      },
      setFreeTradeFeatureFlag: (isFreeFeatureEnabled: any) => {
        this.setState({ isFreeFeatureEnabled });
      },
      setUrlSourceName: (urlSourceName: any) => {
        this.setState({ urlSourceName });
      },
      handleLanguageBottomSheet: (openLanguageBottomSheet: boolean) => {
        this.setState({ openLanguageBottomSheet });
      },
      setMobileLayoutWidth: (val: number) => {
        this.setState({ mobileLayoutWidth: val });
      },
      setAppConfigData: (val: number) => {
        this.setState({ appConfigData: val });
      },
    };
  }

  handleWindowResize = () => {
    const { windowHeight, windowWidth } = this.state;
    const newWindowHeight = window.innerHeight;
    const newWindowWidth = window.innerWidth;

    if (windowWidth !== newWindowWidth || windowHeight !== newWindowHeight) {
      this.setState({
        windowWidth: newWindowWidth,
        windowHeight: newWindowHeight,
      });
    }
  };

  async componentWillMount() {
    const params = new URLSearchParams(this.props?.location?.search);

    const isAndroidWebView =
      typeof globalThis?.proboAndroidWebView !== "undefined" &&
      globalThis?.proboAndroidWebView !== null;
    const isIOSWebView =
      typeof globalThis?.webkit?.messageHandlers?.probo_ios_handler !==
        "undefined" &&
      globalThis?.webkit?.messageHandlers?.probo_ios_handler !== null;

    if (
      params.get("token") &&
      !["/otpless"].includes(window?.location?.pathname)
    ) {
      const token: any = params.get("token");
      setUserToken(token);
    }

    if (isAndroidWebView) {
      const token = globalThis?.proboAndroidWebView?.getUserAuthToken();
      const appVersion = globalThis?.proboAndroidWebView?.getAppVersion();
      if (appVersion) {
        setAppVersion(appVersion);
      }
      if (token) {
        setUserToken(token);
      }
    } else if (isIOSWebView) {
      globalThis?.webkit?.messageHandlers?.forwardRequestHeaderToWebView
        ?.postMessage("FETCH_APP_INFO")
        .then((info: any) => {
          let iOSAppVersion = info?.iOSVersion;
          setAppVersion(iOSAppVersion);
        });
    } else if (isIOSWebView === null) {
      setAppVersion(Constants.APP_VERSION);
    }

    if (params.get("event")) {
      const event_id: any = params.get("event");
      setUserEvent(event_id);
    }

    if (params.get("bannerType")) {
      const bannerType: any = params.get("bannerType");
      setBannerType(bannerType);
    }

    if (params.get("electionCode")) {
      const electionCode: any = params.get("electionCode");
      setElectionCode(electionCode);
    }

    if (params.get("poll")) {
      const event_id: any = params.get("poll");
      setUserPoll(event_id);
    }

    if (params.get("click_id")) {
      const click_id: any = params.get("click_id");
      setUserClickId(click_id);
    }

    if (params.get("source")) {
      const source: any = params.get("source");
      setPollsSource(source);
    }

    if (params.get("back")) {
      const source: any = params.get("back");
      setBackButton(source);
    }

    if (params.get("graphType")) {
      const graphType: any = params.get("graphType");
      setGraphType(graphType);
    }

    if (params.get("seoCard")) {
      const seoCard: any = params.get("seoCard");
      setSeoCard(seoCard);
    }

    if (params.get("utm_source")) {
      const utm_source: any = params.get("utm_source");
      setUtmSource(utm_source);
    }

    if (params.get("source_name")) {
      const sourceName: any = params.get("source_name");
      sourceNameLocalValue = sourceName;
      this?.state?.setUrlSourceName(sourceName);
      setSourceName(sourceName);
    }

    if (params.get("session_id")) {
      const sourceName: any = params.get("session_id");
      setSessionId(sourceName);
    }

    if (params.get("info")) {
      const info: any = params.get("info");
      setDecodedToken(info);
    }

    if (params.get("topic_id")) {
      const topicId: any = params.get("topic_id");
      setTopicId(topicId);
      this.setState({ topicId });
    }

    if (params.get("partner_uid")) {
      let parterUId: string | any = params.get("partner_uid");
      parterUId = decodePartnerUId(parterUId);
      setUserId(parterUId);
    }
  }

  async componentDidMount() {
    const isAndroidWebView =
      typeof globalThis?.proboAndroidWebView !== "undefined" &&
      globalThis?.proboAndroidWebView !== null;

    const isIOSWebView =
      typeof globalThis?.webkit?.messageHandlers?.probo_ios_handler !==
        "undefined" &&
      globalThis?.webkit?.messageHandlers?.probo_ios_handler !== null;

    setUserOs();

    window.addEventListener("resize", this.handleWindowResize);

    // const params = new URLSearchParams(this.props?.location?.search);

    // if (params.get("referral")) {
    //   const referral_code: any = params.get("referral");
    //   setUserReferralCode(referral_code);
    //   this.props.history.push("/referral");
    // }

    if (isAndroidWebView || isIOSWebView) {
      this.setState({ isAndroidWebView, isIOSWebView });
    }

    if (getSeoCard()) {
      this.setState({ mobileLayoutWidth: 500 });
    }

    // Event and Polls Funnel Tracking (Signup Flow)
    const isEvent = window.location.pathname.includes("/event/");
    const isPoll = window.location.pathname.includes("/poll/");

    if (isEvent || isPoll) {
      const data = window.location.pathname.split("/");

      const logData: any = {
        event_name: "clicked_link",
        event_source: getUtmSource(),
      };

      // const logDataMPL: any = {
      //   event_name: "clicked_link",
      //   event_id: data[data.length - 1],
      //   event_type: "poll",
      // };

      if (isEvent) {
        logData.event_id = data[data.length - 1];
      } else if (isPoll) {
        logData.poll_id = data[data.length - 1];
      }

      logFirebaseEvent(logData);
      // logEventsDataMPL(logDataMPL);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  }

  render() {
    const { history } = this.props;

    return (
      <AppContext.Provider value={this.state}>
        <MainLayout>
          <Switch>
            <Route
              exact
              path="/login"
              render={(props: any) => {
                if (getUserToken()) {
                  history.push("/");
                  return null;
                }
                return <RaiLogin />;
              }}
            />

            <Route
              exact
              path="/otp"
              render={(props: any) => {
                if (getUserToken()) {
                  history.push("/");
                  return null;
                }
                return <RaiOtp />;
              }}
            />

            <Route
              exact
              path="/home"
              render={() => {
                if (!getUserToken()) {
                  history.push("/login");
                  return null;
                } else {
                  history?.push("/");
                }
              }}
            />
            <Route
              exact
              path="/logout"
              render={() => {
                localStorage.clear();
                history.push("/login");
                Toast({
                  message: "Successfully logged out",
                  type: "success",
                });
              }}
            />
            <Route
              exact
              path="/"
              render={() => {
                if (!getUserToken()) {
                  history.push("/login");
                  return null;
                }
                return <RaiHome />;
              }}
            />

            <Route
              exact
              path="/question"
              render={(props: any) => {
                if (!getUserToken()) {
                  history.push("/login");
                  return null;
                }
                return (
                  <RaiQuestion value={props?.location?.state?.value || ""} />
                );
              }}
            />

            <Route
              exact
              path="/suggestions"
              render={(props: any) => {
                const { event, type, question } = props.location.state;
                if (!getUserToken()) {
                  history.push("/login");
                  return null;
                }
                return (
                  <RaiSuggestions
                    event={event}
                    type={type}
                    question={question}
                  />
                );
              }}
            />
            <Route
              exact
              path="/create"
              render={(props: any) => {
                const { event, referee_flow } = props.location.state;
                if (!getUserToken()) {
                  history.push("/login");
                  return null;
                }
                return <RaiCreate event={event} referee_flow={referee_flow} />;
              }}
            />

            <Route
              exact
              path="/create/opinion"
              render={(props: any) => {
                const { event, referee_flow } = props?.location?.state;
                if (!getUserToken()) {
                  history.push("/login");
                  return null;
                }
                return <RaiOpinion event={event} referee_flow={referee_flow} />;
              }}
            />

            <Route
              exact
              path="/create/monetary"
              render={(props: any) => {
                const { event, opinion, referee_flow } = props?.location?.state;
                if (!getUserToken()) {
                  history.push("/login");
                  return null;
                }
                return (
                  <RaiMonetary
                    event={event}
                    opinion={opinion}
                    referee_flow={referee_flow}
                  />
                );
              }}
            />

            <Route
              exact
              path="/create/stakes"
              render={(props: any) => {
                const { event, opinion, referee_flow } = props?.location?.state;
                if (!getUserToken()) {
                  history.push("/login");
                  return null;
                }
                return (
                  <RaiStakes
                    event={event}
                    opinion={opinion}
                    referee_flow={referee_flow}
                  />
                );
              }}
            />

            <Route
              exact
              path="/create/number-of-contracts"
              render={(props: any) => {
                const {
                  event,
                  opinion,
                  contract_price,
                  referee_flow,
                  winning_text,
                  is_money_flow,
                } = props?.location?.state;
                if (!getUserToken()) {
                  history.push("/login");
                  return null;
                }
                return (
                  <RaiNumberOfContracts
                    event={event}
                    opinion={opinion}
                    contract_price={contract_price}
                    referee_flow={referee_flow}
                    winning_text={winning_text}
                    is_money_flow={is_money_flow}
                  />
                );
              }}
            />

            <Route
              exact
              path="/create/enter-name"
              render={(props: any) => {
                const {
                  event,
                  opinion,
                  contract_price,
                  contract_qty,
                  referee_flow,
                  is_money_flow,
                  winning_text,
                } = props?.location?.state;
                if (!getUserToken()) {
                  history.push("/login");
                  return null;
                }
                return (
                  <RaiEnterName
                    event={event}
                    opinion={opinion}
                    contract_price={contract_price}
                    contract_qty={contract_qty}
                    is_money_flow={is_money_flow}
                    winning_text={winning_text}
                    referee_flow={referee_flow}
                  />
                );
              }}
            />

            <Route
              exact
              path="/contract/:id"
              render={({ match: { params } }) => {
                const { id } = params;
                if (!getUserToken()) {
                  history.push(`/login?contractId=${id}`);
                  return null;
                }
                return <RaiFinalContract id={id} />;
              }}
            />

            <Route
              exact
              path="/contract/ref-share/:id"
              render={({ match: { params } }) => {
                const { id } = params;
                if (!getUserToken()) {
                  history.push(`/login?contractId=${id}`);
                  return null;
                }
                return <RaiRefereeShare id={id} />;
              }}
            />

            <Route
              exact
              path="/contract/:id/ref"
              render={({ match: { params } }) => {
                const { id } = params;
                if (!getUserToken()) {
                  history.push(`/login?contractId=${id}&user=ref`);
                  return null;
                }
                return <RaiRefereeFinalContract id={id} />;
              }}
            />

            <Route
              exact
              path="/contract/:id/details"
              render={({ match: { params } }) => {
                const { id } = params;
                if (!getUserToken()) {
                  history.push(`/login?contractId=${id}`);
                  return null;
                }
                return <RaiContractSummary id={id} />;
              }}
            />

            <Route
              exact
              path="/group/contract/:id/details"
              render={({ match: { params } }) => {
                const { id } = params;
                if (!getUserToken()) {
                  history.push(`/login?contractId=${id}`);
                  return null;
                }
                return <RaiContractSummary id={id} type={"poll"} />;
              }}
            />

            <Route
              exact
              path="/portfolio"
              render={(props: any) => {
                if (!getUserToken()) {
                  history.push(`/login`);
                  return null;
                }
                return <RaiPortfolio from={props?.location?.state?.from} />;
              }}
            />

            <Route
              exact
              path="/group/question/list"
              render={(props: any) => {
                if (!getUserToken()) {
                  history.push("/login");
                  return null;
                }
                return <RaiGroupQuestionList />;
              }}
            />

            <Route
              exact
              path="/group/question"
              render={(props: any) => {
                if (!getUserToken()) {
                  history.push("/login");
                  return null;
                }
                return (
                  <RaiGroupQuestion event={props?.location?.state?.event} />
                );
              }}
            />
            <Route
              exact
              path="/group/contract/:id"
              render={({ match: { params } }) => {
                const { id } = params;
                if (!getUserToken()) {
                  history.push(`/login?contractId=${id}&format=group`);
                  return null;
                }
                return <RaiGroupContract id={id} />;
              }}
            />
          </Switch>
        </MainLayout>
      </AppContext.Provider>
    );
  }
}

export default withRouter(AppRouter);
