// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_no__data__LXLBo {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  flex: 1 1;\n  color: #d5dadf;\n  font-size: 0.925rem;\n  font-weight: 500;\n}\n\n.style_no__data__image__1nIMF {\n  max-width: 120px;\n  margin-bottom: 1rem;\n}", "",{"version":3,"sources":["webpack://src/components/NoData/style.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;EACA,cAAA;EACA,mBAAA;EACA,gBAAA;AADF;;AAIA;EACE,gBAAA;EACA,mBAAA;AADF","sourcesContent":["@import \"scss/variables.scss\";\n\n.no__data {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  flex: 1;\n  color: #d5dadf;\n  font-size: 0.925rem;\n  font-weight: 500;\n}\n\n.no__data__image {\n  max-width: 120px;\n  margin-bottom: 1rem;\n}\n\n// Mobile Devices (380px and up)\n@media (min-width: 360px) {\n}\n\n// Mobile Devices (420px and up)\n@media (min-width: 420px) {\n}\n\n// Mobile Devices (480px and up)\n@media (min-width: 480px) {\n}\n\n// Small devices (landscape phones, 576px and up)\n@media (min-width: 576px) {\n}\n\n// Medium devices (tablets, 768px and up)\n@media (min-width: 768px) {\n}\n\n// Large devices (desktops, 992px and up)\n@media (min-width: 992px) {\n}\n\n// X-Large devices (large desktops, 1200px and up)\n@media (min-width: 1200px) {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"no__data": "style_no__data__LXLBo",
	"no__data__image": "style_no__data__image__1nIMF"
};
export default ___CSS_LOADER_EXPORT___;
