import React from "react";

export type DefaultStateProps = {
  mobileLayoutWidth: number;
  mobileNavHeight: number;
  mobileBottomNavHeight: number;
  windowWidth: number;
  windowHeight: number;
  openDrawer: boolean;
  handleAppDrawer: () => void;
  handleLogout: () => void;
  openDownloadBottomSheet: boolean;
  handleDownloadBottomSheet: () => void;
  portfolioTabHeight: number;
  bottomSheetOrderSucess: boolean;
  setBottomSheetOrderSuccess: () => any;
  cancelOrderEventSuccess: boolean;
  setCancelOrderEventSuccess: () => any;
  userLoggedIn: boolean;
  setUserLoggedIn: (val: any) => void;
  bureauContext?: any;
  setBureauContext: (val: any) => void;
  sourceName?: any;
  setSourceName: (val: any) => void;
  topicId?: any;
  setTopicId: (val: any) => void;
  mplUserType?: any;
  showMplBanner?: any;
  setShowMplBanner: (val: any) => void;
  tradeCount?: any;
  setTradeCount: (val: any) => any;
  isFreeFeatureEnabled?: any;
  setFreeTradeFeatureFlag: (val: any) => any;
  urlSourceName?: any;
  setUrlSourceName: (val: any) => void;
  openLanguageBottomSheet: boolean;
  handleLanguageBottomSheet: (val: any) => any;
  setMobileLayoutWidth: (val: any) => any;
  appConfigData?: any;
  setAppConfigData: (val: any) => any;
  fantasyBottomNavHeight: number;
  isAndroidWebView?: boolean;
  isIOSWebView?: boolean;
};

export const defaultState: DefaultStateProps = {
  mobileLayoutWidth: 430,
  mobileNavHeight: 52,
  mobileBottomNavHeight: 70,
  windowWidth: window.innerWidth,
  windowHeight: window.innerHeight,
  openDrawer: false,
  handleAppDrawer: () => {},
  handleLogout: () => {},
  openDownloadBottomSheet: false,
  handleDownloadBottomSheet: () => {},
  portfolioTabHeight: 48,
  bottomSheetOrderSucess: false,
  setBottomSheetOrderSuccess: () => {},
  cancelOrderEventSuccess: false,
  setCancelOrderEventSuccess: () => {},
  userLoggedIn: false,
  setUserLoggedIn: () => {},
  bureauContext: null,
  setBureauContext: () => {},
  sourceName: null,
  setSourceName: () => {},
  topicId: null,
  setTopicId: () => {},
  mplUserType: null,
  showMplBanner: false,
  setShowMplBanner: () => {},
  tradeCount: null,
  setTradeCount: () => {},
  isFreeFeatureEnabled: null,
  setFreeTradeFeatureFlag: () => {},
  urlSourceName: null,
  setUrlSourceName: () => {},
  openLanguageBottomSheet: false,
  handleLanguageBottomSheet: () => {},
  setMobileLayoutWidth: () => {},
  appConfigData: null,
  setAppConfigData: () => {},
  fantasyBottomNavHeight: 56,
  isAndroidWebView: false,
  isIOSWebView: false,
};

export const AppContext = React.createContext(defaultState);
