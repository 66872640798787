import { GET_USER_DETAILS, SET_USER_DETAILS, httpClient } from "config/api";

/**
 * Function to login user
 * @param data
 * @returns
 */
export const getUserDetails = async () => {
  return httpClient.get({
    url: `${GET_USER_DETAILS}`,
    token: true,
  });
};

export const setUserDetails = async (data: any) => {
  return httpClient.patch({
    url: `${SET_USER_DETAILS}`,
    data,
    token: true,
  });
};
