// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_details__card__container__3Qua1 {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  background: #19171d;\n  padding: 20px;\n  border-radius: 16px;\n  margin-top: 30px;\n}\n\n.style_details__card__title__jwIy1 {\n  font-size: 14px;\n  font-weight: 600;\n  line-height: 20px;\n  letter-spacing: 0em;\n  text-align: left;\n}\n\n.style_details__card__list__container__3odrr {\n  padding: 0px 10px;\n}\n\n.style_details__card__list__item__1m0js {\n  font-size: 12px;\n  font-weight: 400;\n  line-height: 20px;\n  letter-spacing: 0em;\n  text-align: left;\n  margin-top: 10px;\n  color: #c2c2c2;\n}", "",{"version":3,"sources":["webpack://src/components/Rai/DetailsListCard/style.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,gBAAA;EACA,cAAA;AACF","sourcesContent":[".details__card__container {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  background: #19171d;\n  padding: 20px;\n  border-radius: 16px;\n  margin-top: 30px;\n}\n\n.details__card__title {\n  font-size: 14px;\n  font-weight: 600;\n  line-height: 20px;\n  letter-spacing: 0em;\n  text-align: left;\n}\n\n.details__card__list__container {\n  padding: 0px 10px;\n}\n\n.details__card__list__item {\n  font-size: 12px;\n  font-weight: 400;\n  line-height: 20px;\n  letter-spacing: 0em;\n  text-align: left;\n  margin-top: 10px;\n  color: #c2c2c2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"details__card__container": "style_details__card__container__3Qua1",
	"details__card__title": "style_details__card__title__jwIy1",
	"details__card__list__container": "style_details__card__list__container__3odrr",
	"details__card__list__item": "style_details__card__list__item__1m0js"
};
export default ___CSS_LOADER_EXPORT___;
