import { Router } from "react-router-dom";
import { ClearCacheProvider } from "react-clear-cache";
// Config
import { history } from "config/history";

// Component
import AppRouter from "router";
import ScrollToTop from "components/ScrollToTop";
import ErrorHandler from "components/ErrorHandler";
import EnvironmentLable from "components/EnvironmentLable";
import { getUserDetails } from "services/user";
import { useEffect } from "react";

const App = () => {
  console.log("App Render");

  const getUser = async () => {
    const response: any = await getUserDetails();
    if (response?.statusCode === 200) {
      response?.data?.name &&
        localStorage?.setItem("name", response?.data?.name);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  if (process.env.REACT_APP_ENV === "production") {
    return (
      <ErrorHandler>
        <ClearCacheProvider duration={300000}>
          <Router history={history}>
            <ScrollToTop />
            <AppRouter />
          </Router>
        </ClearCacheProvider>
      </ErrorHandler>
    );
  }

  return (
    <ErrorHandler>
      <Router history={history}>
        <EnvironmentLable>
          <>
            <ScrollToTop />
            <AppRouter />
          </>
        </EnvironmentLable>
      </Router>
    </ErrorHandler>
  );
};

export default App;
