import React, { createRef, useContext, useEffect, useState } from "react";
import { useScreenshot } from "use-react-screenshot";
import {
  getContractById,
  postContract,
  getWalletDetails,
  validateContract,
  closeContract,
  expireContract,
} from "services/rai";
import { Player } from "@lottiefiles/react-lottie-player";
import SwipeableDrawer from "@mui/material/SwipeableDrawer/SwipeableDrawer";

import Container from "components/Rai/Container";
import Navbar from "components/Rai/Navbar";
import EventCard, { EventProps } from "components/Rai/EventCard";
import RaiButton from "components/Rai/Button";
import ContractCard from "components/Rai/ContractCard";

import Checkmark from "assets/images/rai/checkmark_green.svg";
import Sealed from "assets/images/rai/rai_success.json";

import styles from "./style.module.scss";
import { CircularLoader } from "components/Loaders";
import { Toast } from "components/Toast";
import { useHistory } from "react-router-dom";
import { AppContext } from "config/context";
import { logFirebaseEvent } from "config/firebase";
import classNames from "classnames";
import { setUserDetails } from "services/user";

interface FinalContractRefereeProps {
  id: string | number;
}

const FinalContractReferee = ({ id }: FinalContractRefereeProps) => {
  const history = useHistory();

  const context = useContext(AppContext);
  const windowWidth = (context?.windowWidth - context?.mobileLayoutWidth) / 2;

  const ref = createRef<any>();
  const [isCreator, setIsCreator] = useState(true);
  const [isReferee, setIsReferee] = useState(true);
  const [isSettled, setIsSettled] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [isMatched, setIsMatched] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [validatePressed, setValidatePressed] = useState(false);

  const [nameSheetOpen, setNameSheetOpen] = useState(false);
  const [name, setName] = useState("");

  const [contract, setContract] = useState<any>();
  const [settleOn, setSettleOn] = useState<any>();
  const [event, setEvent] = useState<EventProps>();
  const [loading, setLoading] = useState(true);
  const [contractsCompleted, setContractsCompleted] = useState(false);

  const getContractDetails = async () => {
    const response: any = await getContractById(id);
    setLoading(false);
    if (response?.statusCode === 200) {
      const formattedEvent: EventProps = {
        contract_name: response?.data?.contract_details?.name,
        expiry_dt: response?.data?.contract_details?.expiry,
      };
      setEvent(formattedEvent);
      setContract(response?.data);
      setIsCreator(response?.data?.is_creator);
      setIsMatched(response?.data?.is_matched_for_referee);
      setIsSettled(response?.data?.is_settled);
      setIsValidated(response?.data?.is_referee_validated);
      setIsReferee(response?.data?.is_referee);
      setIsSettled(response?.data?.is_settled);
      setContractsCompleted(response?.data?.all_contracts_completed);
    } else if (response?.statusCode === 403) {
      localStorage.clear();
      history.push(`/login?contractId=${id}&user=ref`);
      Toast({
        message: "Please login again",
        type: "success",
      });
    } else {
      Toast({
        message: response?.message,
        type: "error",
      });
      setLoading(false);
    }
  };

  const validateClicked = async () => {
    if (localStorage?.getItem("name")) {
      const data = { contract_id: id };
      const response: any = await validateContract(data);
      if (response?.statusCode === 200) {
        setIsValidated(true);
        setValidatePressed(true);
      }
    } else {
      setNameSheetOpen(true);
    }
  };

  const expireClicked = async () => {
    const data = { contract_id: id };
    const response: any = await expireContract(data);
    if (response?.statusCode === 201) {
      setIsExpired(true);
    }
  };

  const closeClicked = async () => {
    const data = { contract_id: id, outcome: settleOn };
    const response: any = await closeContract(data);
    if (response?.statusCode === 200) {
      setIsSettled(true);
    }
  };

  const handleNameChange = (e: any) => {
    setName(e?.target?.value);
  };

  const submitNameClicked = async () => {
    if (name?.length > 0) {
      const data = { name: name };
      const response: any = await setUserDetails(data);
      if (response?.statusCode === 200) {
        localStorage.setItem("name", name);
        validateClicked();
        setNameSheetOpen(false);
      } else {
        Toast({
          message: response?.message,
          type: "error",
        });
      }
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      submitNameClicked();
    }
  };

  useEffect(() => {
    getContractDetails();
    logFirebaseEvent({
      event_name: "rai_final_contract_screen_loaded",
      event_value_key1: "format_name",
      event_value_value1: "RAI",
      event_value_key2: "screen",
      event_value_value2: "final_contract",
      event_value_key3: "event_id",
      event_value_value3: id,
    });
  }, []);

  return (
    <>
      <Container>
        <>
          {loading && <CircularLoader />}
          {!loading && (
            <>
              <div className={styles["top__container"]}>
                <Navbar
                  title={"₹.AI"}
                  subtitle={"by Probo"}
                  backButtonDisabled
                  portfolioDisabled
                />
                <div
                  className={classNames({
                    [styles["contract__container"]]: true,
                  })}
                >
                  <div className={styles["contract__header"]}>
                    <span className={styles["contract__subtitle"]}>
                      {isValidated
                        ? "Contract"
                        : "You’re invited to be the referee on this contract"}
                    </span>
                  </div>
                </div>
                <div
                  className={classNames({
                    [styles["contract__details__container__screenshot"]]: true,
                  })}
                  ref={ref}
                >
                  {isExpired || validatePressed ? (
                    <EventCard
                      event={event!}
                      referee_flow={true}
                      referee_name={contract?.referee_name}
                    />
                  ) : (
                    <ContractCard
                      event={event!}
                      reward_details={contract?.reward_details}
                      initiator_rai_details={contract?.initiator_rai_details}
                      referee_name={contract?.referee_name}
                      is_referee_flow={true}
                    />
                  )}
                </div>
                {((validatePressed && !isMatched) ||
                  (isValidated && !isExpired)) &&
                  !isSettled && (
                    <div className={styles["validated__container"]}>
                      <Player
                        className={styles["contract__sealed__image"]}
                        autoplay
                        loop
                        src={Sealed}
                        style={{ height: "72px", width: "72px" }}
                      ></Player>
                      <div className={styles["validated__text"]}>
                        <span className={styles["validated__title"]}>
                          Contract approved
                        </span>
                        <span className={styles["validated__subtitle"]}>
                          {isReferee || validatePressed
                            ? "You can settle this contract once it's matched"
                            : ""}
                        </span>
                      </div>
                    </div>
                  )}
                {isValidated && isMatched && isExpired && !isSettled && (
                  <div className={styles["settle__container"]}>
                    <span className={styles["settle__title"]}>
                      Please choose outcome
                    </span>
                    <div className={styles["settle__options__container"]}>
                      <RaiButton
                        className={styles["option__button"]}
                        variant={settleOn === "Yes" ? "contained" : "outlined"}
                        onClick={() => {
                          setSettleOn("Yes");
                        }}
                      >
                        True
                      </RaiButton>
                      <RaiButton
                        className={styles["option__button"]}
                        variant={settleOn === "No" ? "contained" : "outlined"}
                        onClick={() => {
                          setSettleOn("No");
                        }}
                      >
                        False
                      </RaiButton>
                    </div>
                  </div>
                )}
                {isSettled && (
                  <div className={styles["settle__container"]}>
                    <Player
                      className={styles["contract__sealed__image"]}
                      autoplay
                      loop
                      src={Sealed}
                      style={{ height: "72px", width: "72px" }}
                    ></Player>
                    <span className={styles["settled__text"]}>
                      {contract?.event_outcome ||
                        `Contract settled at ${settleOn}`}
                    </span>
                  </div>
                )}
              </div>

              {(!isValidated || isReferee) && (
                <div className={styles["footer__section"]}>
                  {!isValidated && (
                    <>
                      <span className={styles["footer__description"]}>
                        Approve this contract, for it to be sealed after both
                        parties agree
                      </span>
                      <div className={styles["share__section"]}>
                        <RaiButton
                          className={styles["share__button"]}
                          variant="contained"
                          onClick={() => {
                            validateClicked();
                          }}
                        >
                          Approve contract
                        </RaiButton>
                      </div>
                    </>
                  )}
                  {isValidated && isMatched && !isExpired && !isSettled && (
                    <RaiButton
                      className={styles["share__button"]}
                      variant="contained"
                      onClick={() => {
                        expireClicked();
                      }}
                    >
                      Settle contract
                    </RaiButton>
                  )}
                  {isValidated && isMatched && isExpired && !isSettled && (
                    <RaiButton
                      className={styles["share__button"]}
                      variant="contained"
                      onClick={() => {
                        closeClicked();
                      }}
                      disabled={!settleOn}
                    >
                      Continue
                    </RaiButton>
                  )}
                  {(isValidated || validatePressed) && !isMatched && (
                    <RaiButton
                      className={styles["share__button"]}
                      variant="contained"
                      onClick={() => {
                        history?.push("/");
                      }}
                    >
                      Continue
                    </RaiButton>
                  )}
                  {isSettled && (
                    <RaiButton
                      className={styles["share__button"]}
                      variant="contained"
                      onClick={() => {
                        history.push("/");
                      }}
                    >
                      Continue
                    </RaiButton>
                  )}
                </div>
              )}
            </>
          )}
          {nameSheetOpen && (
            <SwipeableDrawer
              container={window.document.body}
              anchor="bottom"
              open={nameSheetOpen}
              onClose={() => {
                setNameSheetOpen(false);
              }}
              onOpen={() => {}}
              PaperProps={{
                style: {
                  left: windowWidth < 0 ? 0 : windowWidth,
                  maxWidth: context.mobileLayoutWidth,
                  background: "none",
                  boxShadow: "none",
                },
              }}
              BackdropProps={{
                style: {
                  left: windowWidth < 0 ? 0 : windowWidth,
                  maxWidth: context.mobileLayoutWidth,
                },
              }}
              ModalProps={{
                keepMounted: true,
              }}
            >
              <div className={styles["enter__name__container"]}>
                <span className={styles["enter__name__title"]}>
                  Please tell us your name
                </span>
                <input
                  className={styles["enter__name__input"]}
                  type="text"
                  autoComplete="off"
                  onChange={handleNameChange}
                  onKeyDown={handleKeyDown}
                  value={name}
                  autoFocus
                />
                <RaiButton
                  variant="contained"
                  disabled={!(name.length > 0)}
                  onClick={submitNameClicked}
                >
                  Continue
                </RaiButton>
              </div>
            </SwipeableDrawer>
          )}
        </>
      </Container>
    </>
  );
};

export default FinalContractReferee;
