import React, {
  createRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import { AppContext } from "config/context";
import { getWalletDetails, createGroupContract } from "services/rai";

import Container from "components/Rai/Container";
import Navbar from "components/Rai/Navbar";

import CoinsIcon from "assets/icons/rai/coins.svg";
import TimerIcon from "assets/icons/rai/timer.svg";
import FriendsIcon from "assets/icons/rai/friends.svg";
import ReturnsIcon from "assets/icons/rai/money.svg";
import EditIcon from "assets/icons/rai/edit.svg";
import RechargeIcon from "assets/icons/rai/recharge.png";

import styles from "./style.module.scss";
import RaiButton from "components/Rai/Button";
import { Toast } from "components/Toast";
import { useHistory } from "react-router-dom";
import Loading from "components/Rai/Loading";
import { Constants } from "config/constants";
import { logFirebaseEvent } from "config/firebase";
export interface QuestionProps {
  created_dt_u?: string;
  description?: string;
  end_date?: string;
  expire_date?: string;
  id?: number;
  image_url?: string;
  is_active?: number;
  is_investment_allowed_in_multiple_options?: number;
  is_multi_round?: number;
  max_pot_amount?: number;
  max_user_limit?: number;
  min_trade_amount?: number;
  name?: string;
  price_lower_limit?: number;
  price_upper_limit?: number;
  round_user_limit?: number;
  start_date?: string;
  suggestion_options?: any;
  tick_value?: number;
  updated_src?: number;
}

export interface EventProps {
  event: QuestionProps;
}

const Question = ({ event }: EventProps) => {
  const history = useHistory();

  const investmentRef = createRef<any>();
  const friendsRef = createRef<any>();

  const context = useContext(AppContext);
  const windowWidth = context.mobileLayoutWidth;

  const [disabled, setDisabled] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState<any>();
  const [selectedId, setSelectedId] = useState<any>();
  const [investment, setInvestment] = useState(10);
  const [maxFriends, setMaxFriends] = useState(5);
  const [loading, setLoading] = useState(false);
  const [walletBalance, setWalletBalance] = useState<any>(0);
  const [lowBalance, setLowBalance] = useState(false);
  const [expiry, setExpiry] = useState<number>(5);

  const expiryOptions = [
    { value: 5, text: "5 min" },
    { value: 10, text: "10 min" },
    { value: 15, text: "15 min" },
    { value: 30, text: "30 min" },
    { value: 60, text: "1 hr" },
    { value: 180, text: "3 hrs" },
    { value: 1440, text: "1 day" },
    { value: 4320, text: "3 days" },
  ];

  const getWallet = async () => {
    setLoading(true);
    const response: any = await getWalletDetails();
    setLoading(false);
    if (response?.statusCode === 200) {
      setWalletBalance(response?.data?.total_amount);
    } else {
      Toast({
        message: "Couldn't fetch wallet details",
        type: "error",
      });
    }
  };

  const createContract = async () => {
    setLoading(true);
    const data = {
      suggestion_id: event?.id,
      investment: investment,
      expiry_time: expiry,
      max_limit: maxFriends,
      selected_options: selectedIndex,
    };

    logFirebaseEvent({
      event_name: "rai_create_contract_clicked",
      event_value_key1: "format_name",
      event_value_value1: "RAI",
      event_value_key2: "screen",
      event_value_value2: "question",
      event_value_key3: "rai_format",
      event_value_value3: "group",
      event_value_key4: "suggestion_id",
      event_value_value4: event?.id,
      event_value_key5: "values",
      event_value_value5: JSON.stringify(data),
    });

    const response: any = await createGroupContract(data);
    setLoading(false);
    if (response?.data) {
      console.log("response", response);
      Toast({
        message: "Contract created",
        type: "success",
      });
      history?.push(`/group/contract/${response?.data?.event_id}`);
    } else {
      Toast({
        message: "Couldn't create contract",
        type: "error",
      });
    }
  };

  useEffect(() => {
    logFirebaseEvent({
      event_name: "rai_question_screen_loaded",
      event_value_key1: "format_name",
      event_value_value1: "RAI",
      event_value_key2: "screen",
      event_value_value2: "question",
      event_value_key3: "rai_format",
      event_value_value3: "group",
      event_value_key4: "suggestion_id",
      event_value_value4: event?.id,
    });
    getWallet();
  }, []);

  useEffect(() => {
    setDisabled(!(selectedIndex >= 0) || lowBalance);
  }, [lowBalance, selectedIndex]);

  useEffect(() => {
    if (walletBalance) {
      setLowBalance(walletBalance < investment);
    }
  }, [investment, walletBalance, maxFriends]);

  return (
    <Container>
      <div className={styles["question__container"]}>
        <Navbar title="₹.AI" subtitle={"by Probo"} />
        {loading && <Loading />}
        {!loading && (
          <div className={styles["question__content__container"]}>
            <span className={styles["question__info__container"]}>
              <span className={styles["question__name"]}>{event?.name}</span>
              <img
                className={styles["question__image"]}
                src={event?.image_url}
              />
            </span>
            <div className={styles["question__opinion__container"]}>
              <span
                className={classNames({
                  [styles["question__opinion__title"]]: true,
                  [styles["question__opinion__title__error"]]: false,
                })}
              >
                {"Pick your opinion"}
              </span>
              <div className={styles["question__poll__container"]}>
                {event?.suggestion_options?.map((ele: any, index: number) => {
                  return (
                    <span
                      className={classNames({
                        [styles["question__poll___option"]]: true,
                        [styles["question__poll___option__selected"]]:
                          selectedIndex === index,
                      })}
                      onClick={() => {
                        logFirebaseEvent({
                          event_name: "rai_question_option_selected",
                          event_value_key1: "format_name",
                          event_value_value1: "RAI",
                          event_value_key2: "screen",
                          event_value_value2: "question",
                          event_value_key3: "rai_format",
                          event_value_value3: "group",
                          event_value_key4: "option_index",
                          event_value_value4: index,
                        });
                        setSelectedIndex(index);
                      }}
                    >
                      {ele?.name}
                    </span>
                  );
                })}
              </div>
              <div className={styles["question__config__container"]}>
                <span className={styles["question__config__item"]}>
                  <span className={styles["question__config__title__wrapper"]}>
                    <img
                      src={CoinsIcon}
                      className={styles["question__config__icon"]}
                    />
                    <span className={styles["question__config__title"]}>
                      Investment amount
                    </span>
                  </span>
                  <span
                    className={styles["question__input__wrapper"]}
                    onClick={() => investmentRef?.current?.select()}
                  >
                    <span className={styles["question__input__left"]}>
                      <span className={styles["question__input__rupee"]}>
                        ₹
                      </span>
                      <input
                        ref={investmentRef}
                        className={styles["question__input__value"]}
                        value={investment}
                        type="number"
                        onChange={(e) => {
                          if (
                            parseInt(e?.target?.value) > 0 &&
                            event?.max_pot_amount &&
                            parseInt(e?.target?.value) < event?.max_pot_amount
                          ) {
                            setInvestment(parseInt(e?.target?.value));
                          } else {
                            investmentRef?.current?.select();
                          }
                        }}
                      />
                    </span>
                    <img src={EditIcon} />
                  </span>
                </span>
                <span className={styles["question__config__item"]}>
                  <span className={styles["question__config__title__wrapper"]}>
                    <img
                      src={TimerIcon}
                      className={styles["question__config__icon"]}
                    />
                    <span className={styles["question__config__title"]}>
                      Expiry
                    </span>
                  </span>
                  <span className={styles["question__input__wrapper"]}>
                    <span className={styles["question__input__left"]}>
                      <select
                        className={styles["question__input__value__select"]}
                        onChange={(e) => {
                          setExpiry(parseInt(e?.target?.value));
                        }}
                        value={expiry}
                      >
                        {expiryOptions?.map((ele: any, index: number) => {
                          return (
                            <option value={ele?.value}>{ele?.text}</option>
                          );
                        })}
                      </select>
                    </span>
                  </span>
                </span>
                <span className={styles["question__config__item"]}>
                  <span className={styles["question__config__title__wrapper"]}>
                    <img
                      src={FriendsIcon}
                      className={styles["question__config__icon"]}
                    />
                    <span className={styles["question__config__title"]}>
                      Max friends
                    </span>
                  </span>
                  <span
                    className={styles["question__input__wrapper"]}
                    onClick={() => friendsRef?.current?.select()}
                  >
                    <span className={styles["question__input__left"]}>
                      <input
                        ref={friendsRef}
                        className={styles["question__input__value"]}
                        value={maxFriends}
                        type="number"
                        onChange={(e) => {
                          if (
                            parseInt(e?.target?.value) > 0 &&
                            event?.max_user_limit &&
                            parseInt(e?.target?.value) < event?.max_user_limit
                          ) {
                            setMaxFriends(parseInt(e?.target?.value));
                          } else {
                            friendsRef?.current?.select();
                          }
                        }}
                      />
                    </span>
                    <img src={EditIcon} />
                  </span>
                </span>
                <span className={styles["question__config__item"]}>
                  <span className={styles["question__config__title__wrapper"]}>
                    <img
                      src={ReturnsIcon}
                      className={styles["question__config__icon"]}
                    />
                    <span className={styles["question__config__title"]}>
                      Potential returns
                    </span>
                  </span>
                  <span
                    className={styles["question__investment__value__wrapper"]}
                  >
                    <span className={styles["question__investment__value"]}>
                      ₹{investment * maxFriends}
                    </span>
                  </span>
                </span>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className={styles["footer__section"]}>
        {/* <span className={styles["terms__section"]}>
              <a className={styles["terms__link"]} href="">
                T&C
              </a>
              applies
            </span> */}
        {!loading && (
          <>
            <RaiButton
              className={styles["continue__btn"]}
              disabled={disabled || lowBalance}
              variant="contained"
              onClick={() => {
                createContract();
              }}
            >
              Invest & continue
            </RaiButton>
            <span
              className={styles["wallet__balance"]}
              style={{ width: windowWidth }}
            >
              Wallet balance: {walletBalance?.toFixed(2)}
            </span>
          </>
        )}
        {lowBalance && (
          <>
            <div
              className={styles["recharge__section"]}
              style={{ width: windowWidth }}
            >
              <div className={styles["recharge__container"]}>
                <div className={styles["recharge__left__container"]}>
                  <img
                    src={RechargeIcon}
                    className={styles["recharge__icon"]}
                  />
                  <div className={styles["recharge__info__container"]}>
                    <span className={styles["recharge__info"]}>
                      ₹{(investment - walletBalance).toFixed(1)} low on money!
                    </span>
                    <span className={styles["recharge__now"]}>
                      Recharge now
                    </span>
                  </div>
                </div>
                <button
                  className={styles["recharge__button"]}
                  onClick={() => {
                    window.open(
                      `${Constants?.TRADING_WEB_URL}/recharge`,
                      "_blank"
                    );
                  }}
                >
                  Add now
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </Container>
  );
};

export default Question;
